import './app-detail.css';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
//import appActions from '../../redux/actions/actions';
import dashboardActions from '../../redux/actions/dashboardActions';
import {
  UPDATE_SHOW_BACKGROUND,
  UPDATE_SHOW_HEADER,
} from '../../redux/constants/types';

import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const PdfViewUserManual_dashboard = () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: () => [],
    renderToolbar: (Toolbar) => (
      <Toolbar>
        {(slots) => {
          const {
            CurrentPageInput,
            Download,
            EnterFullScreen,
            GoToNextPage,
            GoToPreviousPage,
            NumberOfPages,
            Print,
            ShowSearchPopover,
            Zoom,
            ZoomIn,
            ZoomOut,
          } = slots;
          return (
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                width: '100%',
              }}
            >
              <div style={{ padding: '0px 2px' }}>
                <ZoomOut />
              </div>
              <div style={{ padding: '0px 2px' }}>
                <Zoom />
              </div>
              <div style={{ padding: '0px 2px' }}>
                <ZoomIn />
              </div>
              <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                <GoToPreviousPage />
              </div>
              <div style={{ padding: '20px 2px', width: '50px' }}>
                <CurrentPageInput />
              </div>
              <div style={{ padding: '0px 10px' }}>
                / <NumberOfPages />
              </div>
              <div style={{ padding: '0px 2px' }}>
                <GoToNextPage />
              </div>
              <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                <EnterFullScreen />
              </div>
              <div style={{ padding: '0px 2px' }}>
                <Download />
              </div>
              <div style={{ padding: '0px 2px' }}>
                <Print />
              </div>
            </div>
          );
        }}
      </Toolbar>
    ),
  });

  const currentApp =
    useSelector((state) => {
      return state.dashboard.currentApp.data;
    }) || [];

  const currentAppDetails = currentApp?.data || {};

  const { appId } = useParams();

  const dispatch = useDispatch();

  const currentUser = JSON.parse(localStorage.getItem('user_details') || '{}');

  useEffect(() => {
    dispatch(
      dashboardActions.getDashboardById({ appId, userId: currentUser.id })
    );
    dispatch({ type: UPDATE_SHOW_BACKGROUND, show: false });
    dispatch({ type: UPDATE_SHOW_HEADER, showHeader: false });
  }, []);
  const userManual = currentAppDetails?.Dash_Documents?.filter(
    (itm) => itm.image_use === 'user_manual'
  );
  console.log('userManual', userManual);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update windowWidth when the window is resized
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleWindowResize);

    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  return (
    <div
      style={{
        margin: '20px',
      }}
    >
      <div
        style={{
          border: '1px solid #eaeaea',
          height: '750px',
          width: 'auto',
        }}
      >
        {userManual && (
          <Viewer
            defaultScale={windowWidth < 768 ? 0.4 : 1.9}
            fileUrl={`${userManual[0]?.doc_url}`}
            plugins={[defaultLayoutPluginInstance]}
          />
        )}
      </div>
    </div>
  );
};

export default PdfViewUserManual_dashboard;
