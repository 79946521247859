import {
  ArrowLeftOutlined,
  DeleteOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Radio,
  Select,
  Upload,
  Typography,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_SHOW_BACKGROUND } from '../../redux/constants/types';
import { useLocation, useNavigate } from 'react-router-dom';
import './addapp.css';
import appActions from '../../redux/actions/actions';
import { toTitleCase } from '../../components/AppCard';
import moment from 'moment';
import dashboardActions from '../../redux/actions/dashboardActions';
import { debounce } from 'lodash';

const AddDashboard = () => {
  const [appForm] = Form.useForm();
  const catOptions = useSelector((state) => {
    return state.dashboard.catOptions.data || [];
  });

  const refreshOptions = useSelector((state) => {
    return state.dashboard.refreshOptions.data || [];
  });

  const appUsers = useSelector((state) => {
    return state.app.appUsers.data || [];
  });

  const location = useLocation();

  const dashboardToEdit = location.state?.appToEdit;

  const [imageFiles, setImageFiles] = useState([]);
  const [previousImageFiles, setPreviousImageFiles] = useState([]);
  const [userManual, setUserManual] = useState(null);
  const [previousUserManual, setPreviousUserManual] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [previousProfileImage, setPreviousProfileImage] = useState(null);
  const [featureImage, setFeatureImage] = useState(null);
  const [previousFeatureImage, setPreviousFeatureImage] = useState(null);
  const [manageUsersModal, setManageUsersModal] = useState({});
  const [quickLook, setQuicklook] = useState(null);
  const [previousQuickLook, setPreviousQuickLook] = useState(null);
  const [showAdDetails, setShowAdDetails] = useState({});

  const [addAppLoading, setAddAppLoading] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [showAccessType, setShowAccessType] = useState({});

  const { Option } = Select;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const currentDashboard = useSelector((state) => {
    return state?.dashboard?.currentApp?.data || {};
  });

  useEffect(() => {
    dispatch({ type: UPDATE_SHOW_BACKGROUND, show: true });
    dispatch(dashboardActions.getRefreshCycleOptions());
    dispatch(appActions.getUsers());
    dispatch(dashboardActions.getCatOptions());
    appForm.setFieldValue('lastUpdated', moment());
  }, []);

  const currentUser = JSON.parse(localStorage.getItem('user_details') || '{}');

  useEffect(() => {
    if (dashboardToEdit) {
      dispatch(
        dashboardActions.getDashboardById({
          appId: dashboardToEdit?.id,
          userId: currentUser.id,
        })
      );
    }
  }, [dashboardToEdit]);

  useEffect(() => {
    if (dashboardToEdit && currentDashboard && currentDashboard.data) {
      populateFields();
    }
  }, [currentDashboard]);

  const populateFields = () => {
    const {
      dash_name,
      dash_description,
      dash_url,
      benifits,
      how_to_use,
      access_type,
      isBoth,
      isWeb,
      owner_id,
      sme_contact,
      staging_url,
      youtube_url,
      Dash_Documents,
      function_id,
      Permissions,
      last_updated,
      cycle_id,
      access_levels_enabled,
      isBothAccessLevels,
      isRightsAccessLevels,
      isRoleAccessLevels,
    } = currentDashboard.data;

    appForm.setFieldsValue({
      name: dash_name,
      appUrl: dash_url,
      videoUrl: youtube_url,
      description: dash_description,
      benefits: benifits,
      sme_contact: sme_contact,
      appOwners: owner_id,
      howToUse: how_to_use,
      accessType: access_type,
      staging_url: staging_url,
      category: function_id,
      platform: isBoth ? 'both' : isWeb ? 'web' : 'mobile',
      refreshCycle: cycle_id,
      lastUpdated: moment(last_updated),
      access_levels_enabled: access_levels_enabled,
      access_levels_enabled_type: isBothAccessLevels
        ? 'Both'
        : isRightsAccessLevels
        ? 'Rights'
        : isRoleAccessLevels
        ? 'Roles'
        : '',
    });
    const profileImage = Dash_Documents?.find(
      (it) => it.image_use === 'profile'
    );
    const featureImage = Dash_Documents?.find(
      (it) => it.image_use === 'feature_image'
    );
    const appImages = Dash_Documents?.filter(
      (it) => it.image_use === 'gallery_images'
    );
    const userManual = Dash_Documents?.find(
      (it) => it.image_use === 'user_manual'
    );
    const quickLookDoc = Dash_Documents.find(
      (it) => it.image_use === 'quick_look'
    );
    profileImage && setPreviousProfileImage([profileImage]);
    appImages && setPreviousImageFiles(appImages);
    userManual && setPreviousUserManual([userManual]);
    featureImage && setPreviousFeatureImage([featureImage]);
    quickLookDoc && setPreviousQuickLook([quickLookDoc]);
  };

  const handleFormDataOnChange = (slug, value) => {
    console.log('slug,value:', slug, value);

    if (
      (slug === 'access_levels_enabled_type' && value === 'Roles') ||
      (slug === 'access_levels_enabled_type' && value === 'Both')
    ) {
      handleAccessType();
    }

    setFormValues({
      ...formValues,
      [slug]: value,
    });
  };
  const handleAccessType = () => {
    setShowAccessType({ visible: true });
  };

  const onFinish = () => {
    const {
      name,
      appUrl,
      description,
      category,
      howToUse,
      benefits,
      accessType = 'open',
      videoUrl,
      platform = 'web',
      sme_contact,
      appPermissions = [],
      appOwners,
      refreshCycle,
      lastUpdated,
      staging_url,
      access_levels_enabled,
      access_levels_enabled_type,
    } = appForm.getFieldsValue();
    setAddAppLoading(true);
    handleDeleteDOcuments();

    const formData = new FormData();
    formData.append('dash_name', name);
    formData.append('dash_description', description);
    formData.append('dash_url', appUrl);
    formData.append('staging_url', staging_url);
    formData.append('how_to_use', howToUse);
    formData.append('benifits', benefits);
    formData.append('function_id', category);
    formData.append('youtube_url', videoUrl);
    formData.append('access_type', accessType);
    formData.append('access_levels_enabled', access_levels_enabled);
    formData.append('isMobile', platform === 'mobile');
    formData.append('isWeb', platform === 'web');
    formData.append('isBoth', platform === 'both');
    formData.append('sme_contact', sme_contact);
    if (access_levels_enabled === true) {
      if (access_levels_enabled_type === 'Roles') {
        formData.append('isRoleAccessLevels', true);
        formData.append('isRightsAccessLevels', false);
        formData.append('isBothAccessLevels', false);
      } else if (access_levels_enabled_type === 'Rights') {
        formData.append('isRoleAccessLevels', false);
        formData.append('isRightsAccessLevels', true);
        formData.append('isBothAccessLevels', false);
      } else if (access_levels_enabled_type === 'Both') {
        formData.append('isRoleAccessLevels', false);
        formData.append('isRightsAccessLevels', false);
        formData.append('isBothAccessLevels', true);
      }
    } else {
      formData.append('isRoleAccessLevels', false);
      formData.append('isRightsAccessLevels', false);
      formData.append('isBothAccessLevels', false);
    }

    if (!dashboardToEdit) {
      formData.append('owner_id', JSON.stringify(appOwners));
      formData.append('permited_user_id', JSON.stringify(appPermissions));
    }

    if (profileImage && profileImage.length > 0) {
      formData.append('profile', profileImage[0].originFileObj);
    }

    if (featureImage && featureImage.length > 0) {
      formData.append('feature_image', featureImage[0].originFileObj);
    }

    if (quickLook && quickLook.length > 0) {
      formData.append('quick_look', quickLook[0].originFileObj);
    }

    formData.append('cycle_id', refreshCycle);
    formData.append('last_updated', moment(lastUpdated).format('YYYY-MM-DD'));

    if (imageFiles && imageFiles.length > 0) {
      imageFiles.forEach((itm) => {
        formData.append('gallery_images', itm.originFileObj);
      });
    }
    if (userManual && userManual.length > 0) {
      formData.append('user_manual', userManual[0].originFileObj);
    }

    if (dashboardToEdit) {
      dispatch(
        dashboardActions.editDashboard(
          currentDashboard.data.id,
          formData,
          handleAddAppComplete
        )
      );
    } else {
      dispatch(
        dashboardActions.addNewDashboard(formData, handleAddAppComplete)
      );
    }
  };

  const handleAddAppComplete = (status) => {
    setAddAppLoading(false);
    if (status.success) {
      const successMessage = dashboardToEdit
        ? 'Details Updated Successfully!'
        : 'Dashboard Added Successfully!';
      message.success(successMessage);
      dispatch(appActions.getPermittedAppsList());
      navigate('/dashboards');
    } else {
      message.error('Something went wrong, we are looking into it');
    }
  };

  const handleDeleteDOcuments = () => {
    const deletedProfile = previousProfileImage
      ? previousProfileImage.filter((itm) => itm.deleted)
      : [];

    const deletedFeature = previousFeatureImage
      ? previousFeatureImage.filter((itm) => itm.deleted)
      : [];

    const deletedManual = previousUserManual
      ? previousUserManual.filter((itm) => itm.deleted)
      : [];

    const deletedImages = previousImageFiles
      ? previousImageFiles.filter((itm) => itm.deleted)
      : [];

    const deletedQuicklook = previousQuickLook
      ? previousQuickLook.filter((itm) => itm.deleted)
      : [];

    const deletedDocs = [
      ...deletedProfile,
      ...deletedFeature,
      ...deletedManual,
      ...deletedImages,
      ...deletedQuicklook,
    ];
    const deletePayload = [];

    if (deletedDocs && deletedDocs.length > 0) {
      deletedDocs.forEach((itm) => {
        deletePayload.push({
          id: itm.id,
          doc_url: itm.doc_url,
        });
      });
    }
    if (deletePayload.length > 0) {
      dispatch(dashboardActions.deleteDocuments(deletePayload));
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  const handleFileChange = (files) => {
    setImageFiles([...(files.fileList || [])]);
  };

  const handleUserManual = (file) => {
    setUserManual(file.fileList);
  };

  const deleteProfileImage = (idx) => {
    const tempProfileImages = [...previousProfileImage];
    tempProfileImages[0].deleted = true;
    setPreviousProfileImage(tempProfileImages);
  };

  const deleteFeatureImage = (idx) => {
    const tempFeatureImages = [...previousFeatureImage];
    tempFeatureImages[0].deleted = true;
    setPreviousFeatureImage(tempFeatureImages);
  };

  const deleteUserManual = (idx) => {
    const tempUserManual = [...previousUserManual];
    tempUserManual[0].deleted = true;
    setPreviousUserManual(tempUserManual);
  };

  const deleteQuickLook = (idx) => {
    const tempQuicklook = [...previousQuickLook];
    tempQuicklook[0].deleted = true;
    setPreviousQuickLook(tempQuicklook);
  };

  const deleteAppImage = (idx) => {
    const tempAppImages = [...previousImageFiles];
    tempAppImages[idx].deleted = true;
    setPreviousImageFiles(tempAppImages);
  };

  const manageUsers = () => {
    // setManageUsersModal({ visible: true });
    navigate(`/dashboard/manage-users/${currentDashboard.data?.id}`);
  };

  const handleAD_details = () => {
    navigate(`/dashboard/ad-details/${currentDashboard.data?.id}`, {
      state: { currentDashboardData: currentDashboard.data },
    });
  };
  const handlePublishReleaseNotes = () => {
    navigate(`/dashboard/publish-release-notes/${currentDashboard.data?.id}`, {
      state: { currentDashboardData: currentDashboard.data },
    });
  };

  const checkLimits = (s, field) => {
    if (s?.target?.value?.length >= 1000) {
      document.getElementById(field).innerHTML =
        'Max. characters limit reached';
    } else {
      document.getElementById(field).innerHTML = '';
    }
  };

  const deactiveApp = () => {
    appActions
      .makeApiCall(
        'delete',
        `dashboard/deactivate_dashboard?dashboard_id=${currentDashboard.data?.id}`,
        {}
      )
      .then((res) => {
        console.log('deactivation resp', res);
        if (res.data.success) {
          message.success('Deactivated Successfully!');
          setTimeout(() => {
            navigate('/home');
          }, 500);
        }
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const showAdGroupDetails = () => {
    setShowAdDetails({ visible: true });
  };

  const handleOwnersNameSearch = useCallback(
    debounce((value) => {
      if (value.length >= 3) {
        dispatch(appActions.getUsers({ search: value }));
      }
    }, 300),
    []
  );
  const handleNameSearch = useCallback(
    debounce((value) => {
      if (value.length >= 3) {
        dispatch(appActions.getUsers({ search: value }));
      }
    }, 300),
    []
  );
  return (
    <div className="add-app-container">
      <div className="app-back-button" onClick={goBack}>
        <ArrowLeftOutlined /> <span className="back-text">Go to home page</span>
      </div>
      <div className="form-header">
        {dashboardToEdit
          ? 'Edit Analytics & Insights'
          : 'Fill out this form to add Analytics & Insights'}
      </div>
      {dashboardToEdit && (
        <div className="manage-button">
          <>
            <Button
              onClick={handlePublishReleaseNotes}
              round
              // type="primary"
              className="card-buttons px-3"
              style={{ marginLeft: 24 }}
            >
              Publish Release Notes
            </Button>
            <Button
              onClick={handleAD_details} //{showAdGroupDetails}
              round
              // type="primary"
              className="card-buttons px-3"
              style={{ marginLeft: 24 }}
            >
              AD Details
            </Button>
            <Button
              onClick={manageUsers}
              round
              // type="primary"
              className="card-buttons px-3"
              style={{ marginLeft: 24 }}
            >
              Manage Users
            </Button>
            <Popconfirm
              title="Are you sure you want to deactivate this dashboard?"
              description=""
              okText="Deactivate"
              cancelText="No"
              onConfirm={() => {
                deactiveApp();
              }}
            >
              <Button
                round
                className="card-buttons px-3"
                style={{ marginLeft: 24 }}
              >
                Deactivate Dashboard
              </Button>
            </Popconfirm>
          </>
        </div>
      )}

      <div>
        <Form
          name="basic"
          // labelCol={{ span: 12 }}
          // wrapperCol={{ span: 12 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="add-app-form"
          layout="vertical"
          form={appForm}
        >
          <Form.Item
            label="Dashboard Name"
            name="name"
            rules={[
              { required: true, message: 'Please input your dashboard name!' },
            ]}
          >
            <Input placeholder="Enter Dashboard Name" />
          </Form.Item>
          <Form.Item
            label="Dashboard URL"
            name="appUrl"
            rules={[{ required: true, message: 'Please input dashboard url!' }]}
          >
            <Input placeholder="www.appdomain.com" />
          </Form.Item>
          <Form.Item
            label="Staging URL"
            name="staging_url"
            rules={[{ required: true, message: 'Please input staging url!' }]}
          >
            <Input placeholder="www.appdomain.com" />
          </Form.Item>
          <Form.Item
            label="Video URL"
            name="videoUrl"
            rules={[{ required: true, message: 'Please input video url!' }]}
          >
            <Input placeholder="Enter video link" />
          </Form.Item>
          <Form.Item
            label="Dashboard Description"
            name="description"
            rules={[
              {
                required: true,
                message: 'Please enter dashboard description!',
              },
            ]}
          >
            <TextArea
              // onKeyUp={(e) => checkLimits(e, 'description_limit')}
              maxLength={1000}
              placeholder="Enter Dashboard Description"
            />
          </Form.Item>
          <Form.Item
            name="quickLook"
            label="Quick Look Document"
            // rules={[
            //   { required: true, message: 'Please upload profile image!' },
            // ]}
          >
            <Upload
              onChange={(files) => {
                setQuicklook(files.fileList);
              }}
              fileList={quickLook}
              beforeUpload={(file) => {
                return false;
              }}
              showUploadList
              // accept="image/*"
              maxCount={1}
            >
              <Button htmlType="button" type="button" icon={<UploadOutlined />}>
                Upload Quick Look
              </Button>
            </Upload>
            {dashboardToEdit && (
              <div className="previous-files mt-2">
                {/* <Typography.Title level={5}>
                Previous Profile Image
              </Typography.Title> */}
                {previousQuickLook
                  ?.filter((itm) => !itm.deleted)
                  ?.map((man, idx) => (
                    <div className="d-flex w-50 mt-1">
                      {/* // id, doc_url */}
                      <span style={{ fontSize: '12px' }}>{man?.file_name}</span>
                      <span className="ml-auto pointer">
                        <DeleteOutlined
                          onClick={() => deleteQuickLook(idx)}
                          style={{ color: 'red' }}
                        />
                      </span>
                    </div>
                  ))}
              </div>
            )}
          </Form.Item>
          <Form.Item
            name="profileImage"
            label="Profile Image"
            // rules={[
            //   { required: true, message: 'Please upload profile image!' },
            // ]}
          >
            <Upload
              onChange={(files) => {
                setProfileImage(files.fileList);
              }}
              fileList={profileImage}
              beforeUpload={(file) => {
                return false;
              }}
              showUploadList
              accept="image/*"
              maxCount={1}
            >
              <Button htmlType="button" type="button" icon={<UploadOutlined />}>
                Upload Profile Image
              </Button>
            </Upload>
            {dashboardToEdit && (
              <div className="previous-files mt-2">
                {/* <Typography.Title level={5}>
                Previous Profile Image
              </Typography.Title> */}
                {previousProfileImage
                  ?.filter((itm) => !itm.deleted)
                  ?.map((man, idx) => (
                    <div className="d-flex w-50 mt-1">
                      {/* // id, doc_url */}
                      <span style={{ fontSize: '12px' }}>{man?.file_name}</span>
                      <span className="ml-auto pointer">
                        <DeleteOutlined
                          onClick={() => deleteProfileImage(idx)}
                          style={{ color: 'red' }}
                        />
                      </span>
                    </div>
                  ))}
              </div>
            )}
          </Form.Item>
          <Form.Item
            name="featureImage"
            label="Feature Image"
            // rules={[
            //   { required: true, message: 'Please upload feature image!' },
            // ]}
          >
            <Upload
              onChange={(files) => {
                setFeatureImage(files.fileList);
              }}
              fileList={featureImage}
              beforeUpload={(file) => {
                return false;
              }}
              showUploadList
              accept="image/*"
              maxCount={1}
            >
              <Button htmlType="button" type="button" icon={<UploadOutlined />}>
                Upload Feature Image
              </Button>
            </Upload>
            {dashboardToEdit && (
              <div className="previous-files mt-2">
                {/* <Typography.Title level={5}>
                Previous Profile Image
              </Typography.Title> */}
                {previousFeatureImage
                  ?.filter((itm) => !itm.deleted)
                  ?.map((man, idx) => (
                    <div className="d-flex w-50">
                      {/* // id, doc_url */}
                      <span>{man?.file_name}</span>
                      <span className="ml-auto pointer">
                        <DeleteOutlined
                          onClick={() => deleteFeatureImage(idx)}
                          style={{ color: 'red' }}
                        />
                      </span>
                    </div>
                  ))}
              </div>
            )}
          </Form.Item>
          <Form.Item
            label="Last Updated"
            name="lastUpdated"
            rules={[
              { required: true, message: 'Please select refersh interval!' },
            ]}
          >
            <DatePicker format="YYYY/MM/DD" />
          </Form.Item>
          <Form.Item
            label="Select Refresh Cycle "
            name="refreshCycle"
            rules={[
              { required: true, message: 'Please select refersh interval!' },
            ]}
          >
            <Select placeholder="Select Refresh Cycle">
              {refreshOptions.map((itm) => (
                <Option key={itm.id} value={itm.id}>
                  {toTitleCase(itm.cycle_name)}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Select Category"
            name="category"
            rules={[{ required: true, message: 'Please select category!' }]}
          >
            <Select placeholder="Select Category">
              {catOptions.map((itm) => (
                <Option key={itm.id} value={itm.id}>
                  {itm.function_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="accessType" label="Access Type">
            <Radio.Group defaultValue={'open'}>
              <Radio value={'open'}>Open</Radio>
              <Radio value={'restricted'}>Restricted</Radio>
              <Radio value={'upcoming'}>Upcoming</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="access_levels_enabled"
            label="Do you want to enable access level configuration on this app?"
            rules={[{ required: true, message: 'Please select access level!' }]}
          >
            <Radio.Group
              onChange={(e) =>
                handleFormDataOnChange('access_levels_enabled', e.target.value)
              }
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          {!dashboardToEdit ? (
            <>
              {formValues?.access_levels_enabled === true && (
                <Form.Item
                  name="access_levels_enabled_type"
                  label="Please select access level enable type"
                  rules={[
                    {
                      required: true,
                      message: 'Please select access level type!',
                    },
                  ]}
                >
                  <Radio.Group
                    onChange={(e) => {
                      handleFormDataOnChange(
                        'access_levels_enabled_type',
                        e.target.value
                      );
                    }}
                  >
                    <Radio value={'Roles'}>Roles</Radio>
                    <Radio value={'Rights'}>Rights</Radio>
                    <Radio value={'Both'}>Both</Radio>
                  </Radio.Group>
                </Form.Item>
              )}
            </>
          ) : (
            <>
              {(formValues?.access_levels_enabled === undefined &&
                currentDashboard?.data?.access_levels_enabled === true) ||
              formValues?.access_levels_enabled === true ? (
                <Form.Item
                  name="access_levels_enabled_type"
                  label="Please select access level enable type"
                  rules={[
                    {
                      required: true,
                      message: 'Please select access level type!',
                    },
                  ]}
                >
                  <Radio.Group
                    onChange={(e) => {
                      handleFormDataOnChange(
                        'access_levels_enabled_type',
                        e.target.value
                      );
                    }}
                  >
                    <Radio value={'Roles'}>Roles</Radio>
                    <Radio value={'Rights'}>Rights</Radio>
                    <Radio value={'Both'}>Both</Radio>
                  </Radio.Group>
                </Form.Item>
              ) : (
                ''
              )}
            </>
          )}
          <Form.Item name="platform" label="Platform">
            <Radio.Group defaultValue={'web'}>
              <Radio value={'web'}>Web</Radio>
              <Radio value={'mobile'}>Mobile</Radio>
              <Radio value={'both'}>Both</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="How to Use"
            name="howToUse"
            // rules={[{ required: true, message: 'Please input app url!' }]}
          >
            <TextArea
              // onKeyUp={(e) => checkLimits(e, 'htu_limit')}
              maxLength={1000}
              placeholder="Enter instructions on how to use the Dashboard"
            />
          </Form.Item>
          <Form.Item name="userManual" label="User Manual">
            <Upload
              onChange={handleUserManual}
              fileList={userManual}
              beforeUpload={(file) => {
                return false;
              }}
              showUploadList
              maxCount={1}
            >
              <Button htmlType="button" type="button" icon={<UploadOutlined />}>
                Upload Manual
              </Button>
            </Upload>
            {dashboardToEdit && (
              <div className="previous-files mt-2">
                {/* <Typography.Title level={5}>
                Previous User Manual
              </Typography.Title> */}
                {previousUserManual
                  ?.filter((itm) => !itm.deleted)
                  ?.map((man, idx) => (
                    <div className="d-flex w-50 mt-1">
                      {/* // id, doc_url */}
                      <span style={{ fontSize: '12px' }}>{man?.file_name}</span>
                      <span className="ml-auto pointer">
                        <DeleteOutlined
                          onClick={() => deleteUserManual(idx)}
                          style={{ color: 'red' }}
                        />
                      </span>
                    </div>
                  ))}
              </div>
            )}
          </Form.Item>
          <Form.Item
            label="Benefits"
            name="benefits"
            // rules={[{ required: true, message: 'Please input app url!' }]}
          >
            <TextArea
              // onKeyUp={(e) => checkLimits(e, 'benefits_limit')}
              maxLength={1000}
              placeholder="Enter the benefits of the dashboard"
            />
          </Form.Item>
          <Form.Item
            label="Contact Email"
            name="sme_contact"
            rules={[{ required: true, message: 'Please input contact email!' }]}
          >
            <Input placeholder="Enter the email address to contact for support" />
          </Form.Item>
          <Form.Item name="appImages" label="Dashboard Gallery">
            <Upload
              multiple
              onChange={handleFileChange}
              fileList={imageFiles}
              beforeUpload={(file) => {
                return false;
              }}
              showUploadList
              accept="image/*"
              maxCount={
                dashboardToEdit && previousImageFiles.length > 0
                  ? previousImageFiles.filter((itm) => !itm.deleted).length >= 5
                    ? 0
                    : 5 -
                      previousImageFiles.filter((itm) => !itm.deleted).length
                  : 5
              }
            >
              <Button htmlType="button" type="button" icon={<UploadOutlined />}>
                Upload Images
              </Button>
            </Upload>
            {/* <Input multiple type="file" onChange={imageFileChange} /> */}
            {dashboardToEdit && (
              <div className="previous-files mt-2">
                {/* <Typography.Title level={5}>Previous App Images</Typography.Title> */}
                {previousImageFiles
                  // ?.filter((itm) => !itm.deleted)
                  ?.map(
                    (man, idx) =>
                      !man.deleted && (
                        <div className="d-flex w-50 mt-1">
                          {/* // id, doc_url */}
                          <span style={{ fontSize: '12px' }}>
                            {man?.file_name}
                          </span>
                          <span className="ml-auto pointer">
                            <DeleteOutlined
                              onClick={() => deleteAppImage(idx)}
                              style={{ color: 'red' }}
                            />
                          </span>
                        </div>
                      )
                  )}
              </div>
            )}
          </Form.Item>
          {!dashboardToEdit && (
            <>
              <Form.Item
                label="Owners"
                name="appOwners"
                rules={[
                  { required: true, message: 'Please select dashboard owner!' },
                ]}
              >
                <Select
                  placeholder="Select Owners"
                  mode="multiple"
                  showSearch
                  optionFilterProp="children"
                  onSearch={handleOwnersNameSearch}
                  // filterOption={(input, option) =>
                  //   (option?.label ?? '')
                  //     .toLowerCase()
                  //     .includes(input.toLowerCase())
                  // }
                  // options={
                  //   appUsers?.data?.map((usr) => ({
                  //     label: `${usr.first_name || ''} ${usr.last_name || ''}`,
                  //     value: usr.id,
                  //   })) || []
                  // }
                >
                  {appUsers?.data?.map((usr) => (
                    <Option key={usr?.id} value={usr?.id}>{`${
                      usr?.first_name
                    } ${usr.last_name} (${usr?.email || ''})`}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Give Permission to"
                name="appPermissions"
                // rules={[{ required: true, message: 'Please input app url!' }]}
              >
                <Select
                  mode="multiple"
                  placeholder="Select Users who has access to this dashboard"
                  showSearch
                  optionFilterProp="children"
                  onSearch={handleNameSearch}
                  // filterOption={(input, option) =>
                  //   (option?.label ?? '')
                  //     .toLowerCase()
                  //     .includes(input.toLowerCase())
                  // }
                  /*  options={
                    appUsers?.data?.map((usr) => ({
                      label: `${usr.first_name || ''} ${usr.last_name || ''}`,
                      value: usr.id,
                    })) || []
                  } */
                >
                  {appUsers?.data?.map((usr) => (
                    <Option key={usr?.id} value={usr?.id}>{`${
                      usr?.first_name
                    } ${usr.last_name} (${usr?.email || ''})`}</Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}

          <Form.Item className="text-right">
            <Button
              type="outlined"
              className="cancel-btn"
              disabled={addAppLoading}
              onClick={goBack}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              className="submit-btn ml-2"
              disabled={addAppLoading}
            >
              {dashboardToEdit
                ? 'Update Analytics & Insights'
                : 'Add Analytics & Insights'}
            </Button>
          </Form.Item>
        </Form>
      </div>
      {showAdDetails && (
        <Modal
          visible={showAdDetails.visible}
          title="AD Group Details"
          onCancel={() => setShowAdDetails({})}
          onOk={() => setShowAdDetails({})}
        >
          <>
            <div>
              <span className="fw-bold">AD Group for Owners:</span>
              <span className="ml-2">
                {
                  currentDashboard.data?.dash_groups?.find(
                    (grp) => grp.group_type === 'owner'
                  )?.group_name
                }
              </span>
            </div>
            <div>
              <span className="fw-bold">AD Group for Users:</span>
              <span className="ml-2">
                {
                  currentDashboard.data?.dash_groups?.find(
                    (grp) => grp.group_type === 'user'
                  )?.group_name
                }
              </span>
            </div>
          </>
        </Modal>
      )}
      {showAccessType && (
        <Modal
          visible={showAccessType.visible}
          title="Important Information"
          cancelButtonProps={{ style: { display: 'none' } }}
          onCancel={() => setShowAccessType({})}
          onOk={() => setShowAccessType({})}
        >
          {console.log('showAccessType@@@@@@@', showAccessType.visible)}
          <Typography>
            AD Groups once created, not to be shared with the users.
          </Typography>
        </Modal>
      )}
    </div>
  );
};

export default AddDashboard;
