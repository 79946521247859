import React, { useState } from 'react';
import { Table, Tooltip } from 'antd';
import './requests.css';

const ApproverTable = ({
  data,
  setSelectedRowKeys,
  selectedRowKeys,
  setSelectedRow,
  selectedRow,
  setUnselected,
}) => {
  //console.log('DATA:=', data);

  const access_levels_type =
    data?.length > 0 ? data[0]?.access_levels_type : '';
  //console.log('TYPE:=', access_levels_type);
  const dataSBG = data?.map((item) => ({
    key: item?.id,
    name: item?.SBG_name,
    type: item?.access_levels_type,
    SBG_id: item?.SBG_id,
  }));
  const dataSBU = data?.map((item) => ({
    key: item?.id,
    name: item?.SBU_name,
    type: item?.access_levels_type,
    SBU_id: item?.SBU_id,
  }));
  const dataBU = data?.map((item) => ({
    key: item?.id,
    name: item?.BU_name,
    type: item?.access_levels_type,
    BU_id: item?.BU_id,
  }));
  const dataProject = data?.map((item) => ({
    key: item?.id,
    name: `${item?.Project_name} (${item?.Project_code})`,
    type: item?.access_levels_type,
    Project_id: item?.Project_id,
  }));
  //   console.log('dataSBG', dataSBG);
  //   console.log('dataSBU', dataSBU);
  //   console.log('dataBU', dataBU);
  //   console.log('dataProject', dataProject);
  const columnsSBG = [
    {
      title: 'SBG Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      ellipsis: {
        showTitle: false,
      },
      render: (name) => {
        return (
          <>
            <Tooltip
              title={name}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {name}
            </Tooltip>
          </>
        );
      },
    },
    {
      title: 'Level of Access',
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => a.type.localeCompare(b.type),
      ellipsis: {
        showTitle: false,
      },
      render: (type) => {
        return (
          <>
            <Tooltip
              title={type}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {type}
            </Tooltip>
          </>
        );
      },
    },
  ];
  const columnsSBU = [
    {
      title: 'SBU Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      ellipsis: {
        showTitle: false,
      },
      render: (name) => {
        return (
          <>
            <Tooltip
              title={name}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {name}
            </Tooltip>
          </>
        );
      },
    },
    {
      title: 'Level of Access',
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => a.type.localeCompare(b.type),
      ellipsis: {
        showTitle: false,
      },
      render: (type) => {
        return (
          <>
            <Tooltip
              title={type}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {type}
            </Tooltip>
          </>
        );
      },
    },
  ];
  const columnsBU = [
    {
      title: 'BU Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      ellipsis: {
        showTitle: false,
      },
      render: (name) => {
        return (
          <>
            <Tooltip
              title={name}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {name}
            </Tooltip>
          </>
        );
      },
    },
    {
      title: 'Level of Access',
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => a.type.localeCompare(b.type),
      ellipsis: {
        showTitle: false,
      },
      render: (type) => {
        return (
          <>
            <Tooltip
              title={type}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {type}
            </Tooltip>
          </>
        );
      },
    },
  ];
  const columnsProject = [
    {
      title: 'Project Name & code',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      ellipsis: {
        showTitle: false,
      },
      render: (name) => {
        return (
          <>
            <Tooltip
              title={name}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {name}
            </Tooltip>
          </>
        );
      },
    },
    {
      title: 'Level of Access',
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => a.type.localeCompare(b.type),
      ellipsis: {
        showTitle: false,
      },
      render: (type) => {
        return (
          <>
            <Tooltip
              title={type}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {type}
            </Tooltip>
          </>
        );
      },
    },
  ];

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (newSelectedRowKeys, selectedRows) => {
      //   console.log('selectedRowKeys changed: ', newSelectedRowKeys);
      //   console.log('selectedRows: ', selectedRows);
      const newData =
        access_levels_type === 'SBG'
          ? dataSBG
          : access_levels_type === 'SBU'
          ? dataSBU
          : access_levels_type === 'BU'
          ? dataBU
          : access_levels_type === 'Project'
          ? dataProject
          : null;

      const unselectedRows = newData?.filter((object1) => {
        return !selectedRows.some((object2) => {
          return object1.key === object2.key;
        });
      });

      //console.log('newData: ', newData);

      setUnselected(unselectedRows);
      setSelectedRowKeys(newSelectedRowKeys);
      setSelectedRow(selectedRows);
      // console.log('unselectedRows: ', unselectedRows);
    },
  };

  return (
    <div>
      <Table
        dataSource={
          access_levels_type === 'SBG'
            ? dataSBG
            : access_levels_type === 'SBU'
            ? dataSBU
            : access_levels_type === 'BU'
            ? dataBU
            : access_levels_type === 'Project'
            ? dataProject
            : null
        }
        columns={
          access_levels_type === 'SBG'
            ? columnsSBG
            : access_levels_type === 'SBU'
            ? columnsSBU
            : access_levels_type === 'BU'
            ? columnsBU
            : access_levels_type === 'Project'
            ? columnsProject
            : null
        }
        pagination={false}
        rowSelection={rowSelection}
      />
    </div>
  );
};
export default ApproverTable;
