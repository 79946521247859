import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import appActions from '../../redux/actions/actions';

import './requests.css';
import './feedback.scss';
import dashboardActions from '../../redux/actions/dashboardActions';
import { Card, Pagination } from 'antd';
import { debounce } from 'lodash';
import Rating from 'react-rating';
import sarovarActions from '../../redux/actions/sarovarActions';

const FeedbackList = ({ isDashboards, data }) => {
  const entity = useSelector((state) => {
    return state.root.entity;
  });

  const appFeedbackList = useSelector((state) => {
    return state[entity].appsFeedback || {};
  });

  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  // const isDashboards = entity === 'dashboard';
  const currentUser = JSON.parse(localStorage.getItem('user_details') || '{}');

  const refreshData = debounce(() => {
    // const searchPayload = { type: searchBy, query: searchText };
    if (entity === 'app') {
      dispatch(appActions.getAppWiseRatings({ page }));
    } else if (entity === 'sarovar') {
      dispatch(sarovarActions.getDashboardWiseRatings({ page }));
    } else if (entity === 'dashboard') {
      dispatch(dashboardActions.getDashboardWiseRatings({ page }));
    }
  }, 500);

  useEffect(() => {
    refreshData();
  }, [page]);

  console.log('apps feedback', appFeedbackList);

  const nameKey = isDashboards ? 'Dashboard.dash_name' : 'App.app_name';

  return (
    <div className="feedback-container">
      <div style={{ width: '100%', margin: 'auto' }}>
        <div className="status-text">
          {entity === 'dashboard'
            ? 'Dashboards'
            : entity === 'app'
            ? 'Apps'
            : 'Sarovar'}{' '}
          Feedback
          {`(${appFeedbackList?.pagination?.total_records || 0})`}
        </div>

        <div className="d-flex align-items-center selection  mb-2">
          {/* <div style={{ width: 500, marginLeft: 20 }}>
            <Select value={searchBy} onChange={(val) => setSearchBy(val)}>
              {isDashboards ? (
                <Select.Option value="dashboard_search">
                  Dashboard
                </Select.Option>
              ) : (
                <Select.Option value="app_search">Apps</Select.Option>
              )}
              <Select.Option value="user_search">Users</Select.Option>
            </Select>
            <AppSearchBar
              handleChange={handleSearchChange}
              placeholder={`Search by user or ${entity}`}
            />
          </div> */}
        </div>
        <div>
          {appFeedbackList?.data?.length > 0 ? (
            <>
              {appFeedbackList?.data?.map((itm, index) => (
                <Card className="mb-2">
                  <div className="d-flex">
                    <div className="feedback-appname">
                      {isDashboards || entity === 'sarovar'
                        ? itm?.Dashboard?.dash_name
                        : itm?.App.app_name}
                    </div>
                    <div>
                      <Rating
                        readonly
                        placeholderRating={itm?.rating || 0}
                        emptySymbol={
                          <img
                            src="../../star_grey.png"
                            className="icon"
                            alt=""
                          />
                        }
                        placeholderSymbol={
                          <img
                            src="../../star_gold.png"
                            className="icon"
                            alt=""
                          />
                        }
                        fullSymbol={
                          <img
                            src="../../star_gold.png"
                            className="icon"
                            alt=""
                          />
                        }
                      />
                    </div>
                  </div>
                  <div className="feedback-user">{`by ${
                    itm.User?.first_name || ''
                  } ${itm.User?.last_name || ''}`}</div>
                  <div className="feedback-title">{itm.comment || ''}</div>
                  {/* <div className="feedback-description"></div> */}
                </Card>
              ))}
            </>
          ) : (
            <span>There is no feedback to display</span>
          )}
        </div>
        <div className="pagination">
          <Pagination
            total={appFeedbackList?.pagination?.total_records}
            defaultCurrent={1}
            pageSize={10}
            current={page}
            onChange={(current, size) => {
              setPage(current);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default FeedbackList;
