import {
  ADD_APPLICATION_START,
  FETCH_APPS_FINISHED,
  FETCH_APPS_START,
  FETCH_APP_DETAILS_FINISHED,
  FETCH_APP_DETAILS_START,
  FETCH_MY_REQUESTS_FINISHED,
  FETCH_MY_REQUESTS_START,
  FETCH_USER_REQUESTS_START,
  FETCH_USER_REQUESTS_FINISHED,
  GET_APP_BY_ID_FINISHED,
  GET_APP_BY_ID_START,
  GET_CATEGORIES_FINISHED,
  GET_CATEGORIES_START,
  GET_CAT_OPTIONS_FINISHED,
  GET_CAT_OPTIONS_START,
  GET_MY_REQUEST_STATUSES_FINISHED,
  GET_MY_REQUEST_STATUSES_START,
  GET_USERS_FINISHED,
  GET_USERS_START,
  GET_USER_REQUEST_STATUSES_FINISHED,
  GET_USER_REQUEST_STATUSES_START,
  REQUEST_ACCESS_FINISHED,
  REQUEST_ACCESS_START,
  CREATE_NEW_AD_GROUP_START,
  CREATE_NEW_AD_GROUP_FINISHED,
  DEACTIVATE_APP_AD_GROUP_START,
  DEACTIVATE_APP_AD_GROUP_FINISHED,
  SIGNIN_FINISHED,
  SIGNIN_START,
  UPDATE_REQUEST_STATUS_FINISHED,
  UPDATE_REQUEST_STATUS_START,
  UPDATE_SHOW_BACKGROUND,
  UPDATE_SHOW_HEADER,
  GET_APP_USERS_START,
  GET_APP_USERS_FINISHED,
  GET_CUSTOM_APP_AD_GROUP_START,
  GET_CUSTOM_APP_AD_GROUP_FINISHED,
  GET_APP_OWNERS_START,
  GET_APP_OWNERS_FINISHED,
  GET_SBG_USERS_FINISHED,
  GET_SBG_USERS_START,
  GET_SBU_USERS_FINISHED,
  GET_SBU_USERS_START,
  GET_BU_USERS_FINISHED,
  GET_BU_USERS_START,
  GET_PROJECT_USERS_FINISHED,
  GET_PROJECT_USERS_START,
  AD_APP_GROUP_ADD_MEMBERS_STATUS_START,
  AD_APP_GROUP_ADD_MEMBERS_STATUS_FINISHED,
  GET_CUSTOM_APP_AD_MEMBERS_START,
  GET_CUSTOM_APP_AD_MEMBERS_FINISHED,
  DEACTIVATE_APP_AD_MEMBERS_START,
  DEACTIVATE_APP_AD_MEMBERS_FINISHED,
  GET_UPDATES_LIST_START,
  GET_UPDATES_LIST_FINISHED,
  GET_UPDATES_START,
  GET_UPDATES_FINISHED,
  VIEW_ACCESS_LEVELS_START,
  VIEW_ACCESS_LEVELS_FINISHED,
  CREATE_EDIT_RELEASE_NOTE_START,
  CREATE_EDIT_RELEASE_NOTE_FINISHED,
  IMPORT_BULK_APP_START,
  IMPORT_BULK_APP_FINISHED,
  GET_APP_AD_GROUP_START,
  GET_APP_AD_GROUP_FINISHED,
} from '../constants/types';

const INITIAL_STATE = {
  entityList: {
    loading: false,
    data: [],
  },
  updatesList: {
    loading: false,
    data: [],
  },
  updatesCount: {
    loading: false,
    data: [],
  },
  importBulkApp: {
    loading: false,
  },
  viewAccessLevel: {
    loading: false,
    data: [],
  },
  requestsList: {
    loading: false,
    data: [],
  },
  customAppADMembersList: {
    loading: false,
    data: [],
  },
  currentAppDetails: {
    loading: false,
    data: [],
  },
  showBackground: false,
  showHeader: true,
  categories: {
    loading: false,
    data: [],
  },
  myRequestStatuses: {
    loading: false,
    data: [],
  },
  adAppGroupAddMembers: {
    loading: false,
    data: [],
  },
  userRequestStatuses: {
    loading: false,
    data: [],
  },
  catOptions: {
    loading: false,
    data: [],
  },
  requestAccess: {
    loading: false,
  },

  createNewADGroup: {
    loading: false,
  },
  createReleaseNote: {
    loading: false,
  },

  deactivateAppADGroup: {
    loading: false,
  },
  deactivateAppADMembers: {
    loading: false,
  },
  currentApp: {
    loading: false,
    data: {},
  },
  userDetails: {
    loading: false,
    data: {},
  },
  appUsers: {
    loading: false,
    data: [],
  },
  appSBG_Users: {
    loading: false,
    data: [],
  },
  appSBU_Users: {
    loading: false,
    data: [],
  },
  appBU_Users: {
    loading: false,
    data: [],
  },
  appProject_Users: {
    loading: false,
    data: [],
  },
  appPermittedUsers: {
    loading: false,
    data: [],
  },
  appCustomAppADGroups: {
    loading: false,
    data: [],
  },

  appAppADGroups: {
    loading: false,
    data: [],
  },

  appOwners: {
    loading: false,
    data: [],
  },
  userRequestsList: {
    pending: {
      loading: false,
      data: [],
    },
    accepted: {
      loading: false,
      data: [],
    },
    rejected: {
      loading: false,
      data: [],
    },
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_APPS_START:
      return {
        ...state,
        entityList: { loading: true, data: [] },
      };
    case FETCH_APPS_FINISHED:
      return {
        ...state,
        entityList: { loading: false, data: action?.appslist },
      };
    case GET_UPDATES_LIST_START:
      return {
        ...state,
        updatesList: { loading: true, data: [] },
      };
    case GET_UPDATES_LIST_FINISHED:
      return {
        ...state,
        updatesList: { loading: false, data: action?.data },
      };

    case IMPORT_BULK_APP_START:
      return {
        ...state,
        importBulkApp: {
          loading: true,
        },
      };

    case IMPORT_BULK_APP_FINISHED:
      return {
        ...state,
        importBulkApp: {
          loading: false,
        },
      };

    case GET_UPDATES_START:
      return {
        ...state,
        updatesCount: { loading: true, data: [] },
      };
    case GET_UPDATES_FINISHED:
      return {
        ...state,
        updatesCount: { loading: false, data: action?.data },
      };

    case VIEW_ACCESS_LEVELS_START:
      return {
        ...state,
        viewAccessLevel: { loading: true, data: [] },
      };
    case VIEW_ACCESS_LEVELS_FINISHED:
      return {
        ...state,
        viewAccessLevel: { loading: false, data: action?.data },
      };

    case FETCH_MY_REQUESTS_START:
      return {
        ...state,
        myRequestsList: { loading: true, data: [] },
      };
    case FETCH_MY_REQUESTS_FINISHED:
      return {
        ...state,
        myRequestsList: { loading: false, data: action?.data },
      };
    case FETCH_USER_REQUESTS_START:
      return {
        ...state,
        userRequestsList: {
          [action.status]: { loading: true, data: [], pagination: {} },
        },
      };
    case FETCH_USER_REQUESTS_FINISHED:
      console.log('actionnnnn', action);
      return {
        ...state,
        userRequestsList: {
          [action.status]: {
            loading: false,
            data: action?.data,
            pagination: action.pagination || {},
          },
        },
      };
    case FETCH_APP_DETAILS_START:
      return {
        ...state,
        currentAppDetails: { loading: true, data: {} },
      };
    case FETCH_APP_DETAILS_FINISHED:
      return {
        ...state,
        currentAppDetails: { loading: false, data: action.appDetails },
      };
    case UPDATE_SHOW_BACKGROUND:
      return {
        ...state,
        showBackground: action.show,
      };
    case UPDATE_SHOW_HEADER:
      return {
        ...state,
        showHeader: action.show,
      };
    case ADD_APPLICATION_START:
      return {
        ...state,
        addApp: {
          loading: true,
        },
      };
    case GET_CATEGORIES_START:
      return {
        ...state,
        categories: {
          loading: true,
          data: [],
        },
      };
    case GET_CATEGORIES_FINISHED:
      return {
        ...state,
        categories: {
          loading: false,
          data: action.data,
        },
      };
    case GET_MY_REQUEST_STATUSES_START:
      return {
        ...state,
        myRequestStatuses: {
          loading: true,
          data: [],
        },
      };
    case GET_MY_REQUEST_STATUSES_FINISHED:
      return {
        ...state,
        myRequestStatuses: {
          loading: false,
          data: action.data,
        },
      };

    case GET_USER_REQUEST_STATUSES_START:
      return {
        ...state,
        userRequestStatuses: {
          loading: true,
          data: [],
        },
      };
    case GET_USER_REQUEST_STATUSES_FINISHED:
      return {
        ...state,
        userRequestStatuses: {
          loading: false,
          data: action.data,
        },
      };
    case GET_CAT_OPTIONS_START:
      return {
        ...state,
        catOptions: {
          loading: true,
          data: [],
        },
      };
    case GET_CAT_OPTIONS_FINISHED:
      return {
        ...state,
        catOptions: {
          loading: false,
          data: action.data,
        },
      };

    case REQUEST_ACCESS_START:
      return {
        ...state,
        requestAccess: {
          loading: true,
        },
      };

    case REQUEST_ACCESS_FINISHED:
      return {
        ...state,
        requestAccess: {
          loading: false,
        },
      };

    case CREATE_NEW_AD_GROUP_START:
      return {
        ...state,
        createNewADGroup: {
          loading: true,
        },
      };

    case CREATE_NEW_AD_GROUP_FINISHED:
      return {
        ...state,
        createNewADGroup: {
          loading: false,
        },
      };

    case CREATE_EDIT_RELEASE_NOTE_START:
      return {
        ...state,
        createReleaseNote: {
          loading: true,
        },
      };

    case CREATE_EDIT_RELEASE_NOTE_FINISHED:
      return {
        ...state,
        createReleaseNote: {
          loading: false,
        },
      };

    case UPDATE_REQUEST_STATUS_START:
      return {
        ...state,
        requestStatus: {
          loading: true,
        },
      };

    case UPDATE_REQUEST_STATUS_FINISHED:
      return {
        ...state,
        requestStatus: {
          loading: false,
        },
      };

    case GET_CUSTOM_APP_AD_MEMBERS_START:
      return {
        ...state,
        customAppADMembersList: {
          loading: true,
        },
      };

    case GET_CUSTOM_APP_AD_MEMBERS_FINISHED:
      return {
        ...state,
        customAppADMembersList: {
          loading: false,
          data: action.data,
        },
      };
    case AD_APP_GROUP_ADD_MEMBERS_STATUS_START:
      return {
        ...state,
        adAppGroupAddMembers: {
          loading: true,
        },
      };

    case AD_APP_GROUP_ADD_MEMBERS_STATUS_FINISHED:
      return {
        ...state,
        adAppGroupAddMembers: {
          loading: false,
        },
      };

    case DEACTIVATE_APP_AD_GROUP_START:
      return {
        ...state,
        deactivateAppADGroup: {
          loading: true,
        },
      };

    case DEACTIVATE_APP_AD_GROUP_FINISHED:
      return {
        ...state,
        deactivateAppADGroup: {
          loading: false,
        },
      };

    case DEACTIVATE_APP_AD_MEMBERS_START:
      return {
        ...state,
        deactivateAppADMembers: {
          loading: true,
        },
      };

    case DEACTIVATE_APP_AD_MEMBERS_FINISHED:
      return {
        ...state,
        deactivateAppADMembers: {
          loading: false,
        },
      };
    case GET_APP_BY_ID_START:
      return {
        ...state,
        currentApp: {
          loading: true,
          data: {},
        },
      };

    case GET_APP_BY_ID_FINISHED:
      return {
        ...state,
        currentApp: {
          loading: false,
          data: action.data,
        },
      };

    case SIGNIN_START:
      return {
        ...state,
        userDetails: {
          loading: true,
        },
      };
    case SIGNIN_FINISHED:
      return {
        ...state,
        userDetails: {
          loading: false,
          data: action.data,
        },
      };
    case GET_USERS_START:
      return {
        ...state,
        appUsers: {
          loading: true,
        },
      };
    case GET_USERS_FINISHED:
      return {
        ...state,
        appUsers: {
          loading: false,
          data: action.data,
        },
      };
    case GET_SBG_USERS_START:
      return {
        ...state,
        appSBG_Users: {
          loading: true,
        },
      };
    case GET_SBG_USERS_FINISHED:
      return {
        ...state,
        appSBG_Users: {
          loading: false,
          data: action.data,
        },
      };

    case GET_SBU_USERS_START:
      return {
        ...state,
        appSBU_Users: {
          loading: true,
        },
      };
    case GET_SBU_USERS_FINISHED:
      return {
        ...state,
        appSBU_Users: {
          loading: false,
          data: action.data,
        },
      };
    case GET_BU_USERS_START:
      return {
        ...state,
        appBU_Users: {
          loading: true,
        },
      };
    case GET_BU_USERS_FINISHED:
      return {
        ...state,
        appBU_Users: {
          loading: false,
          data: action.data,
        },
      };
    case GET_PROJECT_USERS_START:
      return {
        ...state,
        appProject_Users: {
          loading: true,
        },
      };
    case GET_PROJECT_USERS_FINISHED:
      return {
        ...state,
        appProject_Users: {
          loading: false,
          data: action.data,
        },
      };

    case GET_APP_USERS_START:
      return {
        ...state,
        appPermittedUsers: {
          loading: true,
        },
      };
    case GET_APP_USERS_FINISHED:
      return {
        ...state,
        appPermittedUsers: {
          loading: false,
          data: action.data,
        },
      };
    case GET_CUSTOM_APP_AD_GROUP_START:
      return {
        ...state,
        appCustomAppADGroups: {
          loading: true,
        },
      };
    case GET_CUSTOM_APP_AD_GROUP_FINISHED:
      return {
        ...state,
        appCustomAppADGroups: {
          loading: false,
          data: action.data,
        },
      };

    case GET_APP_AD_GROUP_START:
      return {
        ...state,
        appAppADGroups: {
          loading: true,
        },
      };
    case GET_APP_AD_GROUP_FINISHED:
      return {
        ...state,
        appAppADGroups: {
          loading: false,
          data: action.data,
        },
      };

    case GET_APP_OWNERS_START:
      return {
        ...state,
        appOwners: {
          loading: true,
        },
      };
    case GET_APP_OWNERS_FINISHED:
      return {
        ...state,
        appOwners: {
          loading: false,
          data: action.data,
        },
      };
    case 'FETCH_APP_USER_RATINGS_START':
      return {
        ...state,
        appsFeedback: {
          loading: true,
          data: [],
          pagination: {},
        },
      };
    case 'FETCH_APP_USER_RATINGS_FINISHED':
      return {
        ...state,
        appsFeedback: {
          loading: false,
          data: action?.data,
          pagination: action.pagination || {},
        },
      };
    default:
      return state;
  }
};

export default reducer;
