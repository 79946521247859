import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Select, Tooltip, Radio } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import FileUpload from '../../components/FileUpload';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import './request-access.css';
import appActions from '../../redux/actions/actions';
import dashboardActions from '../../redux/actions/dashboardActions';
//import { UPDATE_SHOW_BACKGROUND } from '../../redux/constants/types';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';

const { Option } = Select;

const RequestAccess = ({
  fromModal,
  app,
  handleSuccess,
  handleCancel,
  isDashboards,
  Access,
}) => {
  console.log('Access', Access);
  console.log('app@@@@@@@', app);
  const [appForm] = Form.useForm();
  const { appId, appName } = useParams();
  const [reasonDocs, setReasonDocs] = useState([]);

  const [formData1, setFormData1] = useState({});
  const [projectSearch, setProjectSearch] = useState('');
  const [adProjectSearch, setAdProjectSearch] = useState('');
  const [adDashSearch, setAdDashSearch] = useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const entity = useSelector((state) => {
    return state.root.entity;
  });
  // console.log('entity::::>>>>111', entity);
  // console.log('isDashboards::::>>>>', isDashboards);
  const appSBG_Users = useSelector((state) => {
    return state[entity]?.appSBG_Users?.data || [];
  });
  const appSBU_Users = useSelector((state) => {
    return state[entity]?.appSBU_Users?.data || [];
  });
  const appBU_Users = useSelector((state) => {
    return state[entity]?.appBU_Users?.data || [];
  });
  const appProject_Users = useSelector((state) => {
    return state[entity]?.appProject_Users?.data || [];
  });

  const appAppADGroups = useSelector((state) => {
    return state[entity].appAppADGroups;
  });
  const dashADGroups = useSelector((state) => {
    return state[entity].dashADGroups;
  });
  console.log('appAppADGroups::::', appAppADGroups);
  console.log('dashADGroups::::', dashADGroups);

  useEffect(() => {
    if (entity === 'app') {
      const payload = {
        app_id: parseInt(app?.id),
        search: adProjectSearch ? adProjectSearch : '',
        // category_type: 'all',
      };

      dispatch(appActions.getAppADGroups(payload));
    }
  }, [adProjectSearch]);

  useEffect(() => {
    if (entity === 'dashboard') {
      const payload = {
        app_id: parseInt(app?.id),
        search: adDashSearch ? adDashSearch : '',
      };

      dispatch(dashboardActions.getAppADGroups(payload));
    }
  }, [adDashSearch]);

  const handleADNameSearch = useMemo(() => {
    const loadOptions = (adProjectSearch) => {
      setAdProjectSearch(adProjectSearch);
    };
    return debounce(loadOptions, 400);
  }, []);

  const handleDashADNameSearch = useMemo(() => {
    const loadOptions = (adDashSearch) => {
      setAdDashSearch(adDashSearch);
    };
    return debounce(loadOptions, 400);
  }, []);

  useEffect(() => {
    if (isDashboards || entity === 'sarovar') {
      const payload = {
        dashboard_id: app?.id,
        page: 1,
        per_page: 1000,
        search: projectSearch,
      };

      if (formData1?.access_level === 'SBG') {
        dispatch(dashboardActions.getSBGUsers(payload));
      } else if (formData1?.access_level === 'SBU') {
        dispatch(dashboardActions.getSBUUsers(payload));
      } else if (formData1?.access_level === 'BU') {
        dispatch(dashboardActions.getBUUsers(payload));
      } else if (formData1?.access_level === 'Project') {
        dispatch(dashboardActions.getProjectUsers(payload));
      }
    } else {
      const payload = {
        app_id: app?.id,
        page: 1,
        per_page: 1000,
        search: projectSearch,
      };

      if (formData1?.access_level === 'SBG') {
        dispatch(appActions.getSBGUsers(payload));
      } else if (formData1?.access_level === 'SBU') {
        dispatch(appActions.getSBUUsers(payload));
      } else if (formData1?.access_level === 'BU') {
        dispatch(appActions.getBUUsers(payload));
      } else if (formData1?.access_level === 'Project') {
        dispatch(appActions.getProjectUsers(payload));
      }
    }
  }, [formData1?.access_level, projectSearch]);

  const entityType = isDashboards
    ? 'dashboard'
    : entity === 'sarovar'
    ? 'sarovar'
    : 'app';

  const handleFormDataOnChange = (slug, value) => {
    //console.log('slug, value', slug, value);

    setFormData1({
      ...formData1,
      [slug]: value,
    });
  };

  useEffect(() => {
    appForm.setFieldsValue({
      selectUsers: undefined,
    });
    setProjectSearch('');
  }, [formData1?.access_level]);

  const handleNameSearch = useMemo(() => {
    const loadOptions = (projectSearch) => {
      setProjectSearch(projectSearch);
    };
    return debounce(loadOptions, 400);
  }, []);

  const onUserSelect = (val) => {
    //setSelcetdCandidates(val);
  };

  const requestAccessCallback = (success, error) => {
    console.log('error', error);
    if (success) {
      message.success(
        `Cool! The ${entityType} request has been successfully sent for approvals`
      );
      if (fromModal) {
        handleSuccess && handleSuccess();
      } else {
        navigate('/home');
      }
    } else {
      message.error(
        error?.response?.data?.msg ||
          error?.message ||
          'Failure: Something went wrong. We are looking into it'
      );
    }
  };

  const onFinish = () => {
    const { reason, additionalInformation, selectUsers, selectADGroup } =
      appForm.getFieldsValue();
    const payload = new FormData();
    payload.append(
      isDashboards || entity === 'sarovar' ? 'dashboard_id' : 'app_id',
      fromModal ? app.id : appId
    );
    payload.append('user_id', userDetails?.id);
    payload.append('reason', reason);
    if (additionalInformation) {
      payload.append('addnl_comments', additionalInformation || '');
    }
    reasonDocs.forEach((itm) => {
      payload.append('reason_doc', itm.originFileObj);
    });

    if (app?.access_levels_enabled === true) {
      if (
        app?.isRoleAccessLevels === true ||
        app?.isBothAccessLevels === true
      ) {
        payload.append('role_ADgroup_id', selectADGroup);
      }

      if (
        app?.isRightsAccessLevels === true ||
        app?.isBothAccessLevels === true
      ) {
        payload.append('access_levels_type', formData1?.access_level);
        payload.append('selected_access_levels', JSON.stringify(selectUsers));
      }
    }

    if (Access === 'Revise') {
      payload.append('revise_access', true);
    } else {
      payload.append('revise_access', false);
    }

    // app?.access_levels_enabled === true
    //   ? payload.append('selected_access_levels', JSON.stringify(selectUsers))
    //   : null;

    if (isDashboards || entity === 'sarovar') {
      dispatch(
        dashboardActions.createAccessRequest(payload, requestAccessCallback)
      );
    } else {
      dispatch(appActions.requestAccess(payload, requestAccessCallback));
    }
  };

  const userDetails = JSON.parse(localStorage.getItem('user_details')) || {};

  const goBack = () => {
    navigate(-1);
  };

  const onFileChange = (files) => {
    // if (val.status === 'removed') {
    // } else {
    //   setReasonDocs([...reasonDocs, val]);
    // }
    setReasonDocs([...(files.fileList || [])]);
  };

  const entityName =
    isDashboards || entity === 'sarovar' ? app.dash_name : app.app_name;

  return (
    <>
      <div className="request-access-container">
        {fromModal ? null : (
          <div className="app-back-button" onClick={goBack}>
            <ArrowLeftOutlined />{' '}
            <span className="back-text">Go to home page</span>
          </div>
        )}
        <div className="request-access-header">
          Fill out this form for{' '}
          {isDashboards && entity === 'dashboard'
            ? Access === 'Revise'
              ? 'revise analytics & insights'
              : 'analytics & insights'
            : isDashboards && entity === 'sarovar'
            ? Access === 'Revise'
              ? 'revise Module'
              : 'module'
            : Access === 'Revise'
            ? 'revise app'
            : 'app'}{' '}
          access
          <div
            className="requester-info"
            style={fromModal ? { width: 'auto' } : {}}
          >
            <div className="item">
              <span className="requester-label">
                {isDashboards
                  ? 'Dashboard'
                  : entity === 'sarovar'
                  ? 'Module'
                  : 'App'}
                :
              </span>
              <Tooltip title={fromModal ? entityName : appName}>
                <span className="requester-value">
                  {fromModal ? entityName : appName}
                </span>
              </Tooltip>
            </div>
            <div className="item">
              <span className="requester-label">Name:</span>
              <Tooltip title={userDetails?.fullname}>
                <span className="requester-value">{userDetails?.fullname}</span>
              </Tooltip>
            </div>
            <div className="item">
              <span className="requester-label">Designation:</span>
              <Tooltip title={userDetails?.designation}>
                <span className="requester-value">
                  {userDetails?.designation}
                </span>
              </Tooltip>
            </div>
          </div>
        </div>

        <div>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="request-access-form"
            layout="vertical"
            form={appForm}
          >
            <Form.Item
              label="Reason for access"
              name="reason"
              rules={[
                {
                  required: true,
                  message: 'Please enter the reason for access!',
                },
              ]}
            >
              <TextArea placeholder="Enter reason.." />
            </Form.Item>
            {app?.access_levels_enabled === true ? (
              <>
                {app?.isRoleAccessLevels === true ||
                app?.isBothAccessLevels === true ? (
                  <Form.Item
                    label="Select Access Group"
                    name="selectADGroup"
                    rules={[
                      {
                        required:
                          app?.isRoleAccessLevels === true ||
                          app?.isBothAccessLevels === true
                            ? true
                            : false,
                        message: `Please select access group`,
                      },
                    ]}
                  >
                    <Select
                      placeholder="Please select!"
                      //mode="multiple"
                      showSearch
                      optionFilterProp="children"
                      onSearch={
                        entity === 'app'
                          ? handleADNameSearch
                          : entity === 'dashboard'
                          ? handleDashADNameSearch
                          : ''
                      }
                    >
                      {entity === 'app'
                        ? appAppADGroups?.data?.data?.map((usr) => (
                            <Option key={usr?.id} value={usr?.id}>
                              {`${usr?.group_name}`}
                            </Option>
                          ))
                        : dashADGroups?.data?.data?.map((usr) => (
                            <Option key={usr?.id} value={usr?.id}>
                              {`${usr?.group_name}`}
                            </Option>
                          ))}
                    </Select>
                  </Form.Item>
                ) : null}
                {app?.isRightsAccessLevels === true ||
                app?.isBothAccessLevels === true ? (
                  <Form.Item
                    name="access_level"
                    label="Select the level of access by:"
                    rules={[
                      {
                        required:
                          app?.isRightsAccessLevels === true ||
                          app?.isBothAccessLevels === true
                            ? true
                            : false,
                        message: 'Please select the level of access by',
                      },
                    ]}
                  >
                    <Radio.Group
                      defaultValue={'open'}
                      onChange={(e) =>
                        handleFormDataOnChange('access_level', e.target.value)
                      }
                    >
                      <Radio value={'SBG'}>SBG</Radio>
                      <Radio value={'SBU'}>SBU</Radio>
                      <Radio value={'BU'}>BU</Radio>
                      <Radio value={'Project'}>Project</Radio>
                    </Radio.Group>
                  </Form.Item>
                ) : null}
                {formData1?.access_level && (
                  <Form.Item
                    label={`Select ${
                      formData1?.access_level === 'SBG'
                        ? 'SBG'
                        : formData1?.access_level === 'SBU'
                        ? 'SBU'
                        : formData1?.access_level === 'BU'
                        ? 'BU'
                        : formData1?.access_level === 'Project'
                        ? 'Project'
                        : ''
                    }`}
                    name="selectUsers"
                    rules={[
                      {
                        required:
                          app?.access_levels_enabled === true ? true : false,
                        message: `Please select ${
                          formData1?.access_level === 'SBG'
                            ? 'SBG'
                            : formData1?.access_level === 'SBU'
                            ? 'SBU'
                            : formData1?.access_level === 'BU'
                            ? 'BU'
                            : formData1?.access_level === 'Project'
                            ? 'Project'
                            : ''
                        }`,
                      },
                    ]}
                  >
                    <Select
                      placeholder="Please select!"
                      mode="multiple"
                      showSearch
                      optionFilterProp="children"
                      onSearch={handleNameSearch}
                    >
                      {formData1?.access_level === 'SBG'
                        ? appSBG_Users?.data?.map((usr) => (
                            <Option key={usr?.id} value={usr?.id}>
                              {`${usr?.SBG_name} `}{' '}
                            </Option>
                          ))
                        : formData1?.access_level === 'SBU'
                        ? appSBU_Users?.data?.map((usr) => (
                            <Option key={usr?.id} value={usr?.id}>
                              {`${usr?.SBU_name} `}{' '}
                            </Option>
                          ))
                        : formData1?.access_level === 'BU'
                        ? appBU_Users?.data?.map((usr) => (
                            <Option key={usr?.id} value={usr?.id}>
                              {`${usr?.BU_name} `}{' '}
                            </Option>
                          ))
                        : formData1?.access_level === 'Project'
                        ? appProject_Users?.data?.map((usr) => (
                            <Option key={usr?.id} value={usr?.id}>
                              {`${usr?.project_name} (${
                                usr?.project_code || ''
                              }) `}{' '}
                            </Option>
                          ))
                        : null}
                    </Select>
                  </Form.Item>
                )}
              </>
            ) : null}
            <Form.Item
              label="Additional Information"
              name="additionalInformation"
            >
              <TextArea placeholder="Enter any other information.." />
            </Form.Item>
            <Form.Item label="Documents" name="documents">
              <FileUpload
                beforeUpload={(file) => {
                  return false;
                }}
                handleFileChange={onFileChange}
                accept="image/*"
              />
            </Form.Item>
            <Form.Item className="text-right">
              <Button
                type="outlined"
                className="cancel-btn"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                className="submit-btn ml-2"
              >
                {Access === 'Revise' ? 'Revise Access' : 'Request Access'}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default RequestAccess;
