import { Button, message, Popconfirm, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import appActions from '../../redux/actions/actions';
import './announcements.scss';
import moment from 'moment';

const Announcements = () => {
  const [announcements, setAnnouncements] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getAnnouncementsData();
  }, []);

  const getAnnouncementsData = () => {
    console.log('welcome data');
    appActions
      .getWelcomeInfo()
      .then((res) => {
        console.log('resp', res);
        setAnnouncements(res.data?.anouncement_info?.data || []);
      })
      .catch((err) => {
        console.log('error', err);
        setAnnouncements({});
      });
  };

  const editAnnouncement = (record) => {
    console.log('edit ann', record);
    navigate(`/edit-announcement`, { state: { announcement: record } });
  };

  const deleteAnnouncement = (record) => {
    console.log('delete announcemn', record);
    appActions
      .makeApiCall(
        'delete',
        `anouncement/delete_anouncement?id=${record.id}`,
        {}
      )
      .then((res) => {
        console.log('delete resp', res);
        if (res.data.success) {
          message.success('Deleted successfully!');
          getAnnouncementsData();
        } else {
          message.error(
            res.data?.msg || 'Something went wrong. We are looking into it'
          );
        }
      })
      .catch((err) => {
        console.log('delete error', err);
        message.error('Something went wrong. We are looking into it');
      });
  };

  const AnnColumns = [
    { title: 'Title', dataIndex: 'title', key: 'title' },
    {
      title: 'Date',
      dataIndex: 'anouncement_date',
      key: 'anouncement_date',
      render: (val) => moment(new Date(val)).format('DD-MM-YYYY'),
    },
    {
      title: 'Action',
      key: 'action',
      render: (val, record) => {
        return (
          <div>
            <Button onClick={() => editAnnouncement(record)}>Edit</Button>
            <Popconfirm
              title="Are you sure you want to delete this announcement?"
              description=""
              okText="Delete"
              okButtonProps={{ danger: true, type: 'default' }}
              cancelText="No"
              onConfirm={() => {
                deleteAnnouncement(record);
              }}
            >
              <Button danger className="ms-2">
                Delete
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <div className="announcements-list p-4">
      <div className="form-header d-flex align-items-center justify-content-center">
        <span>Announcements</span>
        <Button type="primary" href="/add-announcement" className="ms-2">
          Add Announcement
        </Button>
      </div>
      {/* <div>
          <Button href="/add-announcement">Add Announcement</Button>
        </div> */}
      <div>
        <Table columns={AnnColumns} dataSource={announcements} />
      </div>
    </div>
  );
};

export default Announcements;
