import React, { useState } from 'react';
import {
  Button,
  Dropdown,
  Menu,
  Modal,
  //Checkbox,
  DatePicker,
  Row,
  Col,
} from 'antd';
import './requests.css';
import { DownOutlined, FileTextOutlined } from '@ant-design/icons';
import appActions from '../../redux/actions/actions';
import { useDispatch } from 'react-redux';
import dashboardActions from '../../redux/actions/dashboardActions';
import TextArea from 'antd/lib/input/TextArea';
import ApproverTable from './approverTable';
import moment from 'moment';

const RequestCard = ({
  request,
  requestIndex,
  approveSuccessCallback,
  isDashboards,
  handleSelection,
  selectedRequests,
  selectAll,
  viewOnly,
  status,
  entity,
}) => {
  const dispatch = useDispatch();

  const [rejectionReason, setRejectionReason] = useState('');

  const [showRejectModal, setShowRejectModal] = useState({
    visible: false,
    data: null,
  });
  const [acceptReason, setAcceptReason] = useState('');
  const [showAcceptModal, setShowAcceptModal] = useState({
    visible: false,
    data: null,
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [unselected, setUnselected] = useState([]);
  const [expiryDate, setExpiryDate] = useState();

  const docsKey =
    isDashboards || entity === 'sarovar' ? 'Dash_Documents' : 'Documents';
  const entityKey = isDashboards ? 'Dashboard' : 'App';

  const getAppIcon = () => {
    const profileImg = request?.[entityKey]?.[docsKey]?.filter(
      (itm) => itm.image_use === 'profile'
    );
    return profileImg ? profileImg[0]?.doc_url || '' : 'pp1.png';
  };
  console.log('request>>>>>>>>>>>', request);
  const getMenu = () => {
    return <Menu items={getMenuItems()}></Menu>;
  };

  const getMenuItems = () => {
    const items = request?.[docsKey]?.map((doc) => ({
      label: (
        <a
          //href={doc.doc_url}
          href={`${process.env.REACT_APP_API_URL2}/fetch-files/${doc.doc_url
            ?.split('/')
            .pop()}`}
          target="_blank"
          rel="noreferrer"
        >
          {doc.file_name}
        </a>
      ),
      key: doc.id,
    }));
    return items || [];
  };

  const approveRequest = (status) => {
    if (isDashboards || entity === 'sarovar') {
      dispatch(
        dashboardActions.approveRequests(
          {
            status: 'accepted',
            action: [
              {
                dashboard_id: request.Dashboard?.id,
                user_id: request?.User?.id,
                status: 'accepted',
                dash_approval_id: request?.id,
                approver_comments: acceptReason != null ? acceptReason : '',
                expiry_date:
                  expiryDate === undefined
                    ? null
                    : moment(expiryDate).format('YYYY-MM-DD'),
                accepted: selectedRow?.length > 0 ? selectedRow : [],
                rejected: unselected?.length > 0 ? unselected : [],
              },
            ],
          },
          approveSuccessCallback
        )
      );
    } else {
      dispatch(
        appActions.approveRequests(
          {
            status: 'accepted',
            action: [
              {
                app_id: request.App?.id,
                user_id: request?.User?.id,
                status: 'accepted',
                approval_id: request?.id,
                approver_comments: acceptReason != null ? acceptReason : '',
                expiry_date:
                  expiryDate === undefined
                    ? null
                    : moment(expiryDate).format('YYYY-MM-DD'),
                accepted: selectedRow?.length > 0 ? selectedRow : [],
                rejected: unselected?.length > 0 ? unselected : [],
              },
            ],
          },
          approveSuccessCallback
        )
      );
    }
  };

  const openRejectModal = (data) => {
    setShowRejectModal({
      visible: true,
      data,
    });
  };
  const openAcceptModal = (data) => {
    setShowAcceptModal({
      visible: true,
      data,
    });
  };

  const rejectRequest = () => {
    if (isDashboards || entity === 'sarovar') {
      dispatch(
        dashboardActions.approveRequests(
          {
            status: 'rejected',
            action: [
              {
                status: 'rejected',
                approver_comment: rejectionReason,
                user_id: request?.User?.id,
                dashboard_id: request.Dashboard?.id,
                dash_approval_id: request?.id,
              },
            ],
          },
          approveSuccessCallback
        )
      );
    } else {
      dispatch(
        appActions.approveRequests(
          {
            status: 'rejected',
            action: [
              {
                status: 'rejected',
                approver_comment: rejectionReason,
                user_id: request?.User?.id,
                app_id: request.App?.id,
                approval_id: request.id,
              },
            ],
          },
          approveSuccessCallback
        )
      );
    }
  };

  const onCheckChange = (e) => {
    handleSelection(e.target.checked);
  };
  // console.log('SelectedRow: ', selectedRow);
  // console.log('expiryDate:', expiryDate);
  // console.log('acceptReason:', acceptReason);
  // console.log('unselected:', unselected);
  //console.log('ShowAcceptModal:', showAcceptModal);
  // console.log('isDashboards:', isDashboards);

  const access_levels_data =
    isDashboards || entity === 'sarovar'
      ? showAcceptModal?.data?.dash_access_levels_details
      : showAcceptModal?.data?.app_access_levels_details;

  return (
    <div className="request-card">
      <div className="d-flex">
        <div className="request-app-title">
          {!viewOnly && (
            <span className="check-icon">
              {/*  <Checkbox
                checked={
                  selectedRequests.indexOf(requestIndex) >= 0 || selectAll
                }
                onChange={onCheckChange}
              /> */}
            </span>
          )}
          <span>
            <img
              style={{ width: 40 }}
              //src={getAppIcon()}
              src={request?.[entityKey]?.profile_images[0]?.image_url}
              alt=""
            />
          </span>
          {isDashboards || entity === 'sarovar' ? (
            <span className="app-name">{request?.Dashboard?.dash_name}</span>
          ) : (
            <span className="app-name">{request?.App?.app_name}</span>
          )}
        </div>
        <div className="request-details">
          <div className="d-flex">
            <div className="prop-label">Name:</div>
            <div className="prop-value">{`${request?.User?.first_name} ${
              request?.User?.last_name || ''
            }`}</div>
          </div>
          <div className="d-flex mt-1">
            <div className="prop-label">Designation:</div>
            <div className="prop-value">{request?.User?.designation}</div>
          </div>
          <div className="d-flex mt-1">
            <div className="prop-label">Reason for access:</div>
            <div className="prop-value">{request?.reason || ''}</div>
          </div>
          <div className="d-flex mt-1">
            <div className="prop-label">Additional Information:</div>
            <div className="prop-value">{request?.addnl_comments || ''}</div>
          </div>
          {viewOnly && status !== 'accepted' && (
            <div className="d-flex mt-1">
              <div className="prop-label">Approver Comment:</div>
              {isDashboards || entity === 'sarovar' ? (
                <div className="prop-value">{request?.approver_comment}</div>
              ) : (
                <div className="prop-value">
                  {request?.approver_comments || ''}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="actions-row d-flex">
        {request?.[docsKey]?.length === 1 ? (
          <>
            <a
              // href={request?.[docsKey][0]?.doc_url}
              href={`${process.env.REACT_APP_API_URL2}/fetch-files/${request?.[
                docsKey
              ][0]?.doc_url
                ?.split('/')
                .pop()}`}
              target="_blank"
              rel="noreferrer"
            >
              {request?.[docsKey][0]?.file_name}
            </a>
          </>
        ) : (
          <Dropdown overlay={getMenu()}>
            <div className="documents-chip">
              <FileTextOutlined />
              <span className="doc-name">{`${request?.[docsKey]?.length} documents attached`}</span>
            </div>
          </Dropdown>
        )}
        {!viewOnly && (
          <div className="">
            <Button
              className="app-buttons reject-btn"
              onClick={() => openRejectModal(request)}
            >
              Reject
            </Button>
            <Button
              className="app-buttons accept-btn"
              //onClick={() => approveRequest('accepted')}
              onClick={() => openAcceptModal(request)}
            >
              Accept
            </Button>
          </div>
        )}
      </div>
      {showRejectModal.visible && (
        <Modal
          visible={showRejectModal.visible}
          onCancel={() => {
            setRejectionReason('');
            setShowRejectModal({ visible: false, data: null });
          }}
          onOk={rejectRequest}
        >
          <div className="modal-header mb-2">Reason</div>
          <div>
            <TextArea
              onChange={(e) => setRejectionReason(e.target.value)}
              value={rejectionReason}
            />
          </div>
        </Modal>
      )}
      {showAcceptModal.visible && (
        <Modal
          visible={showAcceptModal.visible}
          title={
            <div className="modal-header mb-2" key={requestIndex}>
              Access Level Details
            </div>
          }
          onCancel={() => {
            setAcceptReason('');
            setSelectedRowKeys([]);
            setSelectedRow([]);
            setUnselected([]);
            setShowAcceptModal({ visible: false, data: null });
          }}
          onOk={approveRequest}
          okText={'Accept'}
          okButtonProps={{
            disabled:
              access_levels_data?.length > 0 ? selectedRow?.length <= 0 : '',
          }}
          width={'70%'}
        >
          <div style={{ marginTop: '-15px' }}>
            <div>
              {entity === 'app' ? (
                showAcceptModal?.data?.app_role_ADgroup !== null ? (
                  <div className="mt-2 mb-4">
                    <Row
                      gutter={8}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Col sapn={24}>
                        <sapn
                          style={{
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                            fontSize: '13px',
                          }}
                        >
                          Access Group Name:
                        </sapn>
                        <sapn
                          style={{
                            textTransform: 'capitalize',
                            marginLeft: '5px',
                            marginRight: '15px',
                            fontSize: '13px',
                            fontWeight: 500,
                          }}
                        >
                          {showAcceptModal?.data?.app_role_ADgroup?.group_name}
                        </sapn>
                      </Col>
                      <Col sapn={24}>
                        <sapn
                          style={{
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                            fontSize: '13px',
                          }}
                        >
                          Category:
                        </sapn>
                        <sapn
                          style={{
                            textTransform: 'capitalize',
                            marginLeft: '5px',
                            marginRight: '15px',
                            fontSize: '13px',
                            fontWeight: 500,
                          }}
                        >
                          {showAcceptModal?.data?.app_role_ADgroup?.category}
                        </sapn>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  ''
                )
              ) : showAcceptModal?.data?.dash_role_ADgroup !== null ? (
                <div className="mt-2 mb-4">
                  <Row
                    gutter={8}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Col sapn={24}>
                      <sapn
                        style={{
                          textTransform: 'uppercase',
                          fontWeight: 'bold',
                          fontSize: '13px',
                        }}
                      >
                        Access Group Name:
                      </sapn>
                      <sapn
                        style={{
                          textTransform: 'capitalize',
                          marginLeft: '5px',
                          marginRight: '15px',
                          fontSize: '13px',
                          fontWeight: 500,
                        }}
                      >
                        {showAcceptModal?.data?.dash_role_ADgroup?.group_name}
                      </sapn>
                    </Col>
                    <Col sapn={24}>
                      <sapn
                        style={{
                          textTransform: 'uppercase',
                          fontWeight: 'bold',
                          fontSize: '13px',
                        }}
                      >
                        Category:
                      </sapn>
                      <sapn
                        style={{
                          textTransform: 'capitalize',
                          marginLeft: '5px',
                          marginRight: '15px',
                          fontSize: '13px',
                          fontWeight: 500,
                        }}
                      >
                        {showAcceptModal?.data?.dash_role_ADgroup?.category}
                      </sapn>
                    </Col>
                  </Row>
                </div>
              ) : (
                ''
              )}
            </div>
            {access_levels_data?.length > 0 ? (
              <div className="mb-2">
                <div className="reason-header mb-1">
                  Expiry Date{' '}
                  <span style={{ fontSize: '12px' }}>(Optional)</span>
                </div>
                <DatePicker
                  style={{ width: '50%' }}
                  format={'DD-MMM-YYYY'}
                  onChange={(_, dateValue) => setExpiryDate(dateValue)}
                  disabledDate={(current) => {
                    return moment().add(-1, 'days') >= current;
                  }}
                ></DatePicker>
              </div>
            ) : (
              ''
            )}
            <div className="mb-2">
              <div className="reason-header mb-1">
                Reason <span style={{ fontSize: '12px' }}>(Optional)</span>
              </div>
              <TextArea
                onChange={(e) => setAcceptReason(e.target.value)}
                value={acceptReason}
              />
            </div>
            {access_levels_data?.length > 0 ? (
              <div className="mt-2 mb-4">
                <ApproverTable
                  data={
                    isDashboards || entity === 'sarovar'
                      ? showAcceptModal?.data?.dash_access_levels_details
                      : showAcceptModal?.data?.app_access_levels_details
                  }
                  setSelectedRowKeys={setSelectedRowKeys}
                  selectedRowKeys={selectedRowKeys}
                  setSelectedRow={setSelectedRow}
                  selectedRow={selectedRow}
                  setUnselected={setUnselected}
                />
              </div>
            ) : (
              ''
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default RequestCard;
