import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import AppHeader from '../../components/AppHeader';
import { UPDATE_SHOW_HEADER } from '../../redux/constants/types';
import './file-viewer.scss';
const FileViewer = () => {
  const [numPages, setNumPages] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const filepath = searchParams.get('filepath');
  console.log('fielpath', filepath);

  console.log('search params', searchParams.get('filepath'));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: UPDATE_SHOW_HEADER, show: false });
  }, []);

  useEffect(() => {
    return () => {
      dispatch({ type: UPDATE_SHOW_HEADER, show: true });
    };
  }, []);

  return (
    <div className="file-viewer">
      <AppHeader hideLinks goToHome={() => {}} />
      <div className="ms-auto download-btn">
        <Button type="link" href={filepath}>
          Download
        </Button>
      </div>
      <Document
        file={{ url: filepath }}
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
      >
        {Array.apply(null, Array(numPages))
          .map((x, i) => i + 1)
          .map((page) => (
            <Page pageNumber={page}>
              {/* <View fixed>// fancy things here</View> */}
            </Page>
          ))}
      </Document>
    </div>
  );
};

export default FileViewer;
