import axios from 'axios';
import {
  API_BASE_URL,
  SOVREN_ACCOUNT_ID,
  SOVREN_SERVICE_KEY,
  SOVREN_PARSER_BASE_URL,
  CV_PARSING,
} from '../constants/appConstants';

class AppService {
  getBaseUrl(type = 'api') {
    return type === 'api' ? API_BASE_URL : '';
  }

  authHeader(getType = 1) {
    const user = JSON.parse(this.getCookie('user'));

    return getType === 2 && user && user.token
      ? { Authorization: 'Bearer ' + user.token }
      : getType === 3 && user && user.token
      ? {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + user.token,
        }
      : { 'Content-Type': 'application/json' };
  }

  createApiCall(url, data) {
    const user = JSON.parse(this.getCookie('user'));

    let authHeaderList = {
      headers: {
        Authorization: 'Bearer ' + user.token,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };
    return new Promise(function (resolve, reject) {
      axios.post(API_BASE_URL + url, data, authHeaderList).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          let result = {
            data: { message: '' },
            status: 404,
            statusText: 'Not Found',
          };
          if (typeof error.response !== undefined) {
            result = error.response;
          }
          reject(result);
        }
      );
    });
  }

  apiCall(type = 'get', url, payload) {
    let authHeaderList = '';
    if (type === 'post') authHeaderList = { headers: this.authHeader(3) };
    else {
      authHeaderList = this.authHeader(3);
      payload['headers'] = authHeaderList;
    }
    return new Promise(function (resolve, reject) {
      if (type === 'post') {
        axios.post(API_BASE_URL + url, payload, authHeaderList).then(
          (response) => {
            resolve(response);
          },
          (error) => {
            let result = {
              data: { message: '' },
              status: 404,
              statusText: 'Not Found',
            };
            if (typeof error.response !== undefined) {
              result = error.response;
            }
            reject(result);
          }
        );
      } else {
        axios.get(API_BASE_URL + url, payload).then(
          (response) => {
            resolve(response);
          },
          (error) => {
            let result = {
              data: { message: '' },
              status: 404,
              statusText: 'Not Found',
            };
            if (error.response !== undefined) {
              result = error.response;
            }
            reject(result);
          }
        );
      }
    });
  }

  login(payload) {
    return axios.post(API_BASE_URL + 'recruiter/login', payload);
  }

  uploadResumes = (file) => {
    var formData = new FormData();
    formData.append('file-0', file);
    let headers = {
      'X-Api-Key': CV_PARSING.API_KEY,
      'Content-Type': 'multipart/form-data',
    };
    return axios.post(
      CV_PARSING.API_URL + `api/resources/${CV_PARSING.RESOURCE_ID}/resumes`,
      formData,
      {
        headers,
      }
    );
  };

  parseCV(data) {
    // let headers = {
    //   "Sovren-AccountId": SOVREN_ACCOUNT_ID,
    //   "Sovren-ServiceKey": SOVREN_SERVICE_KEY,
    //   "Accept": "application/json"
    // };
    // return axios.post(SOVREN_PARSER_BASE_URL + "parser/resume", data, {
    //   headers
    // });

    // var formData = new FormData();
    // formData.append('file-0', file);
    // let headers = {
    //   "X-Api-Key": CV_PARSING.API_KEY,
    //   'Content-Type': 'multipart/form-data'
    // };
    // return axios.post(CV_PARSING.API_URL + `api/resources/${CV_PARSING.RESOURCE_ID}/resumes`, formData, {
    //   headers,

    // });

    let headers = {
      'Sovren-AccountId': SOVREN_ACCOUNT_ID,
      'Sovren-ServiceKey': SOVREN_SERVICE_KEY,
      'Content-Type': 'application/json',
    };
    return axios.post(SOVREN_PARSER_BASE_URL + 'parser/resume', data, {
      headers,
    });
  }

  getResumeDetailsByResumeId(resumeId) {
    let headers = {
      'X-Api-Key': CV_PARSING.API_KEY,
    };
    return axios.get(CV_PARSING.API_URL + `api/resumes/${resumeId}`, {
      headers,
    });
  }

  setCookie(name, value, days) {
    var expires = '';
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 59 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  }

  getCookie(name) {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  eraseCookie(name) {
    document.cookie =
      name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  logout() {
    const authHeaderValue = { headers: this.authHeader(2) };
    axios.post(API_BASE_URL + 'recruiter/logout', '', authHeaderValue);
    this.eraseCookie('user');
    return true;
  }

  register(username, email, password) {
    return axios.post(API_BASE_URL + 'signup', {
      username,
      email,
      password,
    });
  }

  checkLoggedIn() {
    return this.getCookie('user') && JSON.parse(this.getCookie('user')).token
      ? true
      : false;
  }

  getLoggedInUser() {
    const username = this.getCookie('user')
      ? JSON.parse(this.getCookie('user')).username
      : '';
    return username;
  }

  checkAdminLogin() {
    return this.getCookie('user') &&
      JSON.parse(this.getCookie('user')).role === 'admin'
      ? true
      : false;
  }

  getCurrentUser() {
    return JSON.parse(this.getCookie('user'));
  }

  changePassword(url, data) {
    const user = JSON.parse(this.getCookie('user'));

    let authHeaderList = {
      headers: {
        Authorization: 'Bearer ' + user.token,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };
    return new Promise(function (resolve, reject) {
      axios.post(API_BASE_URL + url, data, authHeaderList).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          let result = {
            data: { message: '' },
            status: 404,
            statusText: 'Not Found',
          };
          if (typeof error.response !== undefined) {
            result = error.response;
          }
          reject(result);
        }
      );
    });
  }
}

export default new AppService();
