import {
  ArrowLeftOutlined,
  DeleteOutlined,
  UploadOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Radio,
  Select,
  Upload,
  Row,
  Col,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_SHOW_BACKGROUND } from '../../redux/constants/types';
import { useLocation, useNavigate } from 'react-router-dom';
import './addapp.css';
import appActions from '../../redux/actions/actions';
import { toTitleCase } from '../../components/AppCard';
import moment from 'moment';
import dashboardActions from '../../redux/actions/dashboardActions';
import sarovarActions from '../../redux/actions/dashboardActions';
import { debounce } from 'lodash';
//Projects, GST, Receivables, Payables, TMU
const groups = [
  {
    id: 1,
    function_name: 'Projects',
  },
  {
    id: 2,
    function_name: 'GST',
  },
  {
    id: 3,
    function_name: 'Receivables',
  },
  {
    id: 4,
    function_name: 'Payables',
  },
  {
    id: 5,
    function_name: 'TMU',
  },
];

const AddSarovar = () => {
  const [appForm] = Form.useForm();
  const catOptions = useSelector((state) => {
    return state.dashboard.catOptions.data || [];
  });

  const refreshOptions = useSelector((state) => {
    return state.dashboard.refreshOptions.data || [];
  });

  const appUsers = useSelector((state) => {
    return state.app.appUsers.data || [];
  });

  const location = useLocation();

  const dashboardToEdit = location.state?.appToEdit;

  //console.log('dashboardToEdit::', dashboardToEdit);

  const [imageFiles, setImageFiles] = useState([]);
  const [previousImageFiles, setPreviousImageFiles] = useState([]);
  const [userManual, setUserManual] = useState(null);
  const [previousUserManual, setPreviousUserManual] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [previousProfileImage, setPreviousProfileImage] = useState(null);
  const [featureImage, setFeatureImage] = useState(null);
  const [previousFeatureImage, setPreviousFeatureImage] = useState(null);
  const [manageUsersModal, setManageUsersModal] = useState({});
  const [quickLook, setQuicklook] = useState(null);
  const [previousQuickLook, setPreviousQuickLook] = useState(null);
  const [showAdDetails, setShowAdDetails] = useState({});

  const [addAppLoading, setAddAppLoading] = useState(false);
  const [reportsVal, setReportsVal] = useState({});
  const [options, setOptions] = useState(['']);

  const { Option } = Select;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const currentDashboard = useSelector((state) => {
    return state.dashboard.currentApp.data || {};
  });

  useEffect(() => {
    dispatch({ type: UPDATE_SHOW_BACKGROUND, show: true });
    dispatch(dashboardActions.getRefreshCycleOptions());
    dispatch(appActions.getUsers());
    dispatch(dashboardActions.getCatOptions());
    appForm.setFieldValue('lastUpdated', moment());
  }, []);

  const currentUser = JSON.parse(localStorage.getItem('user_details') || '{}');

  useEffect(() => {
    if (dashboardToEdit) {
      dispatch(
        sarovarActions.getDashboardById({
          appId: dashboardToEdit?.id,
          userId: currentUser.id,
          dashboard_type: 'sarovar',
        })
      );
    }
  }, [dashboardToEdit]);

  useEffect(() => {
    if (dashboardToEdit && currentDashboard && currentDashboard.data) {
      populateFields();
    }
  }, [currentDashboard]);

  const populateFields = () => {
    const {
      dash_name,
      dash_description,
      dash_url,
      benifits,
      how_to_use,
      access_type,
      isBoth,
      isWeb,
      owner_id,
      sme_contact,
      staging_url,
      youtube_url,
      Dash_Documents,
      //function_id,
      Permissions,
      last_updated,
      cycle_id,
      access_levels_enabled,
      dashboard_group,
      sarovar_reports,
    } = currentDashboard.data;

    appForm.setFieldsValue({
      name: dash_name,
      appUrl: dash_url,
      videoUrl: youtube_url,
      description: dash_description,
      benefits: benifits,
      sme_contact: sme_contact,
      appOwners: owner_id,
      howToUse: how_to_use,
      accessType: access_type,
      staging_url: staging_url,
      //category: function_id,
      platform: isBoth ? 'both' : isWeb ? 'web' : 'mobile',
      refreshCycle: cycle_id,
      lastUpdated: moment(last_updated),
      access_levels_enabled: false,
      dashboard_group: dashboard_group,
      // reports: sarovar_reports?.map((item) => ({
      //   id: item?.id,
      //   category: item?.category,
      //   description: item?.description,
      //   report_name: item?.report_name,
      //   report_url: item?.report_url,
      //   is_active: item?.is_active,
      // })),
    });

    const profileImage = Dash_Documents?.find(
      (it) => it.image_use === 'profile'
    );
    const featureImage = Dash_Documents?.find(
      (it) => it.image_use === 'feature_image'
    );
    const appImages = Dash_Documents?.filter(
      (it) => it.image_use === 'gallery_images'
    );
    const userManual = Dash_Documents?.find(
      (it) => it.image_use === 'user_manual'
    );
    const quickLookDoc = Dash_Documents.find(
      (it) => it.image_use === 'quick_look'
    );
    profileImage && setPreviousProfileImage([profileImage]);
    appImages && setPreviousImageFiles(appImages);
    userManual && setPreviousUserManual([userManual]);
    featureImage && setPreviousFeatureImage([featureImage]);
    quickLookDoc && setPreviousQuickLook([quickLookDoc]);
  };

  useEffect(() => {
    const options = dashboardToEdit?.sarovar_reports
      ?.filter((item) => item?.is_active !== false)
      ?.map((item, index) => ({
        id: item?.id,
        category: item?.category,
        description: item?.description,
        report_name: item?.report_name,
        report_url: item?.report_url,
        is_active: item?.is_active,
        newIndex: index++,
      }));

    setOptions(options);
  }, [currentDashboard, dashboardToEdit]);

  //console.log("dashboardToEdit:",dashboardToEdit)

  //console.log('options', options);

  const handleChangeReport = (value, slug) => {
    setReportsVal({
      ...reportsVal,
      [slug]: value,
    });
  };

  /* const handleOptionChange = (index, key, value) => {
    console.log('index, key, value::', index, key, value);
    const updatedOptions = [...options];
    updatedOptions[index][key] = value;
    setOptions(updatedOptions);
  }; */
  const handleOptionChange = (index, key, value) => {
    //console.log('index, key, value::', index, key, value);
    // const updatedOptions = [...options];
    // updatedOptions[index] = {
    //   ...updatedOptions[index],
    //   [key]: value,
    // };
    //console.log('options1111::', options);
    const updatedOptions = [...options];
    updatedOptions[index][key] = value;
    setOptions(updatedOptions);
    // const updatedOptions = options.map((option, i) => {
    //   if (i === index) {
    //     return {
    //       ...option,
    //       [key]: value,
    //     };
    //   }
    //   return option;
    // });
    setOptions(updatedOptions);
  };

  const handleDeleteOption = (index) => {
    console.log('Deleted_index::', index);
    if (index === 0) {
      return; // Do not delete the first option
    }
    const updatedOptions = options?.map((opt, i) => {
      if (i === index) {
        return { ...opt, is_active: false }; // Set isActive to false for deleted item
      }
      return opt;
    });
    setOptions(updatedOptions);
  };

  const handleAddOption = () => {
    const newOption = {
      id: null,
      category: '',
      description: '',
      report_name: '',
      report_url: '',
      is_active: true,
      newIndex: options?.length,
    };
    setOptions([...options, newOption]);
  };
  const onFinish = (values) => {
    const reports = values?.reports?.map((opt) => ({
      report_name: opt?.report_name,
      description: opt?.description,
      report_url: opt?.report_url,
      is_active: true,
    }));
    //console.log('reports::', reports);
    const payload = options?.map((opt) => ({
      id: opt.id,
      dashboard_id: opt.dashboard_id,
      category: opt.category,
      report_name: opt.report_name,
      description: opt.description,
      report_url: opt.report_url,
      is_active: opt.is_active !== undefined ? opt.is_active : true,
    }));
    //console.log('EDIT_PAYLOD', payload);

    const {
      name,
      appUrl,
      description,
      //category,
      howToUse,
      benefits,
      accessType = 'open',
      videoUrl,
      platform = 'web',
      sme_contact,
      appPermissions = [],
      appOwners,
      refreshCycle,
      lastUpdated,
      staging_url,
      access_levels_enabled,
      dashboard_group,
    } = appForm.getFieldsValue();
    setAddAppLoading(true);
    handleDeleteDOcuments();
    //console.log('dashboard_group:::::', dashboard_group);
    const formData = new FormData();
    formData.append('dash_name', name);
    formData.append('dash_description', description);
    formData.append('dash_url', appUrl);
    formData.append('staging_url', staging_url);
    formData.append('how_to_use', howToUse);
    formData.append('benifits', benefits);
    //formData.append('function_id', category);
    formData.append('youtube_url', videoUrl);
    formData.append('access_type', accessType);
    formData.append('access_levels_enabled', false); //access_levels_enabled
    formData.append('isMobile', platform === 'mobile');
    formData.append('isWeb', platform === 'web');
    formData.append('isBoth', platform === 'both');
    formData.append('sme_contact', sme_contact);

    formData.append('dashboard_type', 'sarovar');
    formData.append('dashboard_group', dashboard_group);

    if (dashboardToEdit === undefined) {
      formData.append('sarovar_reports_list', JSON.stringify(reports));
    } else {
      formData.append('sarovar_reports_list', JSON.stringify(payload));
    }

    if (!dashboardToEdit) {
      formData.append('owner_id', JSON.stringify(appOwners));
      formData.append('permited_user_id', JSON.stringify(appPermissions));
    }

    if (profileImage && profileImage.length > 0) {
      formData.append('profile', profileImage[0].originFileObj);
    }

    if (featureImage && featureImage.length > 0) {
      formData.append('feature_image', featureImage[0].originFileObj);
    }

    if (quickLook && quickLook.length > 0) {
      formData.append('quick_look', quickLook[0].originFileObj);
    }

    formData.append('cycle_id', refreshCycle);
    formData.append('last_updated', moment(lastUpdated).format('YYYY-MM-DD'));

    if (imageFiles && imageFiles.length > 0) {
      imageFiles.forEach((itm) => {
        formData.append('gallery_images', itm.originFileObj);
      });
    }
    if (userManual && userManual.length > 0) {
      formData.append('user_manual', userManual[0].originFileObj);
    }

    if (dashboardToEdit) {
      dispatch(
        sarovarActions.editDashboard(
          currentDashboard.data.id,
          formData,
          handleAddAppComplete
        )
      );
    } else {
      dispatch(sarovarActions.addNewDashboard(formData, handleAddAppComplete));
    }
  };

  const handleAddAppComplete = (status) => {
    setAddAppLoading(false);
    if (status.success) {
      const successMessage = dashboardToEdit
        ? 'Details Updated Successfully!'
        : 'Module Added Successfully!';
      message.success(successMessage);
      dispatch(appActions.getPermittedAppsList());
      setOptions(['']);
      navigate('/sarovar');
    } else {
      message.error('Something went wrong, we are looking into it');
    }
  };

  const handleDeleteDOcuments = () => {
    const deletedProfile = previousProfileImage
      ? previousProfileImage.filter((itm) => itm.deleted)
      : [];

    const deletedFeature = previousFeatureImage
      ? previousFeatureImage.filter((itm) => itm.deleted)
      : [];

    const deletedManual = previousUserManual
      ? previousUserManual.filter((itm) => itm.deleted)
      : [];

    const deletedImages = previousImageFiles
      ? previousImageFiles.filter((itm) => itm.deleted)
      : [];

    const deletedQuicklook = previousQuickLook
      ? previousQuickLook.filter((itm) => itm.deleted)
      : [];

    const deletedDocs = [
      ...deletedProfile,
      ...deletedFeature,
      ...deletedManual,
      ...deletedImages,
      ...deletedQuicklook,
    ];
    const deletePayload = [];

    if (deletedDocs && deletedDocs.length > 0) {
      deletedDocs.forEach((itm) => {
        deletePayload.push({
          id: itm.id,
          doc_url: itm.doc_url,
        });
      });
    }
    if (deletePayload.length > 0) {
      dispatch(dashboardActions.deleteDocuments(deletePayload));
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  const handleFileChange = (files) => {
    setImageFiles([...(files.fileList || [])]);
  };

  const handleUserManual = (file) => {
    setUserManual(file.fileList);
  };

  const deleteProfileImage = (idx) => {
    const tempProfileImages = [...previousProfileImage];
    tempProfileImages[0].deleted = true;
    setPreviousProfileImage(tempProfileImages);
  };

  const deleteFeatureImage = (idx) => {
    const tempFeatureImages = [...previousFeatureImage];
    tempFeatureImages[0].deleted = true;
    setPreviousFeatureImage(tempFeatureImages);
  };

  const deleteUserManual = (idx) => {
    const tempUserManual = [...previousUserManual];
    tempUserManual[0].deleted = true;
    setPreviousUserManual(tempUserManual);
  };

  const deleteQuickLook = (idx) => {
    const tempQuicklook = [...previousQuickLook];
    tempQuicklook[0].deleted = true;
    setPreviousQuickLook(tempQuicklook);
  };

  const deleteAppImage = (idx) => {
    const tempAppImages = [...previousImageFiles];
    tempAppImages[idx].deleted = true;
    setPreviousImageFiles(tempAppImages);
  };

  const manageUsers = () => {
    // setManageUsersModal({ visible: true });
    navigate(`/sarovar/manage-users/${currentDashboard.data?.id}`);
  };

  const handleAD_details = () => {
    navigate(`/sarovar/ad-details/${currentDashboard.data?.id}`, {
      state: { currentDashboardData: currentDashboard.data },
    });
  };

  const checkLimits = (s, field) => {
    if (s?.target?.value?.length >= 1000) {
      document.getElementById(field).innerHTML =
        'Max. characters limit reached';
    } else {
      document.getElementById(field).innerHTML = '';
    }
  };

  const deactiveApp = () => {
    appActions
      .makeApiCall(
        'delete',
        `dashboard/deactivate_dashboard?dashboard_id=${currentDashboard.data?.id}`,
        {}
      )
      .then((res) => {
        console.log('deactivation resp', res);
        if (res.data.success) {
          message.success('Deactivated Successfully!');
          setTimeout(() => {
            navigate('/home');
          }, 500);
        }
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const showAdGroupDetails = () => {
    setShowAdDetails({ visible: true });
  };

  const handleOwnersNameSearch = useCallback(
    debounce((value) => {
      if (value.length >= 3) {
        dispatch(appActions.getUsers({ search: value }));
      }
    }, 300),
    []
  );
  const handleNameSearch = useCallback(
    debounce((value) => {
      if (value.length >= 3) {
        dispatch(appActions.getUsers({ search: value }));
      }
    }, 300),
    []
  );

  return (
    <div className="add-app-container">
      <div className="app-back-button" onClick={goBack}>
        <ArrowLeftOutlined /> <span className="back-text">Go to home page</span>
      </div>
      <div className="form-header">
        {dashboardToEdit ? 'Edit Sarovar' : 'Fill out this form to add Sarovar'}
      </div>
      {dashboardToEdit && (
        <div className="manage-button">
          <>
            <Button
              onClick={handleAD_details} //{showAdGroupDetails}
              round
              // type="primary"
              className="card-buttons px-3"
              style={{ marginLeft: 24 }}
            >
              AD Details
            </Button>
            <Button
              onClick={manageUsers}
              round
              // type="primary"
              className="card-buttons px-3"
              style={{ marginLeft: 24 }}
            >
              Manage Users
            </Button>
            <Popconfirm
              title="Are you sure you want to deactivate this Module?"
              description=""
              okText="Deactivate"
              cancelText="No"
              onConfirm={() => {
                deactiveApp();
              }}
            >
              <Button
                round
                className="card-buttons px-3"
                style={{ marginLeft: 24 }}
              >
                Deactivate Module
              </Button>
            </Popconfirm>
          </>
        </div>
      )}

      <div>
        <Form
          name="basic"
          // labelCol={{ span: 12 }}
          // wrapperCol={{ span: 12 }}
          initialValues={{ remember: true, reports: [''] }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="add-app-form"
          layout="vertical"
          form={appForm}
        >
          <Form.Item
            label="Module Name"
            name="name"
            rules={[
              { required: true, message: 'Please input your module name!' },
            ]}
          >
            <Input placeholder="Enter Module Name" />
          </Form.Item>
          <Form.Item
            label="Module URL"
            name="appUrl"
            rules={[{ required: true, message: 'Please input module url!' }]}
          >
            <Input placeholder="www.appdomain.com" />
          </Form.Item>
          <Form.Item
            label="Staging URL"
            name="staging_url"
            rules={[{ required: true, message: 'Please input staging url!' }]}
          >
            <Input placeholder="www.appdomain.com" />
          </Form.Item>
          <Form.Item
            label="Video URL"
            name="videoUrl"
            rules={[{ required: true, message: 'Please input video url!' }]}
          >
            <Input placeholder="Enter video link" />
          </Form.Item>
          <Form.Item
            label="Module Description"
            name="description"
            rules={[
              {
                required: true,
                message: 'Please enter module description!',
              },
            ]}
          >
            <TextArea
              // onKeyUp={(e) => checkLimits(e, 'description_limit')}
              maxLength={1000}
              placeholder="Enter Module Description"
            />
          </Form.Item>
          <Form.Item
            name="quickLook"
            label="Quick Look Document"
            // rules={[
            //   { required: true, message: 'Please upload profile image!' },
            // ]}
          >
            <Upload
              onChange={(files) => {
                setQuicklook(files.fileList);
              }}
              fileList={quickLook}
              beforeUpload={(file) => {
                return false;
              }}
              showUploadList
              // accept="image/*"
              maxCount={1}
            >
              <Button htmlType="button" type="button" icon={<UploadOutlined />}>
                Upload Quick Look
              </Button>
            </Upload>
            {dashboardToEdit && (
              <div className="previous-files mt-2">
                {previousQuickLook
                  ?.filter((itm) => !itm.deleted)
                  ?.map((man, idx) => (
                    <div className="d-flex w-50 mt-1">
                      <span style={{ fontSize: '12px' }}>{man?.file_name}</span>
                      <span className="ml-auto pointer">
                        <DeleteOutlined
                          onClick={() => deleteQuickLook(idx)}
                          style={{ color: 'red' }}
                        />
                      </span>
                    </div>
                  ))}
              </div>
            )}
          </Form.Item>
          <Form.Item
            name="profileImage"
            label="Profile Image"
            // rules={[
            //   { required: true, message: 'Please upload profile image!' },
            // ]}
          >
            <Upload
              onChange={(files) => {
                setProfileImage(files.fileList);
              }}
              fileList={profileImage}
              beforeUpload={(file) => {
                return false;
              }}
              showUploadList
              accept="image/*"
              maxCount={1}
            >
              <Button htmlType="button" type="button" icon={<UploadOutlined />}>
                Upload Profile Image
              </Button>
            </Upload>
            {dashboardToEdit && (
              <div className="previous-files mt-2">
                {previousProfileImage
                  ?.filter((itm) => !itm.deleted)
                  ?.map((man, idx) => (
                    <div className="d-flex w-50 mt-1">
                      <span style={{ fontSize: '12px' }}>{man?.file_name}</span>
                      <span className="ml-auto pointer">
                        <DeleteOutlined
                          onClick={() => deleteProfileImage(idx)}
                          style={{ color: 'red' }}
                        />
                      </span>
                    </div>
                  ))}
              </div>
            )}
          </Form.Item>
          <Form.Item
            name="featureImage"
            label="Feature Image"
            // rules={[
            //   { required: true, message: 'Please upload feature image!' },
            // ]}
          >
            <Upload
              onChange={(files) => {
                setFeatureImage(files.fileList);
              }}
              fileList={featureImage}
              beforeUpload={(file) => {
                return false;
              }}
              showUploadList
              accept="image/*"
              maxCount={1}
            >
              <Button htmlType="button" type="button" icon={<UploadOutlined />}>
                Upload Feature Image
              </Button>
            </Upload>
            {dashboardToEdit && (
              <div className="previous-files mt-2">
                {previousFeatureImage
                  ?.filter((itm) => !itm.deleted)
                  ?.map((man, idx) => (
                    <div className="d-flex w-50">
                      <span>{man?.file_name}</span>
                      <span className="ml-auto pointer">
                        <DeleteOutlined
                          onClick={() => deleteFeatureImage(idx)}
                          style={{ color: 'red' }}
                        />
                      </span>
                    </div>
                  ))}
              </div>
            )}
          </Form.Item>
          <Form.Item
            label="Last Updated"
            name="lastUpdated"
            rules={[
              { required: true, message: 'Please select refersh interval!' },
            ]}
          >
            <DatePicker format="YYYY/MM/DD" />
          </Form.Item>
          <Form.Item
            label="Select Refresh Cycle "
            name="refreshCycle"
            rules={[
              { required: true, message: 'Please select refersh interval!' },
            ]}
          >
            <Select placeholder="Select Refresh Cycle">
              {refreshOptions.map((itm) => (
                <Option key={itm.id} value={itm.id}>
                  {toTitleCase(itm.cycle_name)}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Select Group"
            name="dashboard_group"
            rules={[{ required: true, message: 'Please select group!' }]}
          >
            <Select placeholder="Select Group">
              {groups?.map((itm) => (
                <Option key={itm.id} value={itm.function_name}>
                  {itm.function_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {/*   <Form.Item
            label="Select Category"
            name="category"
            rules={[{ required: true, message: 'Please select category!' }]}
          >
            <Select placeholder="Select Category">
              {catOptions.map((itm) => (
                <Option key={itm.id} value={itm.id}>
                  {itm.function_name}
                </Option>
              ))}
            </Select>
          </Form.Item> */}

          {dashboardToEdit ? (
            <div>
              {options
                ?.filter(
                  (option) =>
                    option?.is_active ||
                    option?.is_active === null ||
                    option?.is_active === undefined
                ) // Filter out inactive items
                ?.map((opt, optionIndex) => (
                  <>
                    <Row
                      key={opt?.newIndex}
                      gutter={8}
                      style={{
                        width: '60%',
                        margin: 'auto',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Col span={7}>
                        <Form.Item
                          label={
                            <span>
                              <span
                                style={{
                                  color: '#ff4d4f',
                                  fontSize: '20px',
                                  marginRight: '2px',
                                }}
                              >
                                *
                              </span>
                              Report Name
                            </span>
                          } //"Report Name"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your Report Name!',
                            },
                          ]}
                          className="report-input"
                        >
                          <Input
                            placeholder="Enter Report Name"
                            style={{ width: '100% !important', margin: 'auto' }}
                            value={opt?.report_name}
                            onChange={(e) => {
                              {
                                console.log('optionIndex::', opt?.newIndex);
                              }
                              handleOptionChange(
                                opt?.newIndex,
                                'report_name',
                                e.target.value
                              );
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item
                          label={
                            <span>
                              <span
                                style={{
                                  color: '#ff4d4f',
                                  fontSize: '20px',
                                  marginRight: '2px',
                                }}
                              >
                                *
                              </span>
                              URL
                            </span>
                          } //"URL"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your Report URL!',
                            },
                          ]}
                          className="report-input"
                        >
                          <Input
                            placeholder="www.appdomain.com"
                            value={opt?.report_url}
                            onChange={(e) =>
                              handleOptionChange(
                                opt?.newIndex,
                                'report_url',
                                e.target.value
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label={
                            <span>
                              <span
                                style={{
                                  color: '#ff4d4f',
                                  fontSize: '20px',
                                  marginRight: '2px',
                                }}
                              >
                                *
                              </span>
                              Report Description
                            </span>
                          } //"Report Description"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter Report Description!',
                            },
                          ]}
                          className="report-input"
                        >
                          <TextArea
                            maxLength={1000}
                            placeholder="Enter Report Description"
                            value={opt?.description}
                            onChange={(e) =>
                              handleOptionChange(
                                opt?.newIndex,
                                'description',
                                e.target.value
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        {opt?.newIndex !== 0 ? (
                          <DeleteOutlined
                            style={{ color: 'red', marginLeft: '5px' }}
                            onClick={() => handleDeleteOption(opt?.newIndex)}
                          />
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                  </>
                ))}

              <Form.Item>
                <Button onClick={handleAddOption} icon={<PlusOutlined />}>
                  Add Report
                </Button>
              </Form.Item>
            </div>
          ) : (
            <Form.List name="reports">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <>
                      <Row
                        key={key}
                        gutter={16}
                        style={{
                          width: '60%',
                          margin: 'auto',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          paddingleft: '0px !important',
                          paddingRight: '0px !important',
                        }}
                      >
                        <Col span={7} className="col-padding-reports">
                          <Form.Item
                            label="Report Name"
                            {...restField}
                            name={[name, 'report_name']}
                            rules={[
                              {
                                required: true,
                                message: 'Please input your Report Name!',
                              },
                            ]}
                            className="report-input"
                          >
                            <Input
                              placeholder="Enter Report Name"
                              style={{
                                width: '100% !important',
                                margin: 'auto',
                              }}
                              onChange={(e) =>
                                handleChangeReport(
                                  e.target.value,
                                  'report_name',
                                  e.target.value
                                )
                              }
                            />
                          </Form.Item>
                        </Col>

                        <Col span={8} className="col-padding-reports">
                          <Form.Item
                            label="URL"
                            {...restField}
                            name={[name, 'report_url']}
                            rules={[
                              {
                                required: true,
                                message: 'Please input your Report URL!',
                              },
                            ]}
                            className="report-input"
                          >
                            <Input
                              //placeholder="Enter Report URL"
                              placeholder="www.appdomain.com"
                              onChange={(e) =>
                                handleChangeReport(
                                  e.target.value,
                                  'report_url',
                                  e.target.value
                                )
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8} className="col-padding-reports">
                          <Form.Item
                            label="Report Description"
                            {...restField}
                            name={[name, 'description']}
                            rules={[
                              {
                                required: true,
                                message: 'Please enter Report Description!',
                              },
                            ]}
                            className="report-input"
                          >
                            <TextArea
                              className="report-textArea"
                              maxLength={1000}
                              placeholder="Enter Report Description"
                              onChange={(e) =>
                                handleChangeReport(
                                  e.target.value,
                                  'description',
                                  e.target.value
                                )
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col span={1} className="col-padding-reports">
                          {fields.length > 1 ? (
                            <div className="report-delete">
                              <DeleteOutlined onClick={() => remove(name)} />
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                    </>
                  ))}
                  <Form.Item>
                    <Button
                      onClick={() => add()}
                      icon={
                        <PlusOutlined
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        />
                      }
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      Add Report
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          )}

          <Form.Item name="accessType" label="Access Type">
            <Radio.Group defaultValue={'open'}>
              <Radio value={'open'}>Open</Radio>
              <Radio value={'restricted'}>Restricted</Radio>
              <Radio value={'upcoming'}>Upcoming</Radio>
            </Radio.Group>
          </Form.Item>
          {/*  <Form.Item
            name="access_levels_enabled"
            label="Do you want to enable access level configuration on this module?"
            rules={[
              { required: false, message: 'Please select access level!' },
            ]}
          >
            <Radio.Group disabled defaultValue={false}>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item> */}
          <Form.Item name="platform" label="Platform">
            <Radio.Group defaultValue={'web'}>
              <Radio value={'web'}>Web</Radio>
              <Radio value={'mobile'}>Mobile</Radio>
              <Radio value={'both'}>Both</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="How to Use"
            name="howToUse"
            // rules={[{ required: true, message: 'Please input app url!' }]}
          >
            <TextArea
              // onKeyUp={(e) => checkLimits(e, 'htu_limit')}
              maxLength={1000}
              placeholder="Enter instructions on how to use the Module"
            />
          </Form.Item>
          <Form.Item name="userManual" label="User Manual">
            <Upload
              onChange={handleUserManual}
              fileList={userManual}
              beforeUpload={(file) => {
                return false;
              }}
              showUploadList
              maxCount={1}
            >
              <Button htmlType="button" type="button" icon={<UploadOutlined />}>
                Upload Manual
              </Button>
            </Upload>
            {dashboardToEdit && (
              <div className="previous-files mt-2">
                {previousUserManual
                  ?.filter((itm) => !itm.deleted)
                  ?.map((man, idx) => (
                    <div className="d-flex w-50 mt-1">
                      <span style={{ fontSize: '12px' }}>{man?.file_name}</span>
                      <span className="ml-auto pointer">
                        <DeleteOutlined
                          onClick={() => deleteUserManual(idx)}
                          style={{ color: 'red' }}
                        />
                      </span>
                    </div>
                  ))}
              </div>
            )}
          </Form.Item>
          <Form.Item
            label="Benefits"
            name="benefits"
            // rules={[{ required: true, message: 'Please input app url!' }]}
          >
            <TextArea
              // onKeyUp={(e) => checkLimits(e, 'benefits_limit')}
              maxLength={1000}
              placeholder="Enter the benefits of the module"
            />
          </Form.Item>
          <Form.Item
            label="Contact Email"
            name="sme_contact"
            rules={[{ required: true, message: 'Please input contact email!' }]}
          >
            <Input placeholder="Enter the email address to contact for support" />
          </Form.Item>
          <Form.Item name="appImages" label="Module Gallery">
            <Upload
              multiple
              onChange={handleFileChange}
              fileList={imageFiles}
              beforeUpload={(file) => {
                return false;
              }}
              showUploadList
              accept="image/*"
              maxCount={
                dashboardToEdit && previousImageFiles.length > 0
                  ? previousImageFiles.filter((itm) => !itm.deleted).length >= 5
                    ? 0
                    : 5 -
                      previousImageFiles.filter((itm) => !itm.deleted).length
                  : 5
              }
            >
              <Button htmlType="button" type="button" icon={<UploadOutlined />}>
                Upload Images
              </Button>
            </Upload>
            {/* <Input multiple type="file" onChange={imageFileChange} /> */}
            {dashboardToEdit && (
              <div className="previous-files mt-2">
                {/* <Typography.Title level={5}>Previous App Images</Typography.Title> */}
                {previousImageFiles
                  // ?.filter((itm) => !itm.deleted)
                  ?.map(
                    (man, idx) =>
                      !man.deleted && (
                        <div className="d-flex w-50 mt-1">
                          {/* // id, doc_url */}
                          <span style={{ fontSize: '12px' }}>
                            {man?.file_name}
                          </span>
                          <span className="ml-auto pointer">
                            <DeleteOutlined
                              onClick={() => deleteAppImage(idx)}
                              style={{ color: 'red' }}
                            />
                          </span>
                        </div>
                      )
                  )}
              </div>
            )}
          </Form.Item>

          {!dashboardToEdit && (
            <>
              <Form.Item
                label="Owners"
                name="appOwners"
                rules={[
                  { required: true, message: 'Please select module owner!' },
                ]}
              >
                <Select
                  placeholder="Select Owners"
                  mode="multiple"
                  showSearch
                  optionFilterProp="children"
                  onSearch={handleOwnersNameSearch}
                  // filterOption={(input, option) =>
                  //   (option?.label ?? '')
                  //     .toLowerCase()
                  //     .includes(input.toLowerCase())
                  // }
                  // options={
                  //   appUsers?.data?.map((usr) => ({
                  //     label: `${usr.first_name || ''} ${usr.last_name || ''}`,
                  //     value: usr.id,
                  //   })) || []
                  // }
                >
                  {appUsers?.data?.map((usr) => (
                    <Option key={usr?.id} value={usr?.id}>{`${
                      usr?.first_name
                    } ${usr.last_name} (${usr?.email || ''})`}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Give Permission to"
                name="appPermissions"
                // rules={[{ required: true, message: 'Please input app url!' }]}
              >
                <Select
                  mode="multiple"
                  placeholder="Select Users who has access to this module"
                  showSearch
                  optionFilterProp="children"
                  onSearch={handleNameSearch}
                  // filterOption={(input, option) =>
                  //   (option?.label ?? '')
                  //     .toLowerCase()
                  //     .includes(input.toLowerCase())
                  // }
                  /*  options={
                      appUsers?.data?.map((usr) => ({
                        label: `${usr.first_name || ''} ${usr.last_name || ''}`,
                        value: usr.id,
                      })) || []
                    } */
                >
                  {appUsers?.data?.map((usr) => (
                    <Option key={usr?.id} value={usr?.id}>{`${
                      usr?.first_name
                    } ${usr.last_name} (${usr?.email || ''})`}</Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}

          <Form.Item className="text-right">
            <Button
              type="outlined"
              className="cancel-btn"
              disabled={addAppLoading}
              onClick={goBack}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              className="submit-btn ml-2"
              disabled={addAppLoading}
            >
              {dashboardToEdit ? 'Update Sarovar' : 'Add Sarovar'}
            </Button>
          </Form.Item>
        </Form>
      </div>
      {showAdDetails && (
        <Modal
          visible={showAdDetails.visible}
          title="AD Group Details"
          onCancel={() => setShowAdDetails({})}
          onOk={() => setShowAdDetails({})}
        >
          <>
            <div>
              <span className="fw-bold">AD Group for Owners:</span>
              <span className="ml-2">
                {
                  currentDashboard.data?.dash_groups?.find(
                    (grp) => grp.group_type === 'owner'
                  )?.group_name
                }
              </span>
            </div>
            <div>
              <span className="fw-bold">AD Group for Users:</span>
              <span className="ml-2">
                {
                  currentDashboard.data?.dash_groups?.find(
                    (grp) => grp.group_type === 'user'
                  )?.group_name
                }
              </span>
            </div>
          </>
        </Modal>
      )}
    </div>
  );
};

export default AddSarovar;
