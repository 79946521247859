import React, { useEffect } from 'react';
import { Button, DatePicker, Form, Input, message } from 'antd';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import './add-ann.scss';
import TextArea from 'antd/lib/input/TextArea';
import appActions from '../../../redux/actions/actions';

const AddAnnouncement = ({ isEdit }) => {
  const [annForm] = Form.useForm();
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const location = useLocation();

  const announcementToEdit = location.state?.announcement;

  useEffect(() => {
    if (announcementToEdit) {
      populateFields();
    }
  }, [announcementToEdit]);

  const populateFields = () => {
    const { title, description, anouncement_date, link } = announcementToEdit;
    annForm.setFieldsValue({
      title,
      description,
      announcementDate: moment(anouncement_date),
      url: link,
    });
  };

  const onFinish = () => {
    const { title, description, url, announcementDate } =
      annForm.getFieldsValue();
    console.log('on submit', annForm.getFieldsValue());

    const payload = {
      anouncement_date: moment(announcementDate).format('YYYY-MM-DD'),
      title,
      description,
      link: url,
    };

    if (announcementToEdit) {
      payload.id = announcementToEdit.id;
    }

    let apiUrl = 'anouncement/create_anouncement';
    if (announcementToEdit) {
      apiUrl += `?id=${announcementToEdit.id}`;
    }

    appActions
      .makeApiCall('post', apiUrl, payload)
      .then((res) => {
        console.log('resp ', res);
        if (res.data.success) {
          message.success(res.data.msg);
          if (announcementToEdit) {
            navigate('/announcements');
          } else {
            navigate('/welcome');
          }
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((err) => {
        console.log('err', err);
        message.error(err.response.data.message);
      });
  };

  return (
    <div className="add-ann-container">
      <div className="app-back-button" onClick={goBack}>
        <ArrowLeftOutlined /> <span className="back-text">Go to home page</span>
      </div>
      <div className="form-header">Fill out this form to add announcement</div>
      <Form
        form={annForm}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
        className="add-app-form"
        layout="vertical"
      >
        <Form.Item
          name="title"
          label="Title"
          rules={[
            { required: true, message: 'Please enter announcement title!' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: 'Please enter description!' }]}
        >
          <TextArea />
        </Form.Item>
        <Form.Item
          name="url"
          label="URL"
          rules={[
            { required: true, message: 'Please enter announcement link!' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="announcementDate"
          label="Announcement Date"
          rules={[
            { required: true, message: 'Please enter announcement date!' },
          ]}
        >
          <DatePicker format="YYYY/MM/DD" />
        </Form.Item>
        <Form.Item className="text-right">
          <Button type="outlined" className="cancel-btn" onClick={goBack}>
            Cancel
          </Button>
          <Button htmlType="submit" type="primary" className="submit-btn ml-2">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddAnnouncement;
