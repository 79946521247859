import logo from './logo.svg';
import './App.css';
import { connect, useSelector } from 'react-redux';

import {
  BrowserRouter,
  Route,
  Navigate,
  Routes,
  useLocation,
} from 'react-router-dom';
import HomePage from './pages/HomePage';
import LoginPage from './pages/Login';
import appservice from './services/appservice';
import AddApp from './pages/AddApp';
import RequestAccess from './pages/RequestAccess';
import RequestsList from './pages/RequestsList';
import AppDetail from './pages/AppDetail';
import AppHeader from './components/AppHeader';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import DashboardDetail from './pages/AppDetail/dashboardDetails';
import AddDashboard from './pages/AddApp/addDashboard';
import ManageAppUsers from './pages/AddApp/manageUsers';
import FileViewer from './pages/FileViewer';
import WelcomePage from './pages/Welcome';
import AddAnnouncement from './pages/Announcements/AddAnnouncement';
import Announcements from './pages/Announcements';
import { useEffect } from 'react';
import PdfViewUserManual from './pages/AppDetail/pdfViewUserManual';
import PdfViewUserManual_dashboard from './pages/AppDetail/pdfViewUserManual_dashboard';
import AD_Details from './pages/AddApp/adDetails';
import AD_group_view from './pages/AddApp/ad_group_view';
import PublishReleaseNotes from './pages/AddApp/PublishReleaseNotes';
import SarovarDetail from './pages/AppDetail/sarovarDetail';
import AddSarovar from './pages/AddApp/addSarovar';

const configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: process.env.REACT_APP_AUTHORITY,
    redirectUri: process.env.REACT_APP_REDIRECT_URL,
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    windowHashTimeout: 60000,
    iframeHashTimeout: 60000,
    loadFrameTimeout: 0,
    asyncPopups: false,
  },
};
const pca = new PublicClientApplication(configuration);

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={() =>
        appservice.checkLoggedIn() ? children : <Navigate to="/login" replace />
      }
    />
  );
}

function LoginPrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={() =>
        appservice.checkLoggedIn() ? <Navigate to="/home" /> : children
      }
    />
  );
}

function App(props) {
  // const [initialized, setInitialized] = useState(false);

  // const location = useLocation();
  const showBackground = useSelector((state) => {
    return state.app.showBackground;
  });

  const showHeader = useSelector((state) => {
    return state.app.showHeader;
  });

  // useEffect(() => {
  //   setInitialized(true);
  // }, []);

  // useEffect(() => {
  //   if (initialized) {
  //     console.log('being called');
  //     // ReactGA.pageview(location.pathname + location.search);
  //     window.dataLayer.push({
  //       event: 'pageview',
  //     });
  //   }
  // }, [initialized, location]);

  const getEmptyArray = (mynumber) => {
    var arr = [];
    for (var i = 1; i <= mynumber; i++) arr.push('' + i);
    return arr;
  };
  const renderDots = (rows, columns) => {
    return (
      <span>
        {getEmptyArray(rows).map((i) => {
          return (
            <div className="d-flex" style={{ marginTop: 12 }}>
              {getEmptyArray(columns).map((j, idx) => (
                <span
                  key={idx}
                  className="dots"
                  style={{ marginLeft: 8 * idx }}
                ></span>
              ))}
            </div>
          );
        })}
      </span>
    );
  };

  const goToHome = () => {
    // navigate('');
    // window.location.href = '';
  };

  const PrivateRoute = ({ children, title }) => {
    // let location = useLocation();

    useEffect(() => {
      if (token && title) {
        document.title = title;
      }
    }, [title]);

    const token = localStorage.getItem('access_token');
    if (!token) {
      return <Navigate to="/login" />;
    }

    return children;
  };

  return (
    <div>
      <div className="main-container">
        <BrowserRouter>
          <MsalProvider instance={pca}>
            {showHeader && (
              <div className="">
                <AppHeader goToHome={goToHome} />
              </div>
            )}
          </MsalProvider>
          <div className="app-content" style={{ flex: 1 }}>
            <Routes>
              <Route
                path="/welcome"
                element={
                  <PrivateRoute title="DX Store - Home">
                    <MsalProvider instance={pca}>
                      <WelcomePage />
                    </MsalProvider>
                  </PrivateRoute>
                }
              />
              <Route
                path="/home"
                element={
                  <PrivateRoute title="DX Store - Apps">
                    <MsalProvider instance={pca}>
                      <HomePage />
                    </MsalProvider>
                  </PrivateRoute>
                }
              />
              <Route
                path="/dashboards"
                element={
                  <PrivateRoute title="DX Store - Analytics & Insights">
                    <MsalProvider instance={pca}>
                      <HomePage dashboard />
                    </MsalProvider>
                  </PrivateRoute>
                }
              />
              <Route
                path="/login"
                element={
                  <MsalProvider instance={pca}>
                    <LoginPage title="DX Store - Login" />
                  </MsalProvider>
                }
              />
              <Route
                path="/add-app"
                element={
                  <PrivateRoute title="Manage App">
                    <MsalProvider instance={pca}>
                      <AddApp />
                    </MsalProvider>
                  </PrivateRoute>
                }
              />
              <Route
                path="/add-dashboard"
                element={
                  <PrivateRoute title="Manage Dashboard">
                    <MsalProvider instance={pca}>
                      <AddDashboard />
                    </MsalProvider>
                  </PrivateRoute>
                }
              />
              <Route
                path="/request-access/:appId/:appName"
                element={
                  <PrivateRoute title="DX Store - Request Access">
                    <MsalProvider instance={pca}>
                      <RequestAccess />
                    </MsalProvider>
                  </PrivateRoute>
                }
              />
              <Route
                path="/request-list"
                element={
                  <PrivateRoute title="DX Store - App Requests">
                    <MsalProvider instance={pca}>
                      <RequestsList />
                    </MsalProvider>
                  </PrivateRoute>
                }
              />
              <Route
                path="dashboard/request-list"
                element={
                  <PrivateRoute title="DX Store - Analytics Requests">
                    <MsalProvider instance={pca}>
                      <RequestsList dashboard />
                    </MsalProvider>
                  </PrivateRoute>
                }
              />
              <Route
                path="/app/:appId"
                element={
                  <PrivateRoute>
                    <MsalProvider instance={pca}>
                      <AppDetail />
                    </MsalProvider>
                  </PrivateRoute>
                }
              />
              <Route
                path="/dashboard/:appId"
                element={
                  <PrivateRoute>
                    <MsalProvider instance={pca}>
                      <DashboardDetail />
                    </MsalProvider>
                  </PrivateRoute>
                }
              />
              <Route
                path="/app/manage-users/:appId"
                element={
                  <PrivateRoute title="DX Store - Manage Users">
                    <MsalProvider instance={pca}>
                      <ManageAppUsers />
                    </MsalProvider>
                  </PrivateRoute>
                }
              />
              <Route
                path="/dashboard/manage-users/:appId"
                element={
                  <PrivateRoute title="DX Store - Manage Users">
                    <MsalProvider instance={pca}>
                      <ManageAppUsers dashboards />
                    </MsalProvider>
                  </PrivateRoute>
                }
              />
              <Route
                path="/sarovar/manage-users/:appId"
                element={
                  <PrivateRoute title="DX Store - Manage Users">
                    <MsalProvider instance={pca}>
                      <ManageAppUsers dashboards={'sarovar'} />
                    </MsalProvider>
                  </PrivateRoute>
                }
              />
              <Route
                path="/announcements"
                element={
                  <PrivateRoute title="DX Store - Manage Announcements">
                    <MsalProvider instance={pca}>
                      <Announcements />
                    </MsalProvider>
                  </PrivateRoute>
                }
              />
              <Route
                path="/add-announcement"
                element={
                  <PrivateRoute title="DX Store - Add Announcement">
                    <MsalProvider instance={pca}>
                      <AddAnnouncement />
                    </MsalProvider>
                  </PrivateRoute>
                }
              />
              <Route
                path="/edit-announcement"
                element={
                  <PrivateRoute title="DX Store - Edit Announcement">
                    <MsalProvider instance={pca}>
                      <AddAnnouncement isEdit />
                    </MsalProvider>
                  </PrivateRoute>
                }
              />
              <Route
                path="/view-file"
                element={
                  <PrivateRoute title="DX Store - Quick View">
                    <MsalProvider instance={pca}>
                      <FileViewer />
                    </MsalProvider>
                  </PrivateRoute>
                }
              />
              <Route
                path="/view-pdf/:appId"
                element={
                  <PrivateRoute title="DX Store - PDF View">
                    <MsalProvider instance={pca}>
                      <PdfViewUserManual />
                    </MsalProvider>
                  </PrivateRoute>
                }
              />
              <Route
                path="/view-pdf-dashboard/:appId"
                element={
                  <PrivateRoute title="DX Store - PDF View Dashboard">
                    <MsalProvider instance={pca}>
                      <PdfViewUserManual_dashboard />
                    </MsalProvider>
                  </PrivateRoute>
                }
              />
              <Route
                path="/app/ad-details/:appId"
                element={
                  <PrivateRoute title="DX Store - AD Details">
                    <MsalProvider instance={pca}>
                      <AD_Details />
                    </MsalProvider>
                  </PrivateRoute>
                }
              />
              <Route
                path="/dashboard/ad-details/:appId"
                element={
                  <PrivateRoute title="DX Store - AD Details">
                    <MsalProvider instance={pca}>
                      <AD_Details dashboards />
                    </MsalProvider>
                  </PrivateRoute>
                }
              />
              <Route
                path="/sarovar/ad-details/:appId"
                element={
                  <PrivateRoute title="DX Store - AD Details">
                    <MsalProvider instance={pca}>
                      <AD_Details dashboards />
                    </MsalProvider>
                  </PrivateRoute>
                }
              />
              <Route
                path="/app/ad-details/ad-group-view/:appId"
                element={
                  <PrivateRoute title="DX Store - AD Details">
                    <MsalProvider instance={pca}>
                      <AD_group_view />
                    </MsalProvider>
                  </PrivateRoute>
                }
              />
              <Route
                path="/dashboard/ad-details/ad-group-view/:appId"
                element={
                  <PrivateRoute title="DX Store - AD Details">
                    <MsalProvider instance={pca}>
                      <AD_group_view />
                    </MsalProvider>
                  </PrivateRoute>
                }
              />
              <Route
                path="/sarovar/ad-details/ad-group-view/:appId"
                element={
                  <PrivateRoute title="DX Store - AD Details">
                    <MsalProvider instance={pca}>
                      <AD_group_view />
                    </MsalProvider>
                  </PrivateRoute>
                }
              />
              <Route
                path="/app/publish-release-notes/:appId"
                element={
                  <PrivateRoute title="DX Store - Publish Release Notes">
                    <MsalProvider instance={pca}>
                      <PublishReleaseNotes />
                    </MsalProvider>
                  </PrivateRoute>
                }
              />
              <Route
                path="/dashboard/publish-release-notes/:appId"
                element={
                  <PrivateRoute title="DX Store - Publish Release Notes">
                    <MsalProvider instance={pca}>
                      <PublishReleaseNotes dashboards />
                    </MsalProvider>
                  </PrivateRoute>
                }
              />
              <Route
                path="/sarovar"
                element={
                  <PrivateRoute title="DX Store - Sarovar">
                    <MsalProvider instance={pca}>
                      <HomePage sarovar />
                    </MsalProvider>
                  </PrivateRoute>
                }
              />
              <Route
                path="/add-sarovar"
                element={
                  <PrivateRoute title="Manage Dashboard">
                    <MsalProvider instance={pca}>
                      <AddSarovar />
                    </MsalProvider>
                  </PrivateRoute>
                }
              />
              <Route
                path="/sarovar/:appId"
                element={
                  <PrivateRoute>
                    <MsalProvider instance={pca}>
                      <SarovarDetail />
                    </MsalProvider>
                  </PrivateRoute>
                }
              />
              <Route path="*" element={<Navigate to="/welcome" replace />} />
            </Routes>
          </div>
        </BrowserRouter>
      </div>
      {showBackground && (
        <div className="background-shapes">
          <span key="1" className="left half-circle"></span>
          <span key="2" className="left square1"></span>
          <span key="3" className="left square2"></span>
          <span key="4" className="right circle1"></span>
          <span key="5" className="right circle2"></span>
          <span key="6" className="left dots-container">
            {renderDots(4, 2)}
          </span>
          <span key="7" className="right dots-container">
            {renderDots(9, 2)}
          </span>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
