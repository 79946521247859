import { message } from 'antd';
import {
  FETCH_APPS_START,
  FETCH_APPS_FINISHED,
  FETCH_MY_REQUESTS_START,
  FETCH_MY_REQUESTS_FINISHED,
  FETCH_USER_REQUESTS_START,
  FETCH_USER_REQUESTS_FINISHED,
  ADD_APPLICATION_START,
  ADD_APPLICATION_FINISHED,
  GET_CATEGORIES_START,
  GET_CATEGORIES_FINISHED,
  GET_CAT_OPTIONS_START,
  GET_CAT_OPTIONS_FINISHED,
  UPDATE_REQUEST_STATUS_START,
  UPDATE_REQUEST_STATUS_FINISHED,
  GET_APP_BY_ID_FINISHED,
  GET_APP_BY_ID_START,
  REQUEST_ACCESS_START,
  REQUEST_ACCESS_FINISHED,
  CREATE_NEW_AD_GROUP_START,
  CREATE_NEW_AD_GROUP_FINISHED,
  AD_APP_GROUP_ADD_MEMBERS_STATUS_START,
  AD_APP_GROUP_ADD_MEMBERS_STATUS_FINISHED,
  DEACTIVATE_APP_AD_GROUP_START,
  DEACTIVATE_APP_AD_GROUP_FINISHED,
  SIGNIN_FINISHED,
  SIGNIN_START,
  GET_MY_REQUEST_STATUSES_START,
  GET_MY_REQUEST_STATUSES_FINISHED,
  GET_USER_REQUEST_STATUSES_START,
  GET_USER_REQUEST_STATUSES_FINISHED,
  GET_USERS_START,
  GET_USERS_FINISHED,
  GET_APP_USERS_START,
  GET_APP_USERS_FINISHED,
  GET_CUSTOM_APP_AD_GROUP_START,
  GET_CUSTOM_APP_AD_GROUP_FINISHED,
  GET_APP_OWNERS_START,
  GET_APP_OWNERS_FINISHED,
  GET_SBG_USERS_START,
  GET_SBG_USERS_FINISHED,
  GET_SBU_USERS_START,
  GET_SBU_USERS_FINISHED,
  GET_BU_USERS_START,
  GET_BU_USERS_FINISHED,
  GET_PROJECT_USERS_START,
  GET_PROJECT_USERS_FINISHED,
  DEACTIVATE_APP_AD_MEMBERS_START,
  DEACTIVATE_APP_AD_MEMBERS_FINISHED,
  GET_CUSTOM_APP_AD_MEMBERS_START,
  GET_CUSTOM_APP_AD_MEMBERS_FINISHED,
  GET_UPDATES_LIST_START,
  GET_UPDATES_LIST_FINISHED,
  GET_UPDATES_START,
  GET_UPDATES_FINISHED,
  VIEW_ACCESS_LEVELS_START,
  VIEW_ACCESS_LEVELS_FINISHED,
  CREATE_EDIT_RELEASE_NOTE_START,
  CREATE_EDIT_RELEASE_NOTE_FINISHED,
  IMPORT_BULK_APP_START,
  IMPORT_BULK_APP_FINISHED,
  GET_APP_AD_GROUP_START,
  GET_APP_AD_GROUP_FINISHED,
} from '../constants/types';

import axiosInstance from '../../helpers/axios';
import axios from 'axios';
import { API_BASE_URL } from '../../constants/appConstants';
import { requestsData } from './test_data';

// const getAppsList =
//   (payload = {}) =>
//   (dispatch) => {
//     const { category, appSearch } = payload;
//     dispatch({ type: FETCH_APPS_START });
//     let url = '/admin/all-apps';
//     if (category) {
//       url += '?search=' + category;
//     } else if (appSearch) {
//       url += '?app_search=' + appSearch;
//     }
//     axiosInstance
//       .get(url, {
//         withCredentials: false,
//       })
//       .then(
//         (res) => {
//           dispatch({
//             type: FETCH_APPS_FINISHED,
//             appslist: res?.data?.data,
//           });
//         },
//         (err) => {
//           console.log('Error', err);
//         }
//       );
//   };

const DEV_URL = process.env.REACT_APP_DEV_URL;

const getPermittedAppsList =
  (payload = {}) =>
  (dispatch) => {
    const { category, appSearch } = payload;
    dispatch({ type: FETCH_APPS_START });
    let url = '/admin/permitted';
    if (category) {
      url += '?function_search=' + category;
    } else if (appSearch) {
      url += '?app_search=' + appSearch;
    }
    axiosInstance
      .get(url, {
        withCredentials: false,
      })
      .then(
        (res) => {
          dispatch({
            type: FETCH_APPS_FINISHED,
            appslist: res?.data?.permitted_apps,
          });
        },
        (err) => {
          console.log('Error', err);
        }
      );
  };

const getUpdatesList = (appId, payload) => (dispatch) => {
  // console.log('Payload_Action:', payload);
  // console.log('appId_Action:', appId);

  dispatch({ type: GET_UPDATES_LIST_START });
  let url = `user/getUpdatesList?type=app&app_id=${appId?.app_id}&page=${appId?.page}&per_page=${appId?.per_page}`;
  if (appId?.search) {
    url += `&search=${appId?.search}`;
  }
  axiosInstance.get(url).then(
    (res) => {
      dispatch({ type: GET_UPDATES_LIST_FINISHED, data: res.data });
    },
    (err) => {
      dispatch({ type: GET_UPDATES_LIST_FINISHED, data: {} });
      console.log('err', err);
    }
  );
};

const getUpdatesCounts = (appPayload) => (dispatch) => {
  dispatch({ type: GET_UPDATES_START });
  axiosInstance.get('user/getUpdatesCount', {}).then(
    (res) => {
      dispatch({ type: GET_UPDATES_FINISHED, data: res.data?.data || {} });
    },
    (err) => {
      dispatch({ type: GET_UPDATES_FINISHED, data: {} });
    }
  );
};
//  https://dx-api.lexyslabs.com/api/v1/admin/viewAccessLevels?app_id=4&user_id=b712aa43-a728-4b1b-90c8-e871eee0e22b
/* const getUserRequestsByStatus =
  (isAdmin, status, page = 1, searchPayload) =>
  (dispatch) => {
    let url =
      'admin/user-request?status=' + status + `&page=${page}&per_page=10`;
    if (searchPayload?.query) {
      url += `&${searchPayload.type}=${searchPayload.query}`;
    } */

const viewAccessLevels = (payload) => (dispatch) => {
  // console.log('Payload_Action:', payload);
  // console.log('appId_Action:', appId);

  dispatch({ type: VIEW_ACCESS_LEVELS_START });
  let url = `admin/viewAccessLevels?${payload?.type}=${payload?.app_id}&user_id=${payload?.user_id}`;
  // if (appId?.search) {
  //   url += `&search=${appId?.search}`;
  // }
  axiosInstance.get(url).then(
    (res) => {
      dispatch({ type: VIEW_ACCESS_LEVELS_FINISHED, data: res.data });
    },
    (err) => {
      dispatch({ type: VIEW_ACCESS_LEVELS_FINISHED, data: {} });
      console.log('err', err);
    }
  );
};

const getMyRequests = (payload) => (dispatch) => {
  const { status } = payload;
  dispatch({ type: FETCH_MY_REQUESTS_START });
  axiosInstance
    .get('/admin/pending', {
      withCredentials: false,
    })
    .then(
      (res) => {
        dispatch({
          type: FETCH_MY_REQUESTS_FINISHED,
          data: requestsData,
        });
      },
      (err) => {
        console.log('Error', err);
        dispatch({ type: FETCH_MY_REQUESTS_FINISHED, data: requestsData });
      }
    );
};

const addNewApp = (appPayload, callback) => (dispatch) => {
  dispatch({ type: ADD_APPLICATION_START });
  axiosInstance
    .post('admin/add-app', appPayload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(
      (res) => {
        dispatch({ type: ADD_APPLICATION_FINISHED });
        callback && callback({ success: true });
      },
      (err) => {
        dispatch({ type: ADD_APPLICATION_FINISHED });
        callback && callback({ success: false });
      }
    );
};

const editApp = (appId, appPayload, callback) => (dispatch) => {
  dispatch({ type: ADD_APPLICATION_START });
  axiosInstance
    .post(`admin/add-app?id=${appId}`, appPayload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(
      (res) => {
        dispatch({ type: ADD_APPLICATION_FINISHED });
        callback && callback({ success: true });
      },
      (err) => {
        dispatch({ type: ADD_APPLICATION_FINISHED });
        callback && callback({ success: false });
      }
    );
};

const getCategoryCounts = (appPayload) => (dispatch) => {
  dispatch({ type: GET_CATEGORIES_START });
  axiosInstance.get('admin/app/category-wise', {}).then(
    (res) => {
      dispatch({ type: GET_CATEGORIES_FINISHED, data: res.data?.data || {} });
    },
    (err) => {
      dispatch({ type: GET_CATEGORIES_FINISHED, data: {} });
    }
  );
};

const getMyRequestStatusCounts = (isAdmin) => (dispatch) => {
  dispatch({ type: GET_MY_REQUEST_STATUSES_START });
  const url = 'approval/acceptreject-count';
  axiosInstance.get(url, {}).then(
    (res) => {
      dispatch({
        type: GET_MY_REQUEST_STATUSES_FINISHED,
        data: res.data?.data || {},
      });
    },
    (err) => {
      console.log('category counts error', err);
      dispatch({ type: GET_MY_REQUEST_STATUSES_FINISHED, data: {} });
    }
  );
};

const getUserRequestStatusCounts = (isAdmin) => (dispatch) => {
  dispatch({ type: GET_USER_REQUEST_STATUSES_START });
  const url = 'approval/status-count-owner';
  axiosInstance.get(url, {}).then(
    (res) => {
      dispatch({
        type: GET_USER_REQUEST_STATUSES_FINISHED,
        data: res.data?.data || {},
      });
    },
    (err) => {
      console.log('category counts error', err);
      dispatch({ type: GET_USER_REQUEST_STATUSES_FINISHED, data: {} });
    }
  );
};

const getUserRequestsByStatus =
  (isAdmin, status, page = 1, searchPayload) =>
  (dispatch) => {
    let url =
      'admin/user-request?status=' + status + `&page=${page}&per_page=10`;
    if (searchPayload?.query) {
      url += `&${searchPayload.type}=${searchPayload.query}`;
    }
    dispatch({ type: FETCH_USER_REQUESTS_START, status });
    axiosInstance.get(url, {}).then(
      (res) => {
        dispatch({
          status,
          type: FETCH_USER_REQUESTS_FINISHED,
          data: res.data?.data || [],
          pagination: res.data?.pagination || {},
        });
      },
      (err) => {
        console.log('category counts error', err);
        dispatch({ type: FETCH_USER_REQUESTS_FINISHED, data: [] });
      }
    );
  };

const getMyRequestsByStatus =
  (isAdmin, status, searchPayload) => (dispatch) => {
    let url = 'approval/myrequest?status=' + status;
    if (searchPayload?.query) {
      url += `&search=${searchPayload.query}`;
    }
    dispatch({ type: FETCH_MY_REQUESTS_START });
    axiosInstance.get(url, {}).then(
      (res) => {
        dispatch({
          type: FETCH_MY_REQUESTS_FINISHED,
          data: res.data?.data || [],
        });
      },
      (err) => {
        console.log('category counts error', err);
        dispatch({ type: FETCH_MY_REQUESTS_FINISHED, data: [] });
      }
    );
  };

const getCatOptions = (appPayload) => (dispatch) => {
  dispatch({ type: GET_CAT_OPTIONS_START });
  axiosInstance.get('admin/function-list', {}).then(
    (res) => {
      dispatch({ type: GET_CAT_OPTIONS_FINISHED, data: res.data?.data || [] });
    },
    (err) => {
      console.log('category counts error', err);
      dispatch({ type: GET_CAT_OPTIONS_FINISHED, data: [] });
    }
  );
};

const requestAccess = (payload, callback) => (dispatch) => {
  dispatch({ type: REQUEST_ACCESS_START });
  axiosInstance.post('admin/accessrequest', payload, {}).then(
    (res) => {
      dispatch({ type: REQUEST_ACCESS_FINISHED });
      callback && callback(res.data.success);
    },
    (err) => {
      console.log('error', err);
      dispatch({ type: REQUEST_ACCESS_FINISHED });
      callback && callback(false, err);
    }
  );
};
///api/v1/admin/importBulkAppUsers?app_id=3
const importBulkAppUsers = (appId, payload, callback) => (dispatch) => {
  dispatch({ type: IMPORT_BULK_APP_START });
  axiosInstance
    .post(`admin/importBulkAppUsers?app_id=${appId}`, payload, {})
    .then(
      (res) => {
        //console.log('res>>', res);
        dispatch({ type: IMPORT_BULK_APP_FINISHED });
        //callback && callback(res.data.success);
        if (res.status === 200) {
          const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download', 'bulk_file.csv'); // Set the filename here
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        callback && callback(res);
      },
      (err) => {
        //console.log('error>>', err);
        message.error(err?.response?.data?.message);
        dispatch({ type: IMPORT_BULK_APP_FINISHED });
        callback && callback(false, err);
      }
    );
};
/* const viewAccessLevels = (payload) => (dispatch) => {
  

  dispatch({ type: VIEW_ACCESS_LEVELS_START });
  let url = `admin/viewAccessLevels?${payload?.type}=${payload?.app_id}&user_id=${payload?.user_id}`;
  
  axiosInstance.get(url).then(
    (res) => {
      dispatch({ type: VIEW_ACCESS_LEVELS_FINISHED, data: res.data });
    },
    (err) => {
      dispatch({ type: VIEW_ACCESS_LEVELS_FINISHED, data: {} });
      console.log('err', err);
    }
  );
}; */
const createNewADGroup = (payload, callback) => (dispatch) => {
  dispatch({ type: CREATE_NEW_AD_GROUP_START });
  axiosInstance.post('admin/createNewADGroup', payload, {}).then(
    (res) => {
      dispatch({ type: CREATE_NEW_AD_GROUP_FINISHED });
      callback && callback(res.data.success);
    },
    (err) => {
      console.log('error', err);
      dispatch({ type: CREATE_NEW_AD_GROUP_FINISHED });
      callback && callback(false, err);
    }
  );
};

const createEditReleaseNote = (payload, callback) => (dispatch) => {
  dispatch({ type: CREATE_EDIT_RELEASE_NOTE_START });
  axiosInstance.post('admin/createEditReleaseNote', payload, {}).then(
    (res) => {
      dispatch({ type: CREATE_EDIT_RELEASE_NOTE_FINISHED });
      callback && callback(res.data.success);
    },
    (err) => {
      console.log('error', err);
      dispatch({ type: CREATE_EDIT_RELEASE_NOTE_FINISHED });
      callback && callback(false, err);
    }
  );
};

const approveRequests =
  (payload = {}, callback) =>
  (dispatch) => {
    const { appId, userId, status } = payload;
    dispatch({ type: UPDATE_REQUEST_STATUS_START });
    const url = `admin/acceptreject`;
    axiosInstance.patch(url, payload).then(
      (res) => {
        dispatch({ type: UPDATE_REQUEST_STATUS_FINISHED, data: res });
        callback && callback(true, status);
      },
      (err) => {
        console.log('approve request err', err);
        dispatch({ type: UPDATE_REQUEST_STATUS_FINISHED, data: [] });
        callback && callback(false, status);
      }
    );
  };
// https://dx-api.lexyslabs.com/api/v1/admin/customAppGroup/addMembers
const ADAppGroupAddMembers =
  (payload = {}, callback) =>
  (dispatch) => {
    const { appId, userId, status } = payload;
    dispatch({ type: AD_APP_GROUP_ADD_MEMBERS_STATUS_START });
    const url = `admin/customAppGroup/addMembers`;
    axiosInstance.patch(url, payload).then(
      (res) => {
        dispatch({ type: AD_APP_GROUP_ADD_MEMBERS_STATUS_FINISHED, data: res });
        callback && callback(true, status);
      },
      (err) => {
        console.log('approve request err', err);
        dispatch({ type: AD_APP_GROUP_ADD_MEMBERS_STATUS_FINISHED, data: [] });
        callback && callback(false, status);
      }
    );
  };

const deactivateAppADGroup =
  (payload = {}, callback) =>
  (dispatch) => {
    const { appId, userId, status } = payload;
    dispatch({ type: DEACTIVATE_APP_AD_GROUP_START });
    const url = `admin/deactivateAppADGroup`;
    axiosInstance.patch(url, payload).then(
      (res) => {
        dispatch({ type: DEACTIVATE_APP_AD_GROUP_FINISHED, data: res });
        callback && callback(true, status);
      },
      (err) => {
        console.log('approve request err', err);
        dispatch({ type: DEACTIVATE_APP_AD_GROUP_FINISHED, data: [] });
        callback && callback(false, status);
      }
    );
  };
const deactivateAppADMembers =
  (payload = {}, callback) =>
  (dispatch) => {
    const { appId, userId, status } = payload;
    dispatch({ type: DEACTIVATE_APP_AD_MEMBERS_START });
    const url = `admin/customAppGroup/removeMembers`;
    axiosInstance.patch(url, payload).then(
      (res) => {
        dispatch({ type: DEACTIVATE_APP_AD_MEMBERS_FINISHED, data: res });
        callback && callback(true, status);
      },
      (err) => {
        console.log('approve request err', err);
        dispatch({ type: DEACTIVATE_APP_AD_MEMBERS_FINISHED, data: [] });
        callback && callback(false, status);
      }
    );
  };

const getAppById =
  ({ appId, userId }) =>
  (dispatch) => {
    dispatch({ type: GET_APP_BY_ID_START });
    axiosInstance
      .get('admin/getApp' + `?app_id=${appId}&user_id=${userId}`, {})
      .then(
        (res) => {
          dispatch({ type: GET_APP_BY_ID_FINISHED, data: res.data });
        },
        (err) => {
          dispatch({ type: GET_APP_BY_ID_FINISHED, data: [] });
          console.log('error', err);
        }
      );
  };

const signin = (payoad, callback) => (dispatch) => {
  dispatch({ type: SIGNIN_START });
  axios.post(API_BASE_URL + 'user/signin', payoad).then(
    (res) => {
      dispatch({ type: SIGNIN_FINISHED, data: res.data });
      callback && callback(true, res.data);
    },
    (err) => {
      console.log('error', err);
      dispatch({ type: SIGNIN_FINISHED, data: {} });
      callback && callback(false);
    }
  );
};

const getUsers = (payload, callback) => (dispatch) => {
  dispatch({ type: GET_USERS_START });
  let url = API_BASE_URL + 'user/app-owner/list';
  if (payload && payload.search) {
    url += `?search=${payload.search}`;
  }
  axiosInstance.get(url, payload).then(
    (res) => {
      dispatch({ type: GET_USERS_FINISHED, data: res.data });
      callback && callback(true, res.data);
    },
    (err) => {
      console.log('error', err);
      dispatch({ type: GET_USERS_FINISHED, data: [] });
      callback && callback(false);
    }
  );
};
const getSBGUsers = (payload, callback) => (dispatch) => {
  console.log('payload_ACTION', payload);
  dispatch({ type: GET_SBG_USERS_START });
  let url = API_BASE_URL + 'user/access_levels/getSBGList';
  if (payload || payload.search) {
    url += `?app_id=${payload?.app_id}&page=${payload.page}&per_page=${parseInt(
      payload.per_page
    )}&search=${payload.search}`;
  }
  axiosInstance.get(url, payload).then(
    (res) => {
      dispatch({ type: GET_SBG_USERS_FINISHED, data: res.data });
      callback && callback(true, res.data);
    },
    (err) => {
      console.log('error', err);
      dispatch({ type: GET_SBG_USERS_FINISHED, data: [] });
      callback && callback(false);
    }
  );
};
const getSBUUsers = (payload, callback) => (dispatch) => {
  dispatch({ type: GET_SBU_USERS_START });
  let url = API_BASE_URL + 'user/access_levels/getSBUList';
  if (payload || payload.search) {
    url += `?app_id=${payload?.app_id}&page=${payload.page}&per_page=${parseInt(
      payload.per_page
    )}&search=${payload.search}`;
  }
  axiosInstance.get(url, payload).then(
    (res) => {
      dispatch({ type: GET_SBU_USERS_FINISHED, data: res.data });
      callback && callback(true, res.data);
    },
    (err) => {
      console.log('error', err);
      dispatch({ type: GET_SBU_USERS_FINISHED, data: [] });
      callback && callback(false);
    }
  );
};
const getBUUsers = (payload, callback) => (dispatch) => {
  dispatch({ type: GET_BU_USERS_START });
  let url = API_BASE_URL + 'user/access_levels/getBUList';
  if (payload || payload.search) {
    url += `?app_id=${payload?.app_id}&page=${payload.page}&per_page=${parseInt(
      payload.per_page
    )}&search=${payload.search}`;
  }
  axiosInstance.get(url, payload).then(
    (res) => {
      dispatch({ type: GET_BU_USERS_FINISHED, data: res.data });
      callback && callback(true, res.data);
    },
    (err) => {
      console.log('error', err);
      dispatch({ type: GET_BU_USERS_FINISHED, data: [] });
      callback && callback(false);
    }
  );
};
const getProjectUsers = (payload, callback) => (dispatch) => {
  dispatch({ type: GET_PROJECT_USERS_START });
  let url = API_BASE_URL + 'user/access_levels/getProjectList';
  if (payload || payload.search) {
    url += `?app_id=${payload?.app_id}&page=${payload.page}&per_page=${parseInt(
      payload.per_page
    )}&search=${payload.search}`;
  }
  axiosInstance.get(url, payload).then(
    (res) => {
      dispatch({ type: GET_PROJECT_USERS_FINISHED, data: res.data });
      callback && callback(true, res.data);
    },
    (err) => {
      console.log('error', err);
      dispatch({ type: GET_PROJECT_USERS_FINISHED, data: [] });
      callback && callback(false);
    }
  );
};

const submitFeedback = (payload, callback) => (dispatch) => {
  axiosInstance.post(API_BASE_URL + 'user/createrating', payload).then(
    (res) => {
      // dispatch({ type: GET_USERS_FINISHED, data: res.data });
      callback && callback(true, res.data);
    },
    (err) => {
      console.log('error', err);
      // dispatch({ type: GET_USERS_FINISHED, data: [] });
      callback && callback(false);
    }
  );
};

const deleteDocuments =
  (payloadArr = []) =>
  (dispatch) => {
    const requests = payloadArr.map((itm) =>
      axiosInstance.delete(API_BASE_URL + 'admin/dlt-document', { data: itm })
    );
    axios
      .all(requests)
      .then(
        axios.spread((obj1, obj2) => {
          console.log('deletion complete');
        })
      )
      .catch((err) => {
        console.log('error while deleting');
      });
  };

const getAppUsers = (appId, payload) => (dispatch) => {
  dispatch({ type: GET_APP_USERS_START });
  let url = `admin/app-users?id=${appId}&page=${payload.page}`;
  if (payload.search) {
    url += `&search=${payload.search}`;
  }
  axiosInstance.get(url).then(
    (res) => {
      dispatch({ type: GET_APP_USERS_FINISHED, data: res.data });
    },
    (err) => {
      dispatch({ type: GET_APP_USERS_FINISHED, data: {} });
      console.log('err', err);
    }
  );
};
const getCustomAppADGroups = (appId, payload) => (dispatch) => {
  // console.log('Payload_Action:', payload);
  //console.log('appId_Action:', appId);
  dispatch({ type: GET_CUSTOM_APP_AD_GROUP_START });
  let url = `admin/getCustomAppADGroups?app_id=${appId?.app_id}&category=${appId?.category_type}`;
  if (appId?.search) {
    url += `&search=${appId?.search}`;
  }
  axiosInstance.get(url).then(
    (res) => {
      dispatch({ type: GET_CUSTOM_APP_AD_GROUP_FINISHED, data: res.data });
    },
    (err) => {
      dispatch({ type: GET_CUSTOM_APP_AD_GROUP_FINISHED, data: {} });
      console.log('err', err);
    }
  );
};

const getAppADGroups = (appId, payload) => (dispatch) => {
  // console.log('Payload_Action:', payload);
  //console.log('appId_Action:', appId);
  dispatch({ type: GET_APP_AD_GROUP_START });
  let url = `user/getAppADGroups?app_id=${appId?.app_id}`; //&category=${appId?.category_type}`;
  if (appId?.search) {
    url += `&search=${appId?.search}`;
  }
  axiosInstance.get(url).then(
    (res) => {
      dispatch({ type: GET_APP_AD_GROUP_FINISHED, data: res.data });
    },
    (err) => {
      dispatch({ type: GET_APP_AD_GROUP_FINISHED, data: {} });
      console.log('err', err);
    }
  );
};
const getCustomAppADMembersList = (appId, payload) => (dispatch) => {
  // console.log('Payload_Action:', payload);
  //console.log('appId_Action:', appId);
  dispatch({ type: GET_CUSTOM_APP_AD_MEMBERS_START });
  let url = `admin/customAppGroup/active-users-list?app_id=${appId?.app_id}&group_id=${appId?.group_id}&page=${appId?.page}&per_page=${appId?.per_page}`;
  if (appId?.search) {
    url += `&search=${appId?.search}`;
  }
  axiosInstance.get(url).then(
    (res) => {
      dispatch({ type: GET_CUSTOM_APP_AD_MEMBERS_FINISHED, data: res.data });
    },
    (err) => {
      dispatch({ type: GET_CUSTOM_APP_AD_MEMBERS_FINISHED, data: {} });
      console.log('err', err);
    }
  );
};

const getAppOwners = (appId, payload) => (dispatch) => {
  dispatch({ type: GET_APP_OWNERS_START });
  let url = `admin/app-owners?id=${appId}&page=${payload.page}`;
  if (payload.search) {
    url += `&search=${payload.search}`;
  }
  axiosInstance.get(url).then(
    (res) => {
      dispatch({ type: GET_APP_OWNERS_FINISHED, data: res.data || {} });
    },
    (err) => {
      dispatch({ type: GET_APP_OWNERS_FINISHED, data: {} });
      console.log('err', err);
    }
  );
};

const addUsersToApp = (payload, callback) => (dispatch) => {
  axiosInstance.put(API_BASE_URL + 'admin/add-owners', payload).then(
    (res) => {
      callback && callback(true, res.data);
    },
    (err) => {
      console.log('error', err);
      callback && callback(false);
    }
  );
};

const deactivateUSerFromApp = (payload, callback) => (dispatch) => {
  axiosInstance
    .delete(API_BASE_URL + 'admin/remove-owner', { data: payload })
    .then(
      (res) => {
        callback && callback(true, res.data);
      },
      (err) => {
        console.log('error', err);
        callback && callback(false);
      }
    );
};

const syncAdUsers = (appId, callback) => (dispatch) => {
  axiosInstance
    .get(API_BASE_URL + `admin/sync-azure-group-members?app_id=${appId}`, {})
    .then(
      (res) => {
        callback && callback(true, res.data);
      },
      (err) => {
        console.log('error', err);
        callback && callback(false);
      }
    );
};

const getAppWiseRatings =
  ({ page }) =>
  (dispatch) => {
    let url = 'app_rating/app_wise_rating?page=' + `${page}&per_page=10`;
    dispatch({ type: 'FETCH_APP_USER_RATINGS_START' });
    axiosInstance.get(url, {}).then(
      (res) => {
        dispatch({
          type: 'FETCH_APP_USER_RATINGS_FINISHED',
          data: res.data?.data || [],
          pagination: res?.data?.pagination || {},
        });
      },
      (err) => {
        console.log('user ratings error', err);
        dispatch({ type: 'FETCH_APP_USER_RATINGS_FINISHED', data: [] });
      }
    );
  };

const getWelcomeInfo = (callback) => {
  return axiosInstance.get(`admin/welcome-info`, {});
};

const makeApiCall = (type = 'get', url, payload) => {
  if (type === 'post') {
    return axiosInstance.post(url, payload);
  } else if (type === 'get') {
    return axiosInstance.get(url);
  } else if (type === 'delete') {
    return axiosInstance.delete(url);
  } else {
    return;
  }
};

const stagingSecret = (payload) => {
  const token = localStorage.getItem('access_token');
  return axios.post(DEV_URL + `api/v1/secret/create_secret`, payload, {
    withCredentials: false,
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });
};

const appActions = {
  // getAppsList,
  getPermittedAppsList,
  // getRequests,
  getAppById,
  addNewApp,
  getCategoryCounts,
  getMyRequestStatusCounts,
  getUserRequestStatusCounts,
  getCatOptions,
  requestAccess,
  createNewADGroup,
  ADAppGroupAddMembers,
  deactivateAppADGroup,
  approveRequests,
  signin,
  getUsers,
  submitFeedback,
  getMyRequestsByStatus,
  getUserRequestsByStatus,
  editApp,
  deleteDocuments,
  getAppUsers,
  getCustomAppADGroups,
  getAppOwners,
  addUsersToApp,
  deactivateUSerFromApp,
  syncAdUsers,
  getAppWiseRatings,
  getWelcomeInfo,
  makeApiCall,
  stagingSecret,
  getSBGUsers,
  getSBUUsers,
  getBUUsers,
  getProjectUsers,
  deactivateAppADMembers,
  getCustomAppADMembersList,
  getUpdatesList,
  getUpdatesCounts,
  viewAccessLevels,
  createEditReleaseNote,
  importBulkAppUsers,
  getAppADGroups,
};

export default appActions;
