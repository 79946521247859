import React from 'react';
import { Table, Tooltip } from 'antd';
import './viewAccessLevel.css';
import moment from 'moment';

const ViewAccessLevel = ({ data }) => {
  //console.log('DATA:=', data);

  const access_levels_type =
    data?.length > 0 ? data[0]?.access_levels_type : '';
  //console.log('TYPE:=', access_levels_type);

  const dataSBG = data?.map((item) => ({
    key: item?.id,
    name: item?.SBG_name,
    type: item?.access_levels_type,
    SBG_id: item?.SBG_id,
    expiry_date: item?.expiry_date,
  }));
  const dataSBU = data?.map((item) => ({
    key: item?.id,
    name: item?.SBU_name,
    type: item?.access_levels_type,
    SBU_id: item?.SBU_id,
    expiry_date: item?.expiry_date,
  }));
  const dataBU = data?.map((item) => ({
    key: item?.id,
    name: item?.BU_name,
    type: item?.access_levels_type,
    BU_id: item?.BU_id,
    expiry_date: item?.expiry_date,
  }));
  const dataProject = data?.map((item) => ({
    key: item?.id,
    name: `${item?.Project_name} (${item?.Project_code})`,
    type: item?.access_levels_type,
    Project_id: item?.Project_id,
    expiry_date: item?.expiry_date,
  }));

  const columnsSBG = [
    {
      title: 'SBG Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      ellipsis: {
        showTitle: false,
      },
      render: (name) => {
        return (
          <>
            <Tooltip
              title={name}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {name}
            </Tooltip>
          </>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => a.type.localeCompare(b.type),
      ellipsis: {
        showTitle: false,
      },
      render: (type) => {
        return (
          <>
            <Tooltip
              title={type}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {type}
            </Tooltip>
          </>
        );
      },
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expiry_date',
      key: 'expiry_date',
      sorter: (a, b) => a.expiry_date.localeCompare(b.expiry_date),
      ellipsis: {
        showTitle: false,
      },
      render: (expiry_date) => {
        return (
          <>
            <Tooltip
              title={expiry_date}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {expiry_date === null
                ? 'NA'
                : moment(expiry_date, 'DD-MM-YYYY').format('DD MMM YYYY')}
            </Tooltip>
          </>
        );
      },
    },
  ];
  const columnsSBU = [
    {
      title: 'SBU Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      ellipsis: {
        showTitle: false,
      },
      render: (name) => {
        return (
          <>
            <Tooltip
              title={name}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {name}
            </Tooltip>
          </>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => a.type.localeCompare(b.type),
      ellipsis: {
        showTitle: false,
      },
      render: (type) => {
        return (
          <>
            <Tooltip
              title={type}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {type}
            </Tooltip>
          </>
        );
      },
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expiry_date',
      key: 'expiry_date',
      sorter: (a, b) => a.expiry_date.localeCompare(b.expiry_date),
      ellipsis: {
        showTitle: false,
      },
      render: (expiry_date) => {
        return (
          <>
            <Tooltip
              title={expiry_date}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {expiry_date === null
                ? 'NA'
                : moment(expiry_date, 'DD-MM-YYYY').format('DD MMM YYYY')}
            </Tooltip>
          </>
        );
      },
    },
  ];
  const columnsBU = [
    {
      title: 'BU Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      ellipsis: {
        showTitle: false,
      },
      render: (name) => {
        return (
          <>
            <Tooltip
              title={name}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {name}
            </Tooltip>
          </>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => a.type.localeCompare(b.type),
      ellipsis: {
        showTitle: false,
      },
      render: (type) => {
        return (
          <>
            <Tooltip
              title={type}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {type}
            </Tooltip>
          </>
        );
      },
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expiry_date',
      key: 'expiry_date',
      //sorter: (a, b) => a.expiry_date.localeCompare(b.expiry_date),
      ellipsis: {
        showTitle: false,
      },
      render: (expiry_date) => {
        return (
          <>
            <Tooltip
              title={expiry_date}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {expiry_date === null
                ? 'NA'
                : moment(expiry_date, 'DD-MM-YYYY').format('DD MMM YYYY')}
            </Tooltip>
          </>
        );
      },
    },
  ];
  const columnsProject = [
    {
      title: 'Project Name & code',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      ellipsis: {
        showTitle: false,
      },
      render: (name) => {
        return (
          <>
            <Tooltip
              title={name}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {name}
            </Tooltip>
          </>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => a.type.localeCompare(b.type),
      ellipsis: {
        showTitle: false,
      },
      render: (type) => {
        return (
          <>
            <Tooltip
              title={type}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {type}
            </Tooltip>
          </>
        );
      },
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expiry_date',
      key: 'expiry_date',
      sorter: (a, b) => a.expiry_date.localeCompare(b.expiry_date),
      ellipsis: {
        showTitle: false,
      },
      render: (expiry_date) => {
        return (
          <>
            <Tooltip
              title={expiry_date}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {expiry_date === null
                ? 'NA'
                : moment(expiry_date, 'DD-MM-YYYY').format('DD MMM YYYY')}
            </Tooltip>
          </>
        );
      },
    },
  ];

  return (
    <div>
      <Table
        dataSource={
          access_levels_type === 'SBG'
            ? dataSBG
            : access_levels_type === 'SBU'
            ? dataSBU
            : access_levels_type === 'BU'
            ? dataBU
            : access_levels_type === 'Project'
            ? dataProject
            : null
        }
        columns={
          access_levels_type === 'SBG'
            ? columnsSBG
            : access_levels_type === 'SBU'
            ? columnsSBU
            : access_levels_type === 'BU'
            ? columnsBU
            : access_levels_type === 'Project'
            ? columnsProject
            : null
        }
        pagination={false}
      />
    </div>
  );
};
export default ViewAccessLevel;
