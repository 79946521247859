import './report.css';
import React, { useState } from 'react';
import { Card, Typography, Button, Tooltip, Space } from 'antd';
import 'antd/dist/antd.css';
const { Meta } = Card;
const ReportCard = ({ item, is_accessible }) => {
  //   const [anchorEl, setAnchorEl] = useState(null);
  const [expanded, setExpanded] = useState(false);

  //   const handlePopoverOpen = (event) => {
  //     setAnchorEl(event.currentTarget);
  //   };

  //   const handlePopoverClose = () => {
  //     setAnchorEl(null);
  //   };

  //   const open = Boolean(anchorEl);
  return (
    <Card
      className="container report-card"
      title={
        <Typography.Text
          style={{ textTransform: 'capitalize', color: 'green' }}
        >
          {item?.report_name}
        </Typography.Text>
      }
      actions={[
        <Tooltip
          title="View Report"
          overlayStyle={{ backgroundColor: 'white' }}
          overlayInnerStyle={{ backgroundColor: 'white' }}
        >
          <Button
            href={item?.report_url}
            target="_blank"
            className="view-report-btn"
            disabled={is_accessible === false}
          >
            View Report
          </Button>
        </Tooltip>,
      ]}
    >
      <Meta
        description={
          <>
            {/* <Tooltip title={item?.description}>
            <Typography.Paragraph ellipsis={{ rows: 2, expandable: true }}>
              {item?.description}
            </Typography.Paragraph>
          </Tooltip> */}
            <Typography.Paragraph
              ellipsis={
                !expanded
                  ? {
                      expandable: false,
                      rows: 3,
                    }
                  : false
              }
            >
              {item?.description}
            </Typography.Paragraph>
            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
                marginTop: '-18px',
              }}
            >
              <Button type="link" onClick={() => setExpanded((prev) => !prev)}>
                {expanded ? 'Hide' : 'Show'}
              </Button>
            </div>
          </>
        }
      />
    </Card>
  );
};

export default ReportCard;
