import {
  EyeOutlined,
  MobileOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';
import { Badge, Button, Dropdown, Menu, Tag, Tooltip, Space, Card } from 'antd';
import './appcard.css';
import { ReactComponent as CardCalendar } from '../../assets/card-calendar.svg';

export const toTitleCase = (str) => {
  return str?.replace(/\w\S*/g, function (txt) {
    return txt?.charAt(0)?.toUpperCase() + txt?.substr(1)?.toLowerCase();
  });
};

const AppCard = (props) => {
  const {
    app,
    onOpen,
    requestAccess,
    dashboard,
    isAdmin,
    editEntity,
    openQuicklook,
    upcoming,
    isSarovar,
    //entity,
  } = props;

  const getAppIcon = (files = []) => {
    const profile = files.filter((itm) => itm.image_use === 'profile');
    return profile[0]?.doc_url || 'pp1.png';
  };

  const nameKey = dashboard ? 'dash_name' : 'app_name';
  const descKey = dashboard ? 'dash_description' : 'app_description';

  const getFeatureImage = (files = []) => {
    const profile = files.filter((itm) => itm.image_use === 'feature_image');
    return profile[0]?.doc_url || 'feature_img.png';
  };

  const getQuickLook = (files = []) => {
    const quickLook = files.filter((itm) => itm.image_use === 'quick_look');
    return quickLook[0]?.doc_url || null;
  };

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const menu = (
    <Menu
      items={[
        {
          key: '1',
          label: (
            <a
              onClick={(e) => e.stopPropagation()}
              href={`mailto:?subject=Check out the ${
                app[nameKey]
              }&body=Hello, please check out the ${app[nameKey]} ${
                isSarovar ? 'Module' : dashboard ? 'Dashboard' : 'App'
              } on DX Store by going to (${SERVER_URL}${
                isSarovar ? 'sarovar' : dashboard ? 'dashboard' : 'app'
              }/${app.id})`}
              target="_top"
            >
              Email
            </a>
          ),
        },
      ]}
    />
  );

  return (
    <>
      {isSarovar === false && app?.isUpdatesVisible === true ? (
        <Badge.Ribbon text="Updated" color="volcano">
          <div
            className="card-container"
            onClick={() =>
              app.access_type === 'open' ? onOpen(app.id) : onOpen(app.id)
            }
          >
            {/* {!dashboard && app?.isUpdatesVisible === true && (
              <div className="card-ribbon">
                <span className="card-ribbon-text">Update</span>
              </div>
            )} */}
            <div className="card-container-padding">
              <div className="card-header">
                <span className="app-profile-img">
                  <img
                    // src={getAppIcon(
                    //   dashboard ? app.Dash_Documents : app.Documents
                    // )}
                    src={app?.profile_images[0]?.image_url}
                    alt=""
                    style={{ width: '30px', height: '30px' }}
                  />
                </span>
                <Tooltip
                  title={toTitleCase(app[nameKey])}
                  color={'white'}
                  overlayInnerStyle={{
                    color: '#32324D',
                    fontSize: '14px',
                    //fontFamily: 'SFProText Regular',
                  }}
                >
                  <span className="app-card-title">{app[nameKey]}</span>
                </Tooltip>

                {!dashboard && (
                  <span className="app-card-icon d-flex">
                    <div>
                      {app.isWeb ? (
                        <CardCalendar />
                      ) : app.isBoth ? (
                        <>
                          <CardCalendar />
                          <MobileOutlined />
                        </>
                      ) : (
                        <MobileOutlined />
                      )}
                    </div>
                  </span>
                )}
              </div>
              <Tooltip
                title={app[descKey]}
                color={'white'}
                overlayInnerStyle={{
                  color: '#32324D',
                  fontSize: '14px',
                  //fontFamily: 'SFProText Regular',
                }}
              >
                <div className="card-app-desc">{app[descKey]}</div>
              </Tooltip>
              <Tag className="app-tag" color={'#EBEFF3'}>
                {isSarovar ? app?.dashboard_group : app.Function?.function_name}
              </Tag>
              {dashboard && (
                <div className="feature-image">
                  <img
                    // src={getFeatureImage(
                    //   dashboard ? app.Dash_Documents : app.Documents
                    // )}
                    src={app?.feature_images[0]?.image_url}
                    style={{ width: '100%', height: '100%' }}
                    alt=""
                  />
                </div>
              )}
              <div className="card-actions">
                {isAdmin && (
                  <Button
                    type="text"
                    className="card-buttons px-3"
                    shape="round"
                    onClick={(e) => {
                      e.stopPropagation();
                      editEntity(app);
                    }}
                  >
                    {'Edit'}
                  </Button>
                )}
                {!upcoming ? (
                  <Button
                    type="text"
                    className="card-buttons"
                    shape="round"
                    onClick={(e) => {
                      e.stopPropagation();
                      app.access_type === 'open'
                        ? onOpen(app.id)
                        : requestAccess(app);
                    }}
                  >
                    {app.access_type === 'open'
                      ? 'Open'
                      : // : isSarovar && app?.dashboard_group === 'TMU'
                        // ? 'Request Access'
                        // : isSarovar && app?.dashboard_group !== 'TMU'
                        // ? ''
                        'Request Access'}
                  </Button>
                ) : (
                  <Badge
                    className="ms-2 upcoming-badge me-2"
                    count="UPCOMING"
                    showZero
                    color="#FF5722"
                  />
                )}
                {!upcoming && (
                  <span className="middle-icon">
                    <Dropdown overlay={menu} className="d-flex">
                      <div onClick={(e) => e.stopPropagation()}>
                        <ShareAltOutlined />
                      </div>
                    </Dropdown>
                  </span>
                )}
                {/* {!upcoming && */}
                {getQuickLook(
                  dashboard ? app.Dash_Documents : app.Documents
                ) && (
                  // <a
                  //   // type="primary"
                  //   onClick={(e) => e.stopPropagation()}
                  //   href={getQuickLook(dashboard ? app.Dash_Documents : app.Documents)}
                  //   target="_blank"
                  //   rel="noreferrer"
                  //   className="quick-look d-flex"
                  // >
                  <Tooltip
                    title="Quick Look"
                    color={'white'}
                    overlayInnerStyle={{
                      color: '#32324D',
                      fontSize: '14px',
                      //fontFamily: 'SFProText Regular',
                    }}
                  >
                    <EyeOutlined
                      onClick={(e) => {
                        e.stopPropagation();
                        openQuicklook(
                          getQuickLook(
                            dashboard ? app.Dash_Documents : app.Documents
                          )
                        );
                      }}
                    />
                  </Tooltip>
                  // </a>
                )}
                {/* } */}
              </div>
            </div>
          </div>
        </Badge.Ribbon>
      ) : isSarovar === false && app?.is_new === true ? (
        <Badge.Ribbon text="New">
          <div
            className="card-container"
            onClick={() =>
              app.access_type === 'open' ? onOpen(app.id) : onOpen(app.id)
            }
          >
            {/* {!dashboard && app?.isUpdatesVisible === true && (
              <div className="card-ribbon">
                <span className="card-ribbon-text">Update</span>
              </div>
            )} */}
            <div className="card-container-padding">
              <div className="card-header">
                <span className="app-profile-img">
                  <img
                    src={app?.profile_images[0]?.image_url} //{app?.image_url}
                    // {getAppIcon(
                    //   dashboard ? app.Dash_Documents : app.Documents
                    // )}
                    style={{ width: '30px', height: '30px' }}
                    alt=""
                  />
                </span>
                <Tooltip
                  title={toTitleCase(app[nameKey])}
                  color={'white'}
                  overlayInnerStyle={{
                    color: '#32324D',
                    fontSize: '14px',
                    //fontFamily: 'SFProText Regular',
                  }}
                >
                  <span className="app-card-title">{app[nameKey]}</span>
                </Tooltip>

                {!dashboard && (
                  <span className="app-card-icon d-flex">
                    <div>
                      {app.isWeb ? (
                        <CardCalendar />
                      ) : app.isBoth ? (
                        <>
                          <CardCalendar />
                          <MobileOutlined />
                        </>
                      ) : (
                        <MobileOutlined />
                      )}
                    </div>
                  </span>
                )}
              </div>
              <Tooltip
                title={app[descKey]}
                color={'white'}
                overlayInnerStyle={{
                  color: '#32324D',
                  fontSize: '14px',
                  //fontFamily: 'SFProText Regular',
                }}
              >
                {' '}
                <div className="card-app-desc">{app[descKey]}</div>{' '}
              </Tooltip>
              <Tag className="app-tag" color={'#EBEFF3'}>
                {isSarovar ? app?.dashboard_group : app.Function?.function_name}
              </Tag>
              {dashboard && (
                <div className="feature-image">
                  <img
                    src={
                      app?.feature_images[0]?.image_url
                      // getFeatureImage(
                      // dashboard ? app.Dash_Documents : app.Documents)
                    }
                    style={{ width: '100%', height: '100%' }}
                    alt=""
                  />
                </div>
              )}
              <div className="card-actions">
                {isAdmin && (
                  <Button
                    type="text"
                    className="card-buttons px-3"
                    shape="round"
                    onClick={(e) => {
                      e.stopPropagation();
                      editEntity(app);
                    }}
                  >
                    {'Edit'}
                  </Button>
                )}
                {!upcoming ? (
                  <Button
                    type="text"
                    className="card-buttons"
                    shape="round"
                    onClick={(e) => {
                      e.stopPropagation();
                      app.access_type === 'open'
                        ? onOpen(app.id)
                        : requestAccess(app);
                    }}
                  >
                    {app.access_type === 'open'
                      ? 'Open'
                      : // : isSarovar && app?.dashboard_group === 'TMU'
                        // ? 'Request Access'
                        // : isSarovar && app?.dashboard_group !== 'TMU'
                        // ? ''
                        'Request Access'}
                  </Button>
                ) : (
                  <Badge
                    className="ms-2 upcoming-badge me-2"
                    count="UPCOMING"
                    showZero
                    color="#FF5722"
                  />
                )}
                {!upcoming && (
                  <span className="middle-icon">
                    <Dropdown overlay={menu} className="d-flex">
                      <div onClick={(e) => e.stopPropagation()}>
                        <ShareAltOutlined />
                      </div>
                    </Dropdown>
                  </span>
                )}
                {/* {!upcoming && */}
                {getQuickLook(
                  dashboard ? app.Dash_Documents : app.Documents
                ) && (
                  // <a
                  //   // type="primary"
                  //   onClick={(e) => e.stopPropagation()}
                  //   href={getQuickLook(dashboard ? app.Dash_Documents : app.Documents)}
                  //   target="_blank"
                  //   rel="noreferrer"
                  //   className="quick-look d-flex"
                  // >
                  <Tooltip
                    title="Quick Look"
                    color={'white'}
                    overlayInnerStyle={{
                      color: '#32324D',
                      fontSize: '14px',
                      //fontFamily: 'SFProText Regular',
                    }}
                  >
                    <EyeOutlined
                      onClick={(e) => {
                        e.stopPropagation();
                        openQuicklook(
                          getQuickLook(
                            dashboard ? app.Dash_Documents : app.Documents
                          )
                        );
                      }}
                    />
                  </Tooltip>
                  // </a>
                )}
                {/* } */}
              </div>
            </div>
          </div>
        </Badge.Ribbon>
      ) : (
        <div
          className="card-container"
          onClick={() =>
            app.access_type === 'open' ? onOpen(app.id) : onOpen(app.id)
          }
        >
          <div className="card-container-padding">
            <div className="card-header">
              <span className="app-profile-img">
                <img
                  src={app?.profile_images[0]?.image_url} //{app?.image_url}
                  // {getAppIcon(
                  //   dashboard ? app.Dash_Documents : app.Documents
                  // )}
                  style={{ width: '30px', height: '30px' }}
                  alt=""
                />
              </span>
              <Tooltip
                title={toTitleCase(app[nameKey])}
                color={'white'}
                overlayInnerStyle={{
                  color: '#32324D',
                  fontSize: '14px',
                  //fontFamily: 'SFProText Regular',
                }}
              >
                <span className="app-card-title">{app[nameKey]}</span>
              </Tooltip>

              {!dashboard && (
                <span className="app-card-icon d-flex">
                  <div>
                    {app.isWeb ? (
                      <CardCalendar />
                    ) : app.isBoth ? (
                      <>
                        <CardCalendar />
                        <MobileOutlined />
                      </>
                    ) : (
                      <MobileOutlined />
                    )}
                  </div>
                </span>
              )}
            </div>
            <Tooltip
              title={app[descKey]}
              color={'white'}
              overlayInnerStyle={{
                color: '#32324D',
                fontSize: '14px',
                //fontFamily: 'SFProText Regular',
              }}
            >
              {' '}
              <div className="card-app-desc">{app[descKey]}</div>{' '}
            </Tooltip>
            <Tag className="app-tag" color={'#EBEFF3'}>
              {isSarovar ? app?.dashboard_group : app.Function?.function_name}
            </Tag>
            {dashboard && (
              <div className="feature-image">
                <img
                  // src={getFeatureImage(
                  //   dashboard ? app.Dash_Documents : app.Documents
                  // )}
                  src={app?.feature_images[0]?.image_url}
                  style={{ width: '100%', height: '100%' }}
                  alt=""
                />
              </div>
            )}
            <div className="card-actions">
              {isAdmin && (
                <Button
                  type="text"
                  className="card-buttons px-3"
                  shape="round"
                  onClick={(e) => {
                    e.stopPropagation();
                    editEntity(app);
                  }}
                >
                  {'Edit'}
                </Button>
              )}
              {!upcoming ? (
                <Button
                  type="text"
                  className="card-buttons"
                  shape="round"
                  onClick={(e) => {
                    e.stopPropagation();
                    app.access_type === 'open'
                      ? onOpen(app.id)
                      : requestAccess(app);
                  }}
                >
                  {app.access_type === 'open'
                    ? 'Open'
                    : // : isSarovar && app?.dashboard_group === 'TMU'
                      // ? 'Request Access'
                      // : isSarovar && app?.dashboard_group !== 'TMU'
                      // ? ''
                      'Request Access'}
                </Button>
              ) : (
                <Badge
                  className="ms-2 upcoming-badge me-2"
                  count="UPCOMING"
                  showZero
                  color="#FF5722"
                />
              )}
              {!upcoming && (
                <span className="middle-icon">
                  <Dropdown overlay={menu} className="d-flex">
                    <div onClick={(e) => e.stopPropagation()}>
                      <ShareAltOutlined />
                    </div>
                  </Dropdown>
                </span>
              )}
              {/* {!upcoming && */}
              {getQuickLook(dashboard ? app.Dash_Documents : app.Documents) && (
                // <a
                //   // type="primary"
                //   onClick={(e) => e.stopPropagation()}
                //   href={getQuickLook(dashboard ? app.Dash_Documents : app.Documents)}
                //   target="_blank"
                //   rel="noreferrer"
                //   className="quick-look d-flex"
                // >
                <Tooltip
                  title="Quick Look"
                  color={'white'}
                  overlayInnerStyle={{
                    color: '#32324D',
                    fontSize: '14px',
                    //fontFamily: 'SFProText Regular',
                  }}
                >
                  <EyeOutlined
                    onClick={(e) => {
                      e.stopPropagation();
                      openQuicklook(
                        getQuickLook(
                          dashboard ? app.Dash_Documents : app.Documents
                        )
                      );
                    }}
                  />
                </Tooltip>
                // </a>
              )}
              {/* } */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AppCard;
