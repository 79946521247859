export const FETCH_APPS_START = 'FETCH_APPS_START';
export const FETCH_APPS_FINISHED = 'FETCH_APPS_FINISHED';

export const GET_UPDATES_LIST_DASH_START = 'GET_UPDATES_LIST_DASH_START';
export const GET_UPDATES_LIST_DASH_FINISHED = 'GET_UPDATES_LIST_DASH_FINISHED';

export const GET_UPDATES_START = 'GET_UPDATES_START';
export const GET_UPDATES_FINISHED = 'GET_UPDATES_FINISHED';

export const IMPORT_BULK_APP_START = 'IMPORT_BULK_APP_START';
export const IMPORT_BULK_APP_FINISHED = 'IMPORT_BULK_APP_FINISHED ';

export const IMPORT_BULK_DASH_START = 'IMPORT_BULK_DASH_START';
export const IMPORT_BULK_DASH_FINISHED = 'IMPORT_BULK_DASH_FINISHED ';

export const CREATE_EDIT_RELEASE_NOTE_START = 'CREATE_EDIT_RELEASE_NOTE_START';
export const CREATE_EDIT_RELEASE_NOTE_FINISHED =
  'CREATE_EDIT_RELEASE_NOTE_FINISHED';

export const CREATE_EDIT_RELEASE_NOTE_DASH_FINISHED =
  'CREATE_EDIT_RELEASE_NOTE_DASH_FINISHED';
export const CREATE_EDIT_RELEASE_NOTE_DASH_START =
  'CREATE_EDIT_RELEASE_NOTE_DASH_START';

export const GET_UPDATES_LIST_START = 'GET_UPDATES_LIST_START';
export const GET_UPDATES_LIST_FINISHED = 'GET_UPDATES_LIST_FINISHED';

export const VIEW_ACCESS_LEVELS_START = 'VIEW_ACCESS_LEVELS_START';
export const VIEW_ACCESS_LEVELS_FINISHED = 'VIEW_ACCESS_LEVELS_FINISHED';

export const FETCH_MY_REQUESTS_START = 'FETCH_MY_REQUESTS_START';
export const FETCH_MY_REQUESTS_FINISHED = 'FETCH_MY_REQUESTS_FINISHED';

export const FETCH_USER_REQUESTS_START = 'FETCH_USER_REQUESTS_START';
export const FETCH_USER_REQUESTS_FINISHED = 'FETCH_USER_REQUESTS_FINISHED';

export const FETCH_APP_DETAILS_FINISHED = 'FETCH_APP_DETAILS_FINISHED';
export const FETCH_APP_DETAILS_START = 'FETCH_APP_DETAILS_START';

export const UPDATE_SHOW_BACKGROUND = 'UPDATE_SHOW_BACKGROUND';
export const UPDATE_SHOW_HEADER = 'UPDATE_SHOW_HEADER';

export const ADD_APPLICATION_START = 'ADD_APPLICATION_START';
export const ADD_APPLICATION_FINISHED = 'ADD_APPLICATION_FINISHED';

export const GET_CATEGORIES_START = 'GET_CATEGORIES_START';
export const GET_CATEGORIES_FINISHED = 'GET_CATEGORIES_FINISHED';

export const GET_MY_REQUEST_STATUSES_START = 'GET_MY_REQUEST_STATUSES_START';
export const GET_MY_REQUEST_STATUSES_FINISHED =
  'GET_MY_REQUEST_STATUSES_FINISHED';

export const GET_USER_REQUEST_STATUSES_START =
  'GET_USER_REQUEST_STATUSES_START';
export const GET_USER_REQUEST_STATUSES_FINISHED =
  'GET_USER_REQUEST_STATUSES_FINISHED';

export const GET_CAT_OPTIONS_START = 'GET_CAT_OPTIONS_START';
export const GET_CAT_OPTIONS_FINISHED = 'GET_CAT_OPTIONS_FINISHED';

export const REQUEST_ACCESS_START = 'REQUEST_ACCESS_START';
export const REQUEST_ACCESS_FINISHED = 'REQUEST_ACCESS_FINISHED';

export const CREATE_NEW_AD_GROUP_START = 'CREATE_NEW_AD_GROUP_START';
export const CREATE_NEW_AD_GROUP_FINISHED = 'CREATE_NEW_AD_GROUP_FINISHED';

export const CREATE_NEW_DASH_AD_GROUP_START = 'CREATE_NEW_DASH_AD_GROUP_START';
export const CREATE_NEW_DASH_AD_GROUP_FINISHED =
  'CREATE_NEW_DASH_AD_GROUP_FINISHED';

export const UPDATE_REQUEST_STATUS_START = 'UPDATE_REQUEST_STATUS_START';
export const UPDATE_REQUEST_STATUS_FINISHED = 'UPDATE_REQUEST_STATUS_FINISHED';

export const GET_CUSTOM_DASH_AD_MEMBERS_START =
  'GET_CUSTOM_DASH_AD_MEMBERS_START';
export const GET_CUSTOM_DASH_AD_MEMBERS_FINISHED =
  'GET_CUSTOM_DASH_AD_MEMBERS_FINISHED';

export const GET_CUSTOM_APP_AD_MEMBERS_START =
  'GET_CUSTOM_APP_AD_MEMBERS_START';
export const GET_CUSTOM_APP_AD_MEMBERS_FINISHED =
  'GET_CUSTOM_APP_AD_MEMBERS_FINISHED';

export const AD_APP_GROUP_ADD_MEMBERS_STATUS_START =
  'AD_APP_GROUP_ADD_MEMBERS_STATUS_START';
export const AD_APP_GROUP_ADD_MEMBERS_STATUS_FINISHED =
  'AD_APP_GROUP_ADD_MEMBERS_STATUS_FINISHED';

export const AD_DASH_GROUP_ADD_MEMBERS_STATUS_START =
  'AD_DASH_GROUP_ADD_MEMBERS_STATUS_START';
export const AD_DASH_GROUP_ADD_MEMBERS_STATUS_FINISHED =
  'AD_DASH_GROUP_ADD_MEMBERS_STATUS_FINISHED';

export const GET_APP_BY_ID_START = 'GET_APP_BY_ID_START';
export const GET_APP_BY_ID_FINISHED = 'GET_APP_BY_ID_FINISHED';

export const DEACTIVATE_APP_AD_GROUP_START = 'DEACTIVATE_APP_AD_GROUP_START';
export const DEACTIVATE_APP_AD_GROUP_FINISHED =
  'DEACTIVATE_APP_AD_GROUP_FINISHED';

export const DEACTIVATE_DASH_AD_GROUP_START = 'DEACTIVATE_DASH_AD_GROUP_START';
export const DEACTIVATE_DASH_AD_GROUP_FINISHED =
  'DEACTIVATE_DASH_AD_GROUP_FINISHED';

export const DEACTIVATE_APP_AD_MEMBERS_START =
  'DEACTIVATE_APP_AD_MEMBERS_START';
export const DEACTIVATE_APP_AD_MEMBERS_FINISHED =
  'DEACTIVATE_APP_AD_MEMBERS_FINISHED';

export const DEACTIVATE_DASH_AD_MEMBERS_START =
  'DEACTIVATE_DASH_AD_MEMBERS_START';
export const DEACTIVATE_DASH_AD_MEMBERS_FINISHED =
  'DEACTIVATE_DASH_AD_MEMBERS_FINISHED';

export const SIGNIN_START = 'SIGNIN_START';
export const SIGNIN_FINISHED = 'SIGNIN_FINISHED';

export const GET_USERS_START = 'GET_USERS_START';
export const GET_USERS_FINISHED = 'GET_USERS_FINISHED';

export const GET_SBG_USERS_START = 'GET_SBG_USERS_START';
export const GET_SBG_USERS_FINISHED = 'GET_SBG_USERS_FINISHED';

export const GET_SBU_USERS_START = 'GET_SBU_USERS_START';
export const GET_SBU_USERS_FINISHED = 'GET_SBU_USERS_FINISHED';

export const GET_BU_USERS_START = 'GET_BU_USERS_START';
export const GET_BU_USERS_FINISHED = 'GET_BU_USERS_FINISHED';

export const GET_PROJECT_USERS_START = 'GET_PROJECT_USERS_START';
export const GET_PROJECT_USERS_FINISHED = 'GET_PROJECT_USERS_FINISHED';

export const GET_SBG_DASH_USERS_START = 'GET_SBG_DASH_USERS_START';
export const GET_SBG_DASH_USERS_FINISHED = 'GET_SBG_DASH_USERS_FINISHED';

export const GET_SBU_DASH_USERS_START = 'GET_SBU_DASH_USERS_START';
export const GET_SBU_DASH_USERS_FINISHED = 'GET_SBU_DASH_USERS_FINISHED';

export const GET_BU_DASH_USERS_START = 'GET_BU_DASH_USERS_START';
export const GET_BU_DASH_USERS_FINISHED = 'GET_BU_DASH_USERS_FINISHED';

export const GET_PROJECT_DASH_USERS_START = 'GET_PROJECT_DASH_USERS_START';
export const GET_PROJECT_DASH_USERS_FINISHED =
  'GET_PROJECT_DASH_USERS_FINISHED';

export const CHANGE_ENTITY = 'CHANGE_ENTITY';

export const GET_DASHBOARDS_LIST_START = 'GET_DASHBOARDS_LIST_START';
export const GET_DASHBOARDS_LIST_FINISHED = 'GET_DASHBOARDS_LIST_FINISHED';

export const GET_DASHBOARD_CATEGORIES_START = 'GET_DASHBOARD_CATEGORIES_START';
export const GET_DASHBOARD_CATEGORIES_FINISHED =
  'GET_DASHBOARD_CATEGORIES_FINISHED';

export const GET_DASHBOARD_MY_REQUEST_STATUSES_START =
  'GET_DASHBOARD_MY_REQUEST_STATUSES_START';
export const GET_DASHBOARD_MY_REQUEST_STATUSES_FINISHED =
  'GET_DASHBOARD_MY_REQUEST_STATUSES_FINISHED';

export const GET_DASHBOARD_USER_REQUEST_STATUSES_START =
  'GET_DASHBOARD_USER_REQUEST_STATUSES_START';
export const GET_DASHBOARD_USER_REQUEST_STATUSES_FINISHED =
  'GET_DASHBOARD_USER_REQUEST_STATUSES_FINISHED';

export const FETCH_DASHBOARD_MY_REQUESTS_START =
  'FETCH_DASHBOARD_MY_REQUESTS_START';
export const FETCH_DASHBOARD_MY_REQUESTS_FINISHED =
  'FETCH_DASHBOARD_MY_REQUESTS_FINISHED';

export const FETCH_DASHBOARD_USER_REQUESTS_START =
  'FETCH_DASHBOARD_USER_REQUESTS_START';
export const FETCH_DASHBOARD_USER_REQUESTS_FINISHED =
  'FETCH_DASHBOARD_USER_REQUESTS_FINISHED';

export const DASHBOARD_REQUEST_ACCESS_START = 'DASHBOARD_REQUEST_ACCESS_START';
export const DASHBOARD_REQUEST_ACCESS_FINISHED =
  'DASHBOARD_REQUEST_ACCESS_FINISHED';

export const GET_DASHBOARD_BY_ID_START = 'GET_DASHBOARD_BY_ID_START';
export const GET_DASHBOARD_BY_ID_FINISHED = 'GET_DASHBOARD_BY_ID_FINISHED';

export const GET_DASHBOARD_CAT_OPTIONS_START =
  'GET_DASHBOARD_CAT_OPTIONS_START';
export const GET_DASHBOARD_CAT_OPTIONS_FINISHED =
  'GET_DASHBOARD_CAT_OPTIONS_FINISHED';

export const ADD_DASHBOARD_START = 'ADD_DASHBOARD_START';
export const ADD_DASHBOARD_FINISHED = 'ADD_DASHBOARD_FINISHED';

export const GET_REFRESH_CYCLE_OPTIONS_START =
  'GET_REFRESH_CYCLE_OPTIONS_START';
export const GET_REFRESH_CYCLE_OPTIONS_FINISHED =
  'GET_REFRESH_CYCLE_OPTIONS_FINISHED';

export const REQUEST_DASHBOARD_ACCESS_START = 'REQUEST_DASHBOARD_ACCESS_START';
export const REQUEST_DASHBOARD_ACCESS_FINISHED =
  'REQUEST_DASHBOARD_ACCESS_FINISHED';

export const GET_APP_USERS_START = 'GET_APP_USERS_START';
export const GET_APP_USERS_FINISHED = 'GET_APP_USERS_FINISHED';

export const GET_CUSTOM_APP_AD_GROUP_START = 'GET_CUSTOM_APP_AD_GROUP_START';
export const GET_CUSTOM_APP_AD_GROUP_FINISHED =
  'GET_CUSTOM_APP_AD_GROUP_FINISHED';

export const GET_APP_AD_GROUP_START = 'GET_APP_AD_GROUP_START';
export const GET_APP_AD_GROUP_FINISHED = 'GET_APP_AD_GROUP_FINISHED';

export const GET_DASH_AD_GROUP_START = 'GET_DASH_AD_GROUP_START';
export const GET_DASH_AD_GROUP_FINISHED = 'GET_DASH_AD_GROUP_FINISHED';

export const GET_CUSTOM_DASH_AD_GROUP_START = 'GET_CUSTOM_DASH_AD_GROUP_START';
export const GET_CUSTOM_DASH_AD_GROUP_FINISHED =
  'GET_CUSTOM_DASH_AD_GROUP_FINISHED';

export const GET_APP_OWNERS_START = 'GET_APP_OWNERS_START';
export const GET_APP_OWNERS_FINISHED = 'GET_APP_OWNERS_FINISHED';

export const GET_DASH_USERS_START = 'GET_DASH_USERS_START';
export const GET_DASH_USERS_FINISHED = 'GET_DASH_USERS_FINISHED';

export const GET_DASH_OWNERS_START = 'GET_DASH_OWNERS_START';
export const GET_DASH_OWNERS_FINISHED = 'GET_DASH_OWNERS_FINISHED';
