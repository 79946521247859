import { Button, Card, Carousel } from 'antd';
import React, { useEffect, useState } from 'react';
import './welcome.scss';
import AppIcon from '../../assets/apps-icon.png';
import DashboardIcon from '../../assets/dashboards-icon.png';
import UsersImg from '../../assets/users.png';
import DashboardsImg from '../../assets/dashboards.png';
import AppsImg from '../../assets/applications.png';
import { useNavigate } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { CHANGE_ENTITY } from '../../redux/constants/types';
import appActions from '../../redux/actions/actions';
import moment from 'moment';
import { BarChartOutlined } from '@ant-design/icons';

const WelcomePage = () => {
  const currentUser = JSON.parse(localStorage.getItem('user_details') || '{}');

  const [welcomeInfo, setWelcomeInfo] = useState({});

  useEffect(() => {
    dispatch({ type: CHANGE_ENTITY, entity: 'welcome' });
    getWelcomeData();
  }, []);

  const getWelcomeData = () => {
    console.log('welcome data');
    appActions
      .getWelcomeInfo()
      .then((res) => {
        console.log('resp', res);
        setWelcomeInfo(res.data);
      })
      .catch((err) => {
        console.log('error', err);
        setWelcomeInfo({});
      });
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const goToApps = () => {
    dispatch({ type: CHANGE_ENTITY, entity: 'app' });
    navigate('/home');
  };

  const goToDashboards = () => {
    dispatch({ type: CHANGE_ENTITY, entity: 'dashboard' });
    navigate('/dashboards');
  };
  const goToSarovar = () => {
    navigate('/sarovar', {});
    dispatch({ type: CHANGE_ENTITY, entity: 'sarovar' });
  };

  const isAdmin = currentUser?.role === 'admin';

  return (
    <div className="welcome-container">
      <div className="welcome-banner">
        <Carousel
          dots={false}
          className="welcome-carousel"
          autoplay
          autoplaySpeed={5000}
        >
          <div>
            <div className="welcome-banner-title">
              Hello {currentUser?.fullname}! Welcome to the DX store
            </div>
            <div className="welcome-banner-desc mt-4 mb-3">
              Discover enterprise apps and dashboards and access them—anytime,
              anywhere
            </div>
            <div className="buttons-container mt-3">
              <Button className="bg-white" onClick={goToApps}>
                <img src={AppIcon} alt="" />
                Apps
              </Button>
              <Button className="bg-highlighted ms-3" onClick={goToDashboards}>
                <img src={DashboardIcon} alt="" /> Analytics & Insights
              </Button>
              <Button className="bg-sarovar ms-3" onClick={goToSarovar}>
                <BarChartOutlined style={{ fontSize: '25px' }} />
                Sarovar
              </Button>
            </div>
          </div>
          <div className="carousel-container">
            <div className="carousel-title">Project NEEV Microsite</div>
            <div className="carousel-desc">
              Learn more about the Project NEEV and important details on the SAP
              implementation
            </div>
            <Button
              type="primary"
              className="submit-btn checkout-btn"
              href={'https://neev.tataprojects.com'}
              target="_blank"
            >
              Visit Now
            </Button>
          </div>
        </Carousel>
      </div>
      <div className="welcome-stats d-flex">
        <div className="left">
          <Card className="updates-card">
            <div className="updates-card-header">
              <div className="heading">
                IT & Digital Updates{' '}
                {isAdmin && <Button href="/announcements">Manage</Button>}
              </div>
              <div className="desc">
                Keep yourself updated on the latest IT & Digital developments
              </div>
            </div>
            <div className="updates-container">
              {welcomeInfo.anouncement_info?.data &&
                welcomeInfo.anouncement_info?.data.length > 0 &&
                welcomeInfo.anouncement_info?.data.map((itm) => (
                  <div className="updates-item">
                    <div className="" style={{ flex: 1 }}>
                      <div className="heading">{itm.title}</div>
                      <div className="desc">{itm.description}</div>
                      <div className="date">
                        {moment(itm.anouncement_date).format('LL')}
                      </div>
                    </div>
                    <div className="d-flex align-items-center arrow-bg">
                      <a href={itm.link} target="_blank" rel="noreferrer">
                        <ArrowRightOutlined />
                      </a>
                    </div>
                  </div>
                ))}
            </div>
          </Card>
        </div>
        <div className="right">
          <Card className="counts-card">
            <div className="counts-item">
              <img src={AppsImg} alt="" />
              <div className="counts-label">Applications</div>
              <div className="counts-label ms-auto">
                {welcomeInfo?.apps_count || 'NA'}
              </div>
            </div>
            <div className="counts-item">
              <img src={DashboardsImg} alt="" />
              <div className="counts-label">Dashboards</div>
              <div className="counts-label ms-auto">
                {welcomeInfo?.dashboards_count || 'NA'}
              </div>
            </div>
            <div className="counts-item">
              <img src={UsersImg} alt="" />
              <div className="counts-label">Users</div>
              <div className="counts-label ms-auto">
                {welcomeInfo?.users_count || 'NA'}
              </div>
            </div>
          </Card>
          <Card className="upcoming-card">
            <div className="upcoming-header">Upcoming Apps/Analytics</div>
            <div className="upcoming-container">
              {welcomeInfo?.upcoming_apps &&
                welcomeInfo?.upcoming_apps.length > 0 &&
                welcomeInfo?.upcoming_apps.map((itm) => (
                  <div className="d-flex upcoming-item">
                    <div className="app-icon">
                      <img
                        src={itm?.profile_images[0]?.doc_url}
                        style={{ width: '30px', height: '30px' }}
                        alt=""
                      />
                    </div>
                    <div className="ms-3">
                      <div className="title">{itm.app_name}</div>
                      <div className="desc">{itm.app_description}</div>
                    </div>
                  </div>
                ))}
              {welcomeInfo?.upcoming_dashboards &&
                welcomeInfo?.upcoming_dashboards.length > 0 &&
                welcomeInfo?.upcoming_dashboards.map((itm) => (
                  <div className="d-flex upcoming-item">
                    <div className="app-icon">
                      <img
                        src={itm?.profile_images[0]?.doc_url}
                        style={{ width: '30px', height: '30px' }}
                        alt=""
                      />
                    </div>
                    <div className="ms-3">
                      <div className="title">{itm.dash_name}</div>
                      <div className="desc">{itm.dash_description}</div>
                    </div>
                  </div>
                ))}
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
