import React from 'react';
import { Timeline, Pagination, Empty } from 'antd';
import './timelineUpdatesApp.css';

const TimelineUpdatesApp = ({ updatesList, page, setPage }) => {
  console.log('updatesList:', updatesList);
  return (
    <div>
      {updatesList?.data?.pagination?.total_records === 0 ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <>
          <Timeline>
            {updatesList?.data?.data?.map((update, index) => (
              <Timeline.Item key={index}>
                <div>
                  <div>
                    <span className="timeline-title">Release Notes:</span>
                    {update?.release_notes}
                  </div>
                  <div>
                    <span className="timeline-title">Posted Date:</span>{' '}
                    {update?.posted_date}
                  </div>
                  {/*  <div>
                    <span className="timeline-title">Expiry Date:</span>{' '}
                    {update?.expiry_date}
                  </div> */}
                </div>
              </Timeline.Item>
            ))}
          </Timeline>
          <div className="pagination">
            <Pagination
              total={updatesList?.data?.pagination?.total_records}
              defaultCurrent={1}
              pageSize={10}
              current={page}
              onChange={(currentPage) => {
                setPage(currentPage);
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};
export default TimelineUpdatesApp;
