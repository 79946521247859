import {
  ArrowLeftOutlined,
  FileTextOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { Button, Collapse, Image, message, Modal, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import { useDispatch, useSelector } from 'react-redux';
import appActions from '../../redux/actions/actions';
import { UPDATE_SHOW_BACKGROUND } from '../../redux/constants/types';
import './app-detail.css';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';
import { useNavigate, useParams } from 'react-router-dom';
import { parseYoutubeUrl } from '../../helpers/util';
import Rating from 'react-rating';
import TextArea from 'antd/lib/input/TextArea';
import RequestAccess from '../RequestAccess';
import StreamPlayer from '../../components/StreamPlayer';
import ReactPlayer from 'react-player';
import TimelineUpdatesApp from './TimelineUpdatesApp';
import ImageComponent from '../../components/Base64/ImageComponent';

const AppDetail = () => {
  const currentApp =
    useSelector((state) => {
      return state.app.currentApp.data;
    }) || [];

  const { appId } = useParams();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const entity = useSelector((state) => {
    return state.root.entity;
  });

  const updatesList = useSelector((state) => {
    return state[entity].updatesList;
  });
  //  console.log('appId:', appId);
  //  console.log('updatesList:', updatesList);
  //  console.log('currentApp:', currentApp);
  //  console.log('entity:', entity);

  const [showFeedback, setShowFeedback] = useState(false);
  const [feedbackComment, setFeedbackComment] = useState('');
  const [userRating, setUserRating] = useState(0);
  const [showTrialModal, setShowTrialModal] = useState({});

  const [showRequestAccess, setShowRequestAccess] = useState({
    visible: false,
  });

  const [showReviseAccess, setShowReviseAccess] = useState({
    visible: false,
  });
  const [page, setPage] = useState(1);

  const currentUser = JSON.parse(localStorage.getItem('user_details') || '{}');

  useEffect(() => {
    dispatch(appActions.getAppById({ appId, userId: currentUser.id }));
    dispatch({ type: UPDATE_SHOW_BACKGROUND, show: false });
  }, []);

  const currentAppDetails = currentApp?.data || {};

  useEffect(() => {
    if (currentAppDetails?.app_name) {
      document.title = `DX Store - ${currentAppDetails?.app_name}`;
    }
  }, [currentAppDetails]);

  useEffect(() => {
    handlerGetUpdatesList();
  }, [page]);

  const handlerGetUpdatesList = () => {
    const payloadApp = {
      page,
      per_page: 10,
      app_id: appId,
    };
    if (entity === 'app') {
      dispatch(appActions.getUpdatesList(payloadApp));
    }
  };

  // const appImages = currentAppDetails?.Documents?.filter(
  //   (itm) => itm.image_use === 'app_images'
  // );
  const appImages =
    currentAppDetails?.app_images?.map((itm) => ({
      image_url: itm?.image_url,
    })) || [];

  // const appProfileImg =
  //   currentAppDetails?.Documents?.filter(
  //     (itm) => itm.image_use === 'profile'
  //   ) || [];

  const appProfileImg =
    currentAppDetails?.profile_images?.map((itm) => ({
      image_url: itm?.image_url,
    })) || [];

  const userManual = currentAppDetails?.Documents?.filter(
    (itm) => itm.image_use === 'user_manual'
  );

  const handleFeedbackChange = (e) => {
    setFeedbackComment(e.target.value);
  };

  const feedbackCallback = (success) => {
    if (success) {
      setShowFeedback(false);
      setUserRating(null);
      setFeedbackComment('');
      message.success(
        'Perfect! Your feedback has been submitted successfully.'
      );
      dispatch(appActions.getAppById({ appId, userId: currentUser.id }));
    } else {
      message.error('Something went wrong! We are looking into it.');
    }
  };

  const submitFeedback = () => {
    const payload = {
      app_id: currentAppDetails.id,
      rating: userRating,
      comment: feedbackComment,
    };
    dispatch(appActions.submitFeedback(payload, feedbackCallback));
  };

  const handleRatingChange = (val) => {
    setUserRating(val);
  };

  const prependHttp = (url) => {
    let newUrl = url;
    if (!/^https?:\/\//i.test(url)) {
      newUrl = 'http://' + url;
    }
    return newUrl;
  };

  const requestAccess = (app) => {
    // navigate('/request-access/' + app.id + '/' + app.app_name);
    setShowRequestAccess({ visible: true });
  };

  const cancelRequestModal = () => {
    setShowRequestAccess({ visible: false, selectedApp: null });
  };

  const requestSuccess = () => {
    setShowRequestAccess({ visible: false, selectedApp: null });
    // dispatch({ type: UPDATE_SHOW_BACKGROUND, show: false });
  };

  const cancelRequestAccess = () => {
    setShowRequestAccess({ visible: false, selectedApp: null });
  };

  const reviseAccess = (app) => {
    setShowReviseAccess({ visible: true });
  };

  const cancelReviseModal = () => {
    setShowReviseAccess({ visible: false, selectedApp: null });
  };

  const reviseSuccess = () => {
    setShowReviseAccess({ visible: false, selectedApp: null });
    // dispatch({ type: UPDATE_SHOW_BACKGROUND, show: false });
  };

  const cancelReviseAccess = () => {
    setShowReviseAccess({ visible: false, selectedApp: null });
  };

  const createSecretAndNavigate = () => {
    const payload = {
      user_id: currentUser.id,
      app_id: currentAppDetails.id,
    };
    appActions
      .stagingSecret(payload)
      .then((res) => {
        console.log('secret resp', res);
        if (res.data.secret) {
          console.log(
            currentAppDetails.staging_url + '/login?secret=' + res.data.secret
          );
          window.open(
            currentAppDetails.staging_url + '/login?secret=' + res.data.secret
          );
        }
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const goToStaging = () => {
    console.log(currentAppDetails);
    const payload = {
      user_id: currentUser.id,
      app_id: currentAppDetails.id.toString(),
    };
    appActions
      .makeApiCall('post', `/trial/check_trial`, payload)
      .then((res) => {
        console.log('trial resp', res.data);
        if (res.data.trialEnabled) {
          setShowTrialModal({ visible: true, data: { isExhausted: false } });
        } else {
          setShowTrialModal({ visible: true, data: { isExhausted: true } });
        }
      })
      .catch((err) => {
        console.log('error', err);
        message.error('Something went wrong! We are looking into it.');
      });
  };

  const proceedToStaging = () => {
    console.log('proceed to staging');
    setShowTrialModal({});
    if (currentAppDetails?.is_launch_restricted) {
      createSecretAndNavigate();
    } else {
      window.open(currentAppDetails.staging_url);
    }
  };

  const launchApp = () => {
    //console.log('@@@@@@@@', currentAppDetails);

    var window_reference = window.open();
    // alert('1');
    /*   if (currentAppDetails?.is_launch_restricted) { */

    // alert('2');
    const payload = {
      user_id: currentUser.id,
      app_id: currentAppDetails.id,
    };
    appActions
      .makeApiCall('post', `/secret/create_secret`, payload)
      .then((res) => {
        // alert('3');
        console.log('secret resp', res);
        if (res.data.secret) {
          // alert('4');
          console.log(
            currentAppDetails.app_url + '/login?secret=' + res.data.secret
          );
          // setTimeout(() => {
          //   window.open(
          //     currentAppDetails.app_url + '/login?secret=' + res.data.secret
          //   );
          // }, 0);
          window_reference.location =
            currentAppDetails.app_url + '/login?secret=' + res.data.secret;
        }
      })
      .catch((err) => {
        // alert('5');
        console.log('error', err);
      });
    // } else {
    //   // alert('6');

    //  // window?.open(currentAppDetails?.app_url);

    // }
  };
  return (
    <div className="app-detail-container">
      <div className="info-container">
        <div className="app-back-button" onClick={() => navigate('/home')}>
          <ArrowLeftOutlined />{' '}
          <span className="back-text">Go to home page</span>
        </div>
        <div className="d-flex flex-wrap">
          <div className="app-info">
            <div className="app-detail-title d-flex">
              <span>
                <img
                  className="profile-img"
                  src={appProfileImg[0]?.image_url}
                  style={{ width: '30px', height: '30px' }}
                  alt=""
                />
                {/* <ImageComponent
                  base64Image={appProfileImg[0]}
                  width={30}
                  height={30}
                  marginRight={10}
                /> */}
              </span>
              <span>{currentAppDetails.app_name}</span>
            </div>
            <div className="app-desc">{currentAppDetails.app_description}</div>
            <div className="reviews-section">
              <div>
                <Rating
                  readonly
                  placeholderRating={currentAppDetails?.ratingAvg || 0}
                  emptySymbol={
                    <img src="../../star_grey.png" className="icon" alt="" />
                  }
                  placeholderSymbol={
                    <img src="../../star_gold.png" className="icon" alt="" />
                  }
                  fullSymbol={
                    <img src="../../star_gold.png" className="icon" alt="" />
                  }
                />
              </div>
              <div className="review-counts">
                {`${currentAppDetails?.totalReview || 0} Reviews`}
              </div>
            </div>
            <div className="app-detail-buttons">
              {currentAppDetails?.is_accessible &&
              currentAppDetails?.is_launch_restricted === true ? (
                <Button
                  type="primary"
                  className="submit-btn"
                  // href={prependHttp(currentAppDetails.app_url)}
                  onClick={launchApp}
                  target="_blank"
                >
                  Launch App
                </Button>
              ) : currentAppDetails?.is_accessible &&
                currentAppDetails?.is_launch_restricted === false ? (
                <Button
                  type="primary"
                  className="submit-btn"
                  href={prependHttp(currentAppDetails.app_url)}
                  //onClick={launchApp}
                  target="_blank"
                >
                  Launch App
                </Button>
              ) : (
                <Button
                  type="primary"
                  className="submit-btn"
                  onClick={requestAccess}
                >
                  Request Access
                </Button>
              )}

              <Tooltip
                title={
                  currentAppDetails?.isFeedbackProvided
                    ? 'You have already submitted the feedback'
                    : ''
                }
              >
                <Button
                  onClick={() => setShowFeedback(true)}
                  className="cancel-btn ml-2"
                  disabled={currentAppDetails?.isFeedbackProvided}
                >
                  Give Feedback
                </Button>
              </Tooltip>
              <Button
                className="cancel-btn ml-2"
                target="_blank"
                onClick={goToStaging}
              >
                Experience it
              </Button>
              {/* added is_owner key in getApp api and dashboard_by_id  (to hide button of Revice access) */}
              {/*  (currentAppDetails?.is_accessible &&
                currentAppDetails?.access_levels_enabled === true &&
                currentUser?.role !== 'admin') 
                
                (currentAppDetails?.is_accessible &&
                currentAppDetails?.access_levels_enabled === true &&
                currentAppDetails?.is_owner !== true && currentUser?.role !== 'admin') 
                */}
              {currentUser?.role === 'admin' ? (
                ''
              ) : currentAppDetails?.is_owner === true ? (
                ''
              ) : currentAppDetails?.is_accessible === true &&
                currentAppDetails?.access_levels_enabled === true ? (
                <Button
                  className="cancel-btn ml-2"
                  target="_blank"
                  onClick={reviseAccess}
                >
                  Revise Access
                </Button>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="app-video">
            <div className="app-youtube">
              {/* <LiteYouTubeEmbed
                  aspectHeight={48}
                  aspectWidth={100}
                  id={parseYoutubeUrl(currentAppDetails?.youtube_url)}
                /> */}
              {/* <ReactPlayer
                width="100%"
                height="100%"
                url={
                  currentAppDetails?.youtube_url
                  //`https://player.vimeo.com/video/854285921?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`
                }
                controls={true}
              /> */}
              <StreamPlayer
                url={
                  currentAppDetails?.youtube_url
                  // 'https://web.microsoftstream.com/embed/video/1387e908-7351-44b0-a86b-cbede39551db?autoplay=false&showinfo=true'
                }
              />
              {/* <div style="padding:56.25% 0 0 0;position:relative;">
                <iframe src="https://player.vimeo.com/video/854285921?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" style={{position:"absolute",top:0,left:0,width:"100%",height:"100%"}} title="Compliance App">
                  </iframe>
                  <script src="https://player.vimeo.com/api/player.js"></script>
                  </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="app-gallery">
        <div className="gallery-heading">App Gallery</div>
        <div className="gallery-flex">
          <Image.PreviewGroup>
            {appImages?.map((itm) => (
              <div className="gallery-image">
                <Image
                  src={itm.image_url}
                  style={{ width: '400px', height: '200px' }}
                />
                {/* <ImageComponent
                  base64Image={itm}
                  width={400}
                  height={200}
                  marginRight={0}
                /> */}
              </div>
            ))}
          </Image.PreviewGroup>
        </div>
      </div>
      <div className="app-faqs">
        <Collapse
          bordered={false}
          defaultActiveKey={['0']}
          expandIcon={({ isActive }) =>
            isActive ? <MinusCircleOutlined /> : <PlusCircleOutlined />
          }
        >
          {entity === 'app' && (
            <Collapse.Panel header="Updates" key={'updateHistory'}>
              <TimelineUpdatesApp
                updatesList={updatesList}
                page={page}
                setPage={setPage}
              />
            </Collapse.Panel>
          )}
          <Collapse.Panel header="How to use" key={'how_to_use'}>
            {currentAppDetails.how_to_use}
            {userManual && userManual.length > 0 && (
              <div className="user-manual">
                <FileTextOutlined />
                <Button
                  type="link"
                  target="_blank"
                  //href={`/view-pdf/${appId}?${userManual[0]?.doc_url}`}
                  // href={`https://dx-api.lexyslabs.com/fetch-files/${userManual[0]?.doc_url
                  href={`${
                    process.env.REACT_APP_API_URL2
                  }/fetch-files/${userManual[0]?.doc_url?.split('/').pop()}`}
                >
                  User Manual
                </Button>
              </div>
            )}
          </Collapse.Panel>
          <Collapse.Panel header="Benefits" key={'benefits'}>
            {currentAppDetails.benifits}
          </Collapse.Panel>
          <Collapse.Panel
            header="SME Contact to reach out for More Information"
            key={'sme_contact'}
          >
            {currentAppDetails.sme_contact}
          </Collapse.Panel>
        </Collapse>
      </div>
      {showFeedback && (
        <Modal
          visible={showFeedback}
          onOk={submitFeedback}
          onCancel={() => {
            setShowFeedback(false);
            setUserRating(0);
          }}
        >
          <div className="rating-section">
            <div className="modal-header mb-3">Rate the App</div>
            <Rating
              onChange={handleRatingChange}
              initialRating={userRating}
              emptySymbol={
                <img src="../../star_grey.png" className="icon" alt="" />
              }
              placeholderSymbol={
                <img src="../../star_gold.png" className="icon" alt="" />
              }
              fullSymbol={
                <img src="../../star_gold.png" className="icon" alt="" />
              }
            />
            <div className="mt-2">
              <div>
                <label>Recommendation for improvement</label>
              </div>
              <TextArea className="mt-2" onChange={handleFeedbackChange} />
            </div>
          </div>
        </Modal>
      )}

      {showRequestAccess.visible && (
        <Modal
          width="60%"
          visible={showRequestAccess?.visible}
          onCancel={cancelRequestModal}
          footer={[]}
        >
          <div>
            <RequestAccess
              handleSuccess={requestSuccess}
              fromModal
              app={currentAppDetails}
              handleCancel={cancelRequestAccess}
              Access={'Request'}
            />
          </div>
        </Modal>
      )}
      {showReviseAccess?.visible && (
        <Modal
          width="60%"
          visible={showReviseAccess?.visible}
          onCancel={cancelReviseModal}
          footer={[]}
        >
          <div>
            <RequestAccess
              handleSuccess={reviseSuccess}
              fromModal
              app={currentAppDetails}
              handleCancel={cancelReviseAccess}
              Access={'Revise'}
            />
          </div>
        </Modal>
      )}
      {showTrialModal.visible && (
        <Modal
          visible={showTrialModal.visible}
          okText={showTrialModal.data.isExhausted ? 'Close' : 'Try'}
          cancelText={showTrialModal.data.isExhausted ? <></> : 'Cancel'}
          onOk={
            showTrialModal.data.isExhausted
              ? () => {
                  setShowTrialModal({});
                }
              : proceedToStaging
          }
          onCancel={() => setShowTrialModal({})}
          cancelButtonProps={
            showTrialModal.data.isExhausted
              ? { style: { display: 'none' } }
              : {}
          }
        >
          <div>
            <div className="modal-header mb-3">
              {showTrialModal.data?.isExhausted
                ? 'Trials exhausted'
                : 'Please confirm'}
            </div>
            <div>
              {showTrialModal.data.isExhausted
                ? 'You have already used the app 3 times in the staging environment. Please click on “Launch Now” to start using the latest, stable release of the app. If you do not have access, you can request for it.'
                : 'You will be redirected to the staging site to try the app. An email will be sent to you with login credentials. Please note that you can try the app only three times.'}
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default AppDetail;
