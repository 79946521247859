import {
  GET_DASHBOARDS_LIST_START,
  GET_DASHBOARDS_LIST_FINISHED,
  GET_DASHBOARD_MY_REQUEST_STATUSES_START,
  GET_DASHBOARD_MY_REQUEST_STATUSES_FINISHED,
  GET_USER_REQUEST_STATUSES_START,
  GET_USER_REQUEST_STATUSES_FINISHED,
  DASHBOARD_REQUEST_ACCESS_START,
  DASHBOARD_REQUEST_ACCESS_FINISHED,
  GET_DASHBOARD_BY_ID_START,
  GET_DASHBOARD_BY_ID_FINISHED,
  FETCH_DASHBOARD_MY_REQUESTS_START,
  FETCH_DASHBOARD_MY_REQUESTS_FINISHED,
  FETCH_DASHBOARD_USER_REQUESTS_START,
  FETCH_DASHBOARD_USER_REQUESTS_FINISHED,
  GET_DASHBOARD_CATEGORIES_START,
  GET_DASHBOARD_CATEGORIES_FINISHED,
  GET_DASHBOARD_CAT_OPTIONS_START,
  GET_DASHBOARD_CAT_OPTIONS_FINISHED,
  GET_REFRESH_CYCLE_OPTIONS_START,
  GET_REFRESH_CYCLE_OPTIONS_FINISHED,
  GET_DASHBOARD_USER_REQUEST_STATUSES_START,
  GET_DASHBOARD_USER_REQUEST_STATUSES_FINISHED,
  GET_APP_USERS_START,
  GET_APP_USERS_FINISHED,
  GET_APP_OWNERS_START,
  GET_APP_OWNERS_FINISHED,
  GET_DASH_USERS_START,
  GET_DASH_USERS_FINISHED,
  GET_DASH_OWNERS_START,
  GET_DASH_OWNERS_FINISHED,
  GET_CUSTOM_DASH_AD_GROUP_START,
  GET_CUSTOM_DASH_AD_GROUP_FINISHED,
  CREATE_NEW_DASH_AD_GROUP_START,
  CREATE_NEW_DASH_AD_GROUP_FINISHED,
  DEACTIVATE_DASH_AD_GROUP_START,
  DEACTIVATE_DASH_AD_GROUP_FINISHED,
  AD_DASH_GROUP_ADD_MEMBERS_STATUS_START,
  AD_DASH_GROUP_ADD_MEMBERS_STATUS_FINISHED,
  GET_CUSTOM_DASH_AD_MEMBERS_START,
  GET_CUSTOM_DASH_AD_MEMBERS_FINISHED,
  DEACTIVATE_DASH_AD_MEMBERS_START,
  DEACTIVATE_DASH_AD_MEMBERS_FINISHED,
  GET_SBG_DASH_USERS_START,
  GET_SBG_DASH_USERS_FINISHED,
  GET_SBU_DASH_USERS_START,
  GET_SBU_DASH_USERS_FINISHED,
  GET_BU_DASH_USERS_START,
  GET_BU_DASH_USERS_FINISHED,
  GET_PROJECT_DASH_USERS_START,
  GET_PROJECT_DASH_USERS_FINISHED,
  GET_UPDATES_LIST_DASH_START,
  GET_UPDATES_LIST_DASH_FINISHED,
  IMPORT_BULK_DASH_START,
  IMPORT_BULK_DASH_FINISHED,
  CREATE_EDIT_RELEASE_NOTE_DASH_FINISHED,
  CREATE_EDIT_RELEASE_NOTE_DASH_START,
  GET_DASH_AD_GROUP_START,
  GET_DASH_AD_GROUP_FINISHED,
} from '../constants/types';

const INITIAL_STATE = {
  entityList: {
    loading: false,
    data: [],
  },
  dashADGroups: {
    loading: false,
    data: [],
  },
  createReleaseNote: {
    loading: false,
  },
  updatesList: {
    loading: false,
    data: [],
  },
  importBulkApp: {
    loading: false,
  },
  appSBG_Users: {
    loading: false,
    data: [],
  },
  appSBU_Users: {
    loading: false,
    data: [],
  },
  appBU_Users: {
    loading: false,
    data: [],
  },
  appProject_Users: {
    loading: false,
    data: [],
  },
  customAppADMembersList: {
    loading: false,
    data: [],
  },
  adDashGroupAddMembers: {
    loading: false,
    data: [],
  },
  adDashGroupAddMember: {
    loading: false,
    data: [],
  },
  deactivateDashADGroup: {
    loading: false,
  },
  deactivateAppADMembers: {
    loading: false,
  },
  createNewDashADGroup: {
    loading: false,
  },
  myRequestsList: {
    loading: false,
    data: [],
  },
  categories: {
    loading: false,
    data: [],
  },
  requestAccess: {
    loading: false,
  },
  currentApp: {
    loading: false,
    data: {},
  },
  myRequestStatuses: {
    loading: false,
    data: [],
  },
  userRequestStatuses: {
    loading: false,
    data: [],
  },
  catOptions: {
    loading: false,
    data: [],
  },
  refreshOptions: {
    loading: false,
    data: [],
  },
  appPermittedUsers: {
    loading: false,
    data: [],
  },
  appOwners: {
    loading: false,
    data: [],
  },
  userRequestsList: {
    pending: {
      loading: false,
      data: [],
    },
    accepted: {
      loading: false,
      data: [],
    },
    rejected: {
      loading: false,
      data: [],
    },
  },
};

const dashboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_DASHBOARDS_LIST_START:
      return {
        ...state,
        entityList: { loading: true, data: [] },
      };
    case GET_DASHBOARDS_LIST_FINISHED:
      return {
        ...state,
        entityList: { loading: false, data: action?.data },
      };

    case CREATE_EDIT_RELEASE_NOTE_DASH_START:
      return {
        ...state,
        createReleaseNote: {
          loading: true,
        },
      };

    case CREATE_EDIT_RELEASE_NOTE_DASH_FINISHED:
      return {
        ...state,
        createReleaseNote: {
          loading: false,
        },
      };

    case IMPORT_BULK_DASH_START:
      return {
        ...state,
        importBulkApp: {
          loading: true,
        },
      };

    case IMPORT_BULK_DASH_FINISHED:
      return {
        ...state,
        importBulkApp: {
          loading: false,
        },
      };

    case GET_UPDATES_LIST_DASH_START:
      return {
        ...state,
        updatesList: { loading: true, data: [] },
      };
    case GET_UPDATES_LIST_DASH_FINISHED:
      return {
        ...state,
        updatesList: { loading: false, data: action?.data },
      };
    case GET_DASH_AD_GROUP_START:
      return {
        ...state,
        dashADGroups: {
          loading: true,
        },
      };
    case GET_DASH_AD_GROUP_FINISHED:
      return {
        ...state,
        dashADGroups: {
          loading: false,
          data: action.data,
        },
      };

    case GET_DASHBOARD_MY_REQUEST_STATUSES_START:
      return {
        ...state,
        myRequestStatuses: {
          loading: true,
          data: [],
        },
      };
    case GET_DASHBOARD_MY_REQUEST_STATUSES_FINISHED:
      return {
        ...state,
        myRequestStatuses: {
          loading: false,
          data: action.data,
        },
      };
    case GET_CUSTOM_DASH_AD_GROUP_START:
      return {
        ...state,
        dashCustomAppADGroups: {
          loading: true,
        },
      };
    case GET_CUSTOM_DASH_AD_GROUP_FINISHED:
      return {
        ...state,
        dashCustomAppADGroups: {
          loading: false,
          data: action.data,
        },
      };
    case GET_DASHBOARD_USER_REQUEST_STATUSES_START:
      return {
        ...state,
        userRequestStatuses: {
          loading: true,
          data: {},
        },
      };
    case GET_DASHBOARD_USER_REQUEST_STATUSES_FINISHED:
      return {
        ...state,
        userRequestStatuses: {
          loading: false,
          data: action.data,
        },
      };

    case GET_CUSTOM_DASH_AD_MEMBERS_START:
      return {
        ...state,
        customAppADMembersList: {
          loading: true,
          data: {},
        },
      };
    case GET_CUSTOM_DASH_AD_MEMBERS_FINISHED:
      return {
        ...state,
        customAppADMembersList: {
          loading: false,
          data: action.data,
        },
      };
    case DEACTIVATE_DASH_AD_GROUP_START:
      return {
        ...state,
        deactivateDashADGroup: {
          loading: true,
        },
      };

    case DEACTIVATE_DASH_AD_GROUP_FINISHED:
      return {
        ...state,
        deactivateDashADGroup: {
          loading: false,
        },
      };
    case DEACTIVATE_DASH_AD_MEMBERS_START:
      return {
        ...state,
        deactivateAppADMembers: {
          loading: true,
        },
      };

    case DEACTIVATE_DASH_AD_MEMBERS_FINISHED:
      return {
        ...state,
        deactivateAppADMembers: {
          loading: false,
        },
      };

    case GET_SBG_DASH_USERS_START:
      return {
        ...state,
        appSBG_Users: {
          loading: true,
        },
      };
    case GET_SBG_DASH_USERS_FINISHED:
      return {
        ...state,
        appSBG_Users: {
          loading: false,
          data: action.data,
        },
      };

    case GET_SBU_DASH_USERS_START:
      return {
        ...state,
        appSBU_Users: {
          loading: true,
        },
      };
    case GET_SBU_DASH_USERS_FINISHED:
      return {
        ...state,
        appSBU_Users: {
          loading: false,
          data: action.data,
        },
      };
    case GET_BU_DASH_USERS_START:
      return {
        ...state,
        appBU_Users: {
          loading: true,
        },
      };
    case GET_BU_DASH_USERS_FINISHED:
      return {
        ...state,
        appBU_Users: {
          loading: false,
          data: action.data,
        },
      };
    case GET_PROJECT_DASH_USERS_START:
      return {
        ...state,
        appProject_Users: {
          loading: true,
        },
      };
    case GET_PROJECT_DASH_USERS_FINISHED:
      return {
        ...state,
        appProject_Users: {
          loading: false,
          data: action.data,
        },
      };

    case GET_DASHBOARD_CATEGORIES_START:
      return {
        ...state,
        categories: {
          loading: true,
          data: {},
        },
      };

    case GET_DASHBOARD_CATEGORIES_FINISHED:
      return {
        ...state,
        categories: {
          loading: true,
          data: action.data,
        },
      };

    case DASHBOARD_REQUEST_ACCESS_START:
      return {
        ...state,
        requestAccess: {
          loading: true,
        },
      };

    case DASHBOARD_REQUEST_ACCESS_FINISHED:
      return {
        ...state,
        requestAccess: {
          loading: false,
        },
      };
    case GET_DASHBOARD_BY_ID_START:
      return {
        ...state,
        currentApp: {
          loading: true,
          data: {},
        },
      };

    case GET_DASHBOARD_BY_ID_FINISHED:
      return {
        ...state,
        currentApp: {
          loading: false,
          data: action.data,
        },
      };

    case CREATE_NEW_DASH_AD_GROUP_START:
      return {
        ...state,
        createNewDashADGroup: {
          loading: true,
        },
      };

    case CREATE_NEW_DASH_AD_GROUP_FINISHED:
      return {
        ...state,
        createNewDashADGroup: {
          loading: false,
        },
      };

    case AD_DASH_GROUP_ADD_MEMBERS_STATUS_START:
      return {
        ...state,
        adDashGroupAddMembers: {
          loading: true,
        },
      };

    case AD_DASH_GROUP_ADD_MEMBERS_STATUS_FINISHED:
      return {
        ...state,
        adDashGroupAddMembers: {
          loading: false,
        },
      };

    case FETCH_DASHBOARD_MY_REQUESTS_START:
      return {
        ...state,
        myRequestsList: { loading: true, data: [] },
      };
    case FETCH_DASHBOARD_MY_REQUESTS_FINISHED:
      return {
        ...state,
        myRequestsList: { loading: false, data: action?.data },
      };
    case FETCH_DASHBOARD_USER_REQUESTS_START:
      return {
        ...state,
        userRequestsList: {
          [action.status]: { loading: true, data: [], pagination: {} },
        },
      };
    case FETCH_DASHBOARD_USER_REQUESTS_FINISHED:
      return {
        ...state,
        userRequestsList: {
          [action.status]: {
            loading: false,
            data: action?.data,
            pagination: action?.pagination || {},
          },
        },
      };
    case GET_DASHBOARD_CAT_OPTIONS_START:
      return {
        ...state,
        catOptions: {
          loading: true,
          data: [],
        },
      };
    case GET_DASHBOARD_CAT_OPTIONS_FINISHED:
      return {
        ...state,
        catOptions: {
          loading: false,
          data: action.data,
        },
      };

    case GET_REFRESH_CYCLE_OPTIONS_START:
      return {
        ...state,
        refreshOptions: {
          loading: true,
          data: [],
        },
      };
    case GET_REFRESH_CYCLE_OPTIONS_FINISHED:
      return {
        ...state,
        refreshOptions: {
          loading: false,
          data: action.data,
        },
      };
    case GET_DASH_USERS_START:
      return {
        ...state,
        appPermittedUsers: {
          loading: true,
        },
      };
    case GET_DASH_USERS_FINISHED:
      return {
        ...state,
        appPermittedUsers: {
          loading: false,
          data: action.data,
        },
      };
    case GET_DASH_OWNERS_START:
      return {
        ...state,
        appOwners: {
          loading: true,
        },
      };
    case GET_DASH_OWNERS_FINISHED:
      return {
        ...state,
        appOwners: {
          loading: false,
          data: action.data,
        },
      };
    case 'FETCH_DASHBOARD_USER_RATINGS_START':
      return {
        ...state,
        appsFeedback: {
          loading: true,
          data: [],
          pagination: {},
        },
      };
    case 'FETCH_DASHBOARD_USER_RATINGS_FINISHED':
      return {
        ...state,
        appsFeedback: {
          loading: false,
          data: action?.data,
          pagination: action.pagination || {},
        },
      };
    default:
      return state;
  }
};

export default dashboardReducer;
