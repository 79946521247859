import {
  Button,
  Card,
  message,
  Modal,
  Select,
  Table,
  Tabs,
  Typography,
  Upload,
} from 'antd';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toTitleCase } from '../../components/AppCard';
import AppSearchBar from '../../components/AppSearchbar';
import appActions from '../../redux/actions/actions';
import dashboardActions from '../../redux/actions/dashboardActions';
import {
  CHANGE_ENTITY,
  UPDATE_SHOW_BACKGROUND,
} from '../../redux/constants/types';
import './addapp.css';
import './manageUsers.css';
import ViewAccessLevel from './ViewAccessLevel';
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import sarovarActions from '../../redux/actions/sarovarActions';

const AddUsers = ({ appId, setShowAddUserModal, refreshData }) => {
  const [role, setRole] = useState('user');
  const [selectedCandidates, setSelcetdCandidates] = useState([]);
  const allUsers = useSelector((state) => {
    return state.app.appUsers;
  });

  const dispatch = useDispatch();

  const entity = useSelector((state) => {
    return state.root.entity;
  });

  const isDashboard = entity === 'dashboard';

  const entityActions =
    isDashboard || entity === 'sarovar' ? dashboardActions : appActions;

  const handleNameSearch = useCallback(
    debounce((value) => {
      if (value.length >= 3) {
        dispatch(appActions.getUsers({ search: value }));
      }
    }, 300),
    []
  );

  const onUserSelect = (val) => {
    setSelcetdCandidates(val);
  };

  const addingComplete = (success) => {
    if (success) {
      const entityLabel =
        entity === 'dashboard'
          ? 'Dashboard'
          : entity === 'sarovar'
          ? 'Module'
          : 'App';
      const successMessage =
        role === 'user'
          ? `Perfect! ${entityLabel} User has been added successfully`
          : `Perfect! ${entityLabel} Owner has been added successfully`;
      message.success(successMessage);
      setShowAddUserModal({});
      refreshData();
    } else {
      message.error('Something went wrong! We are looking into it.');
    }
  };

  const addUserToApp = () => {
    const idKey =
      isDashboard || entity === 'sarovar' ? 'dashboard_id' : 'app_id';
    const payload = {
      owners_user_id: selectedCandidates,
      [idKey]: appId,
    };

    if (role === 'owner') {
      payload.role = 'owner';
    }
    dispatch(entityActions.addUsersToApp(payload, addingComplete));
  };
  console.log('allUsers>>', allUsers);
  return (
    <div>
      <div>
        <Typography.Title level={4}>Add User</Typography.Title>
      </div>
      <div>
        <div className="ant-form-item-label">
          <label>User</label>
        </div>
        <Select
          mode="multiple"
          style={{ minWidth: 200 }}
          showSearch
          placeholder="Select a person"
          optionFilterProp="children"
          onChange={onUserSelect}
          onSearch={handleNameSearch}
          value={selectedCandidates}
        >
          {allUsers?.data?.data?.map((usr) => (
            <Select.Option key={usr?.id} val={usr}>{`${usr.first_name} ${
              usr.last_name
            } (${usr.email || ''})`}</Select.Option>
          ))}
        </Select>
      </div>
      <div className="mt-3">
        <div className="ant-form-item-label">
          <label>Role</label>
        </div>
        <Select
          style={{ minWidth: 200 }}
          placeholder="Select role"
          onChange={(val) => {
            setRole(val);
          }}
        >
          <Select.Option value="user">User</Select.Option>
          <Select.Option value="owner">Owner</Select.Option>
        </Select>
      </div>
      <div className="mt-3 w-100 text-right">
        <Button onClick={setShowAddUserModal} className="cancel-btn ml-auto">
          Cancel
        </Button>
        <Button
          type="primary ml-2"
          className="submit-btn"
          onClick={addUserToApp}
        >
          ADD
        </Button>
      </div>
    </div>
  );
};

const UserBulkImport = ({ appId, setShowUserBulkImportModal, refreshData }) => {
  const [role, setRole] = useState('user');
  const [selectedCandidates, setSelcetdCandidates] = useState([]);
  const [quickLook, setQuicklook] = useState(null);
  const [previousQuickLook, setPreviousQuickLook] = useState(null);
  const [importBulkloading, setImportBulkloading] = useState(false);

  const allUsers = useSelector((state) => {
    return state.app.appUsers;
  });

  const dispatch = useDispatch();

  const entity = useSelector((state) => {
    return state.root.entity;
  });

  const isDashboard = entity === 'dashboard';

  const entityActions =
    isDashboard || entity === 'sarovar' ? dashboardActions : appActions;

  const importBulkApp = useSelector((state) => {
    return state.app.importBulkApp;
  });

  console.log('importBulkApploading', importBulkloading);

  // const handleNameSearch = useCallback(
  //   debounce((value) => {
  //     if (value.length >= 3) {
  //       dispatch(appActions.getUsers({ search: value }));
  //     }
  //   }, 300),
  //   []
  // );

  // const onUserSelect = (val) => {
  //   setSelcetdCandidates(val);
  // };
  console.log('appId>>>', appId);

  const BulkImportComplete = (res) => {
    if (res?.status === 200) {
      const entityLabel =
        entity === 'dashboard'
          ? 'Dashboard'
          : entity === 'sarovar'
          ? 'Module'
          : 'App';
      const successMessage =
        role === 'user'
          ? `Perfect! bulk upload has been completed successfully and you can view the users report in downloads`
          : `Perfect! bulk upload has been completed successfully and you can view the users report in downloads`;
      message.success(successMessage);
      setShowUserBulkImportModal({});
      refreshData();
      setImportBulkloading(false);
    } else {
      //console.log('message22222:',   message?.response?.data?.message   )
      //const msg=message?.response?.data?.message;
      //message.error(msg);
      setImportBulkloading(false);
    }
  };

  const UserBulkImport = () => {
    const formData = new FormData();

    //formData.append('app_id', appId);

    if (quickLook && quickLook.length > 0) {
      formData.append('bulk_import_sheet', quickLook[0].originFileObj);
    }
    console.log(formData);

    if (isDashboard || entity === 'sarovar') {
      setImportBulkloading(true);
      dispatch(
        dashboardActions.importBulkDashUsers(
          appId,
          formData,
          BulkImportComplete
        )
      );
    } else {
      setImportBulkloading(true);
      dispatch(
        appActions.importBulkAppUsers(appId, formData, BulkImportComplete)
      );
    }
  };

  // if (quickLook && quickLook.length > 0) {
  //   console.log('quickLook_FILE', quickLook[0].originFileObj);
  // }
  // console.log('quickLook>>', quickLook);

  const deleteQuickLook = (idx) => {
    const tempQuicklook = [...previousQuickLook];
    tempQuicklook[0].deleted = true;
    setPreviousQuickLook(tempQuicklook);
  };
  const downloadTemplate = () => {
    const downloadUrl = '/BULK_USER_IMPORT_TEMPLATE.csv';
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = 'BULK_USER_IMPORT_TEMPLATE.csv'; //'template.xlsx';
    link.click();
  };
  return (
    <div>
      <div>
        <Typography.Title level={4}>User Bulk Import</Typography.Title>
      </div>
      <div className="mb-3">
        <div className="template_bulkUpload" onClick={downloadTemplate}>
          Download template for Bulk Upload
        </div>
      </div>
      <Upload
        onChange={(files) => {
          setQuicklook(files.fileList);
        }}
        fileList={quickLook}
        beforeUpload={(file) => {
          return false;
        }}
        showUploadList
        accept=".csv"
        maxCount={1}
      >
        <Button htmlType="button" type="button" icon={<UploadOutlined />}>
          Upload
        </Button>
      </Upload>

      <div className="previous-files mt-2">
        {previousQuickLook
          ?.filter((itm) => !itm.deleted)
          ?.map((man, idx) => (
            <div className="d-flex w-50 mt-1">
              {/* // id, doc_url */}
              <span style={{ fontSize: '12px' }}>{man?.file_name}</span>
              <span className="ml-auto pointer">
                <DeleteOutlined
                  onClick={() => deleteQuickLook(idx)}
                  style={{ color: 'red' }}
                />
              </span>
            </div>
          ))}
      </div>

      <div className="mt-3 w-100 text-right">
        <Button
          onClick={setShowUserBulkImportModal}
          className="cancel-btn ml-auto"
        >
          Cancel
        </Button>
        <Button
          type="primary ml-2"
          className="submit-btn"
          onClick={UserBulkImport}
          disabled={quickLook?.length === 0 || quickLook === null}
          loading={importBulkloading}
        >
          Upload
        </Button>
      </div>
    </div>
  );
  {
  }
};

const ManageAppUsers = ({ dashboards }) => {
  const dispatch = useDispatch();
  const { appId } = useParams();
  const [currentPage, setcurrentPage] = useState(1);
  const [currentTab, setCurrentTab] = useState('users');

  const [showConfirmationModal, setShowConfirmationModal] = useState({});

  const [showAccessLevelModal, setShowAccessLevelModal] = useState({});

  const [showAddUserModal, setShowAddUserModal] = useState({});
  const [showUserBulkImportModal, setShowUserBulkImportModal] = useState({});

  const entity = useSelector((state) => {
    return state.root.entity;
  });
  const permittedUsers = useSelector((state) => {
    return state[entity].appPermittedUsers;
  });

  const appOwners = useSelector((state) => {
    return state[entity].appOwners;
  });

  const viewAccessLevel = useSelector((state) => {
    return state.app.viewAccessLevel;
  });

  const isDashboard = entity === 'dashboard' || dashboards;

  const entityActions =
    isDashboard || entity === 'sarovar' ? dashboardActions : appActions;

  const entityLabel = isDashboard
    ? 'dashboard'
    : entity === 'sarovar'
    ? 'sarovar'
    : 'app';

  const navigate = useNavigate();

  const [syncProgress, setSyncProgress] = useState(false);

  useEffect(() => {
    // refreshData();
    dispatch({ type: UPDATE_SHOW_BACKGROUND, show: true });
    dispatch(appActions.getUsers());
  }, []);

  console.log('viewAccessLevel', viewAccessLevel);

  // useEffect(() => {
  //   const payload = {
  //     type: isDashboard ? 'dashboard_id' : 'app_id',
  //     app_id: appId,
  //     user_id: '',
  //   };
  //   dispatch(appActions.viewAccessLevels(payload));
  // }, []);

  useEffect(() => {
    refreshData(currentPage);
  }, [currentPage]);

  const fetchEntityUsers = (pageNum = 1, search) => {
    dispatch(
      entityActions.getAppUsers(appId, {
        pageSize: 10,
        page: currentTab === 'users' ? pageNum : 1,
        search,
      })
    );
  };

  const fetchEntityOwners = (pageNum = 1, search) => {
    dispatch(
      entityActions.getAppOwners(appId, {
        pageSize: 10,
        page: currentTab === 'owners' ? pageNum : 1,
        search,
      })
    );
  };

  const refreshData = (pageNum = 1, search) => {
    fetchEntityUsers(pageNum, search);
    fetchEntityOwners(pageNum, search);
  };

  const changeRoleComplete = (success) => {
    if (success) {
      message.success('Role updated successfully!');
      refreshData(currentPage);
    } else {
      message.error('Could not update the role');
    }
  };

  const changeRole = (user, role) => {
    const idKey =
      isDashboard || entity === 'sarovar' ? 'dashboard_id' : 'app_id';
    const payload = {
      owners_user_id: [user.id],
      [idKey]: appId,
      role: role,
    };
    dispatch(entityActions.addUsersToApp(payload, changeRoleComplete));
  };

  const goBack = () => {
    navigate(-1);
  };

  const deactivateClick = (user, type) => {
    setShowConfirmationModal({
      type: 'deactivate',
      user: user,
      visible: true,
      role: type,
    });
  };

  const accessLevelClick = (user, type) => {
    //console.log('USER:', user);
    const payload = {
      type: isDashboard || entity === 'sarovar' ? 'dashboard_id' : 'app_id',
      app_id: appId,
      user_id: user?.id,
    };
    dispatch(appActions.viewAccessLevels(payload));

    setShowAccessLevelModal({
      type: 'deactivate',
      user: user,
      visible: true,
      role: type,
    });
  };

  const roleColumns = (type) => [
    {
      title: 'Full Name',
      dataIndex: 'first_name',
      key: 'first_name',
      ellipsis: {
        showTitle: true,
      },
      render: (val, record) => (
        <span>{`${record.first_name} ${record.last_name || ''}`}</span>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ellipsis: {
        showTitle: true,
      },
      render: (val, record) => <span>{record.email}</span>,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      width: '80px',
      render: (val, record) => <span>{toTitleCase(type)}</span>,
    },
    {
      title: 'Action',
      dataIndex: 'access',
      key: 'access',
      width: '350px',
      render: (val, record) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            size="small"
            danger
            onClick={() => deactivateClick(record, type)}
          >
            Revoke Access
          </Button>
          <Button
            className="ml-2 cancel-btn"
            size="small"
            onClick={() =>
              changeRole(record, type === 'user' ? 'owner' : 'user')
            }
          >
            {type === 'user' ? 'Change to Owner' : 'Change to User'}
          </Button>
          {type === 'user' ? (
            <Button
              size="small"
              className="accessLevel_style"
              onClick={() => accessLevelClick(record, type)}
            >
              View Access Level
            </Button>
          ) : (
            ''
          )}
        </div>
      ),
    },
  ];

  const handleAddUsers = () => {
    setShowAddUserModal({ visible: true });
  };

  const handleUserBulkImport = () => {
    setShowUserBulkImportModal({ visible: true });
  };

  const deactivateComplete = (success) => {
    if (success) {
      const entityLabel =
        entity === 'dashboard'
          ? 'Dashboard'
          : entity === 'sarovar'
          ? 'Module'
          : 'App';
      const successMessage =
        currentTab === 'users'
          ? `Alright! ${entityLabel} User has been deactivated successfully`
          : `Alright! ${entityLabel} Owner has been deactivated successfully`;
      message.success(successMessage);
      setShowConfirmationModal({});
      refreshData();
    } else {
      message.error('Something went wrong. We are looking into it');
    }
  };

  const deactivateUser = () => {
    const idKey =
      isDashboard || entity === 'sarovar' ? 'dashboard_id' : 'app_id';
    dispatch(
      entityActions.deactivateUSerFromApp(
        {
          [idKey]: appId,
          user_id: showConfirmationModal.user.id,
          permission_type: showConfirmationModal.role,
        },
        deactivateComplete
      )
    );
  };

  const searchUsers = (txt) => {
    if (currentTab === 'users') {
      fetchEntityUsers(1, txt);
    } else {
      fetchEntityOwners(1, txt);
    }
  };

  const handleSearchChange = debounce((e) => {
    searchUsers(e.target.value);
  }, 300);

  const syncCallback = (success) => {
    setSyncProgress(false);
    if (success) {
      message.success('Synced users successfully!');
      refreshData();
    } else {
      message.error('Something went wrong, we are looking into it!');
    }
  };

  const syncUsers = () => {
    // https://dx-api.lexyslabs.com/api/v1/admin/sync-azure-group-members?app_id=1
    setSyncProgress(true);
    dispatch(entityActions.syncAdUsers(appId, syncCallback));
  };

  return (
    <div className="manage-users-container">
      <div className="app-back-button" onClick={goBack}>
        <ArrowLeftOutlined /> <span className="back-text">Go to home page</span>
      </div>
      <Card className="manage-users-card">
        <div className="d-flex w-100 align-items-center">
          <Typography.Title level={4} className="mb-0">
            Manage Users
          </Typography.Title>

          <Button
            type="primary"
            className="ml-auto submit-btn"
            onClick={syncUsers}
            disabled={syncProgress}
          >
            Sync Users
          </Button>
          <Button
            type="primary"
            className="ml-2 submit-btn"
            onClick={handleAddUsers}
          >
            Add Users
          </Button>
          {dashboards === 'sarovar' ? (
            ''
          ) : (
            <Button
              type="primary"
              className="ml-2 submit-btn"
              onClick={handleUserBulkImport}
            >
              User Bulk Import
            </Button>
          )}
        </div>
        <div style={{ width: 900 }} className="mt-2 mb-2">
          <AppSearchBar
            handleChange={handleSearchChange}
            placeholder="Search by email or name"
          />
        </div>
        <div className="manage-users-tab">
          <Tabs
            onChange={(activeKey) => {
              setcurrentPage(1);
              setCurrentTab(activeKey);
            }}
          >
            <Tabs.TabPane
              tab={`Users ${
                permittedUsers.data?.pagination
                  ? `(${permittedUsers.data.pagination.total_records})`
                  : ''
              }`}
              key="users"
            >
              <Table
                pagination={{
                  pageSize: 10,
                  onChange: (current, size) => {
                    setcurrentPage(current);
                  },
                  current: currentPage,
                  total: permittedUsers.data?.pagination?.total_records,
                }}
                columns={roleColumns('user')}
                dataSource={permittedUsers.data?.data || []}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={`Owners ${
                appOwners.data?.pagination
                  ? `(${appOwners.data.pagination.total_records})`
                  : ''
              }`}
              key="owners"
            >
              <Table
                pagination={{
                  pageSize: 10,
                  onChange: (current, size) => {
                    setcurrentPage(current);
                  },
                  current: currentPage,
                  total: appOwners.data?.pagination?.total_records,
                }}
                columns={roleColumns('owner')}
                dataSource={appOwners.data?.data || []}
              />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </Card>
      {showAddUserModal.visible && (
        <Modal
          visible={showAddUserModal.visible}
          footer={[]}
          onCancel={() => setShowAddUserModal({})}
        >
          <AddUsers
            refreshData={refreshData}
            appId={appId}
            setShowAddUserModal={setShowAddUserModal}
          />
        </Modal>
      )}
      {showUserBulkImportModal.visible && (
        <Modal
          visible={showUserBulkImportModal.visible}
          footer={[]}
          onCancel={() => setShowUserBulkImportModal({})}
        >
          <UserBulkImport
            refreshData={refreshData}
            appId={appId}
            setShowUserBulkImportModal={setShowUserBulkImportModal}
          />
        </Modal>
      )}
      {showConfirmationModal.visible && (
        <Modal
          title="Revoke Access"
          visible={showConfirmationModal.visible}
          onCancel={() => setShowConfirmationModal({})}
          onOk={() => deactivateUser()}
          okText="Revoke"
        >
          <div>
            This will remove access to
            <strong>{` ${showConfirmationModal.user.first_name} ${
              showConfirmationModal.user.last_name || ''
            } `}</strong>
            for this {entityLabel}. Continue?
          </div>
        </Modal>
      )}
      {showAccessLevelModal.visible && (
        <Modal
          width="60%"
          title="View Access Level"
          visible={showAccessLevelModal.visible}
          onCancel={() => setShowAccessLevelModal({})}
          onOk={() => setShowAccessLevelModal({})}
          okText="Ok"
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          <div>
            <ViewAccessLevel
              data={
                viewAccessLevel?.data?.data
                // isDashboards
                //   ? showAcceptModal?.data?.dash_access_levels_details
                //   : showAcceptModal?.data?.app_access_levels_details
              }
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ManageAppUsers;
