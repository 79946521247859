import React, { useEffect, useState } from 'react';
const PdfComponent = ({ pdf_file }) => {
  const [base64Pdf, setBase64Pdf] = useState('');

  console.log('inside_PdfComponent', pdf_file);
  //`https://dx-api.lexyslabs.com/get-base64-document/${pdf_file}`
  const apiUrl = process.env.REACT_APP_API_URL2;
  console.log('apiUrl>>', `${apiUrl}/get-base64-document/${pdf_file}`);
  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/get-base64-document/${pdf_file}`
        );

        const data = await response.json(); // Assuming the response is JSON
        setBase64Pdf(data?.data); // Adjust according to the response structure
      } catch (error) {
        console.error('Error fetching PDF:', error);
      }
    };

    fetchPdf();
  }, []);

  return (
    <div>
      {base64Pdf ? (
        <iframe
          title="pdfViewer"
          width="100%"
          height="600px"
          src={`data:application/pdf;base64,${base64Pdf}`}
          frameBorder="0"
        />
      ) : (
        <p>Loading PDF...</p>
      )}
    </div>
  );
};
export default PdfComponent;

/* With TOKEN */
// import React, { useState, useEffect } from 'react';
// const PdfComponent = ({ pdf_file }) => {
//   const [base64Pdf, setBase64Pdf] = useState('');
//   const apiUrl = process.env.REACT_APP_API_URL2;

//   // Assuming the token is stored in localStorage, adjust this according to your setup
//   const authToken = localStorage.getItem('access_token');
//   console.log('authToken', authToken);

//   useEffect(() => {
//     const fetchPdf = async () => {
//       try {
//         const response = await fetch(`${apiUrl}/get-base64-document/${pdf_file}`, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer ${authToken}`, // Add the token here
//           },
//         });

//         if (!response.ok) {
//           throw new Error(`HTTP error! Status: ${response.status}`);
//         }

//         const data = await response.json(); // Assuming the response is JSON
//         setBase64Pdf(data?.data); // Adjust according to the response structure
//       } catch (error) {
//         console.error('Error fetching PDF:', error);
//       }
//     };

//     fetchPdf();
//   }, [apiUrl, pdf_file, authToken]); // Add dependencies to useEffect

//   return (
//     <div>
//       {base64Pdf ? (
//         <iframe
//           title="pdfViewer"
//           width="100%"
//           height="600px"
//           src={`data:application/pdf;base64,${base64Pdf}`}
//           frameBorder="0"
//         />
//       ) : (
//         <p>Loading PDF...</p>
//       )}
//     </div>
//   );
// };

// export default PdfComponent;
