import { ArrowLeftOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  message,
  Modal,
  Select,
  Table,
  Tabs,
  Typography,
  Tooltip,
  Form,
  Input,
} from 'antd';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { toTitleCase } from '../../components/AppCard';
import AppSearchBar from '../../components/AppSearchbar';
import appActions from '../../redux/actions/actions';
import dashboardActions from '../../redux/actions/dashboardActions';
import {
  CHANGE_ENTITY,
  UPDATE_SHOW_BACKGROUND,
} from '../../redux/constants/types';
import './addapp.css';
import 'antd/dist/antd.css';
//import { debounce } from 'lodash';
//import App from './DemoAD';

const { TabPane } = Tabs;

const AddUsers = ({
  appId,
  setShowAddUserModal,
  //refreshData,
  handelCustomAppADGroups,
}) => {
  const [appForm] = Form.useForm();
  const [role, setRole] = useState('user');
  // const [selectedCandidates, setSelcetdCandidates] = useState([]);
  // const allUsers = useSelector((state) => {
  //   return state.app.appUsers;
  // });

  const dispatch = useDispatch();

  const entity = useSelector((state) => {
    return state.root.entity;
  });
  const createNewADGroup = useSelector((state) => {
    return state[entity].createNewADGroup;
  });
  const createNewDashADGroup = useSelector((state) => {
    return state[entity].createNewDashADGroup;
  });
  const isDashboard = entity === 'dashboard' || entity === 'sarovar';

  const entityActions =
    entity === 'dashboard' || entity === 'sarovar'
      ? dashboardActions
      : appActions;

  //console.log('entityActions', entityActions);

  // const handleNameSearch = useCallback(
  //   debounce((value) => {
  //     if (value.length >= 3) {
  //       dispatch(appActions.getUsers({ search: value }));
  //     }
  //   }, 300),
  //   []
  // );

  // const onUserSelect = (val) => {
  //   setSelcetdCandidates(val);
  // };

  const addingComplete = (success) => {
    if (success) {
      const entityLabel =
        entity === 'dashboard'
          ? 'Dashboard'
          : entity === 'sarovar'
          ? 'Module'
          : 'App';
      const successMessage =
        role === 'user'
          ? `Perfect! AD Group has been created successfully.`
          : `Perfect! AD Group has been created successfully.`;
      message.success(successMessage);
      setShowAddUserModal({});
      // refreshData();
      handelCustomAppADGroups();
    } else {
      message.error('Something went wrong! We are looking into it.');
    }
  };

  console.log('entity_ADD:', entity);

  const onFinish = (values) => {
    if (isDashboard || entity === 'sarovar') {
      console.log('Dash_values@:', values);
      const payload = {
        dashboard_id: parseInt(appId),
        group_name: values?.ADGroupName,
      };
      dispatch(entityActions.createNewDashADGroup(payload, addingComplete));
    } else {
      console.log('APP_values@:', values);
      const payload = {
        app_id: parseInt(appId),
        group_name: values?.ADGroupName,
      };
      dispatch(entityActions.createNewADGroup(payload, addingComplete));
    }
  };

  return (
    <div>
      <div>
        <Typography.Title level={4}>Add AD Group</Typography.Title>
      </div>
      <div>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          className="add-app-form"
          layout="vertical"
          form={appForm}
        >
          <Form.Item
            label="AD Group Name"
            name="ADGroupName"
            rules={[{ required: true, message: 'Please enter AD group name!' }]}
          >
            <Input placeholder="Enter AD Group Name" />
          </Form.Item>
          <Form.Item className="text-right">
            <Button
              onClick={setShowAddUserModal}
              className="cancel-btn ml-auto"
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              className="submit-btn ml-2"
              loading={
                isDashboard
                  ? createNewDashADGroup?.loading
                  : createNewADGroup?.loading
              }
            >
              ADD
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

const AD_Details = ({ dashboards }) => {
  const dispatch = useDispatch();
  const { appId } = useParams();
  // console.log('appId_adDetails:', parseInt(appId));

  const location = useLocation();

  const entity = useSelector((state) => {
    return state.root.entity;
  });

  const isDashboard = entity === 'dashboard' || dashboards;
  //console.log('isDashboard:', isDashboard);

  const entityActions = isDashboard ? dashboardActions : appActions;

  const entityLabel =
    entity === 'dashboard'
      ? 'dashboard'
      : entity === 'sarovar'
      ? 'module'
      : 'app';

  const navigate = useNavigate();

  const currentAppData = isDashboard
    ? location.state?.currentDashboardData
    : location.state?.currentAppData;

  const appCustomAppADGroups = useSelector((state) => {
    return state[entity].appCustomAppADGroups;
  });
  const dashCustomAppADGroups = useSelector((state) => {
    return state[entity].dashCustomAppADGroups;
  });
  //console.log('dashCustomAppADGroups:', dashCustomAppADGroups);

  //const [currentPage, setcurrentPage] = useState(1);
  const [currentTab, setCurrentTab] = useState('users');
  const [activeTab, setActiveTab] = useState('Cust1');
  const [showAddUserModal, setShowAddUserModal] = useState({});
  const [showConfirmationModal, setShowConfirmationModal] = useState({});
  const [projectSearch, setProjectSearch] = useState('');

  //console.log('activeTab:', activeTab);

  const goBack = () => {
    navigate(-1);
  };

  const defaultADGroups_columns = [
    {
      title: 'Access Group Name',
      dataIndex: 'group_name',
      key: 'group_name',
      sorter: (a, b) => a.group_name.localeCompare(b.group_name),
      ellipsis: {
        showTitle: false,
      },
      render: (group_name) => {
        return (
          <>
            <Tooltip
              title={group_name}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <span style={{ textTransform: 'capitalize' }}>{group_name}</span>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: 'Group Type',
      dataIndex: 'group_type',
      key: 'group_type',
      sorter: (a, b) => a.group_type.localeCompare(b.group_type),
      ellipsis: {
        showTitle: false,
      },
      render: (group_type) => {
        return (
          <>
            <Tooltip
              title={group_type}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <span style={{ textTransform: 'capitalize' }}>{group_type}</span>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const CustomADGroups_columns = [
    {
      title: 'Access Group Name',
      dataIndex: 'group_name',
      key: 'group_name',
      sorter: (a, b) => a.group_name.localeCompare(b.group_name),
      ellipsis: {
        showTitle: false,
      },
      render: (group_name) => {
        return (
          <>
            <Tooltip
              title={group_name}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <span style={{ textTransform: 'capitalize' }}>{group_name}</span>
            </Tooltip>
          </>
        );
      },
    },

    {
      title: 'Action',
      dataIndex: 'deactivate',
      key: 'deactivate',
      render: (deactivate, record) => {
        // console.log('RECOR_Deactivate', record);
        return (
          <>
            <div>
              <Button
                size="small"
                danger
                onClick={() => deactivateClick(record)}
              >
                Deactivate
              </Button>
            </div>
          </>
        );
      },
    },
    {
      title: 'View',
      dataIndex: 'view',
      key: 'view',
      render: (view, record) => {
        // console.log('RECOR_View', record);
        return (
          <>
            <div
              className="view-btn-style"
              onClick={() => handleADGroupsView(record)}
            >
              View
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    handelCustomAppADGroups();
  }, [appId, projectSearch]);

  const handelCustomAppADGroups = () => {
    if (isDashboard) {
      const payload = {
        app_id: parseInt(appId),
        search: projectSearch ? projectSearch : '',
      };

      dispatch(dashboardActions.getCustomDashADGroups(payload));
    } else {
      const payload = {
        app_id: parseInt(appId),
        search: projectSearch ? projectSearch : '',
        category_type: '',
      };

      dispatch(appActions.getCustomAppADGroups(payload));
    }
  };

  const handleNameSearch = useMemo(() => {
    const loadOptions = (projectSearch) => {
      setProjectSearch(projectSearch);
    };
    return debounce(loadOptions, 400);
  }, []);

  const handleTabChange = (key) => {
    setActiveTab(key);
  };
  const handleAddUsers = () => {
    setShowAddUserModal({ visible: true });
  };

  const deactivateClick = (record) => {
    console.log('record_deactivate', record);
    setShowConfirmationModal({
      // type: 'deactivate',
      // user: user,
      visible: true,
      record: record,
      // role: type,
    });
  };
  const handleADGroupsView = (record) => {
    if (entity === 'dashboard') {
      navigate(`/dashboard/ad-details/ad-group-view/${parseInt(appId)}`, {
        state: { currentDashboardData: record },
      });
    } else if (entity === 'sarovar') {
      navigate(`/sarovar/ad-details/ad-group-view/${parseInt(appId)}`, {
        state: { currentDashboardData: record },
      });
    } else {
      navigate(`/app/ad-details/ad-group-view/${parseInt(appId)}`, {
        state: { currentAppData: record },
      });
    }
  };
  const handlerDeactivateAppADGroup = () => {
    if (entity === 'dashboard' || entity === 'sarovar') {
      dispatch(
        entityActions.deactivateDashADGroup(
          {
            group_id: showConfirmationModal?.record?.id,
          },
          deactivateComplete
        )
      );
    } else {
      dispatch(
        entityActions.deactivateAppADGroup(
          {
            group_id: showConfirmationModal?.record?.id,
          },
          deactivateComplete
        )
      );
    }
  };

  const deactivateComplete = (success) => {
    if (success) {
      //const entityLabel = entity === 'dashboard' ? 'Dashboard' : 'App';
      const successMessage =
        currentTab === 'users'
          ? `Alright! AD Group has been deactivated successfully.`
          : `Alright! AD Group has been deactivated successfully.`;

      message.success(successMessage);
      setShowConfirmationModal({});
      //refreshData();
      handelCustomAppADGroups();
    } else {
      message.error('Something went wrong. We are looking into it');
    }
  };

  return (
    <div className="manage-users-container">
      <div className="app-back-button" onClick={goBack}>
        <ArrowLeftOutlined /> <span className="back-text">Go to home page</span>
      </div>
      <Card className="manage-users-card">
        <div className="d-flex w-100 align-items-center AD_title_container">
          <Typography.Title level={4} className="mb-0">
            AD Details
          </Typography.Title>

          {activeTab === 'Cust1' && (
            <Button
              type="primary"
              className="ml-2 submit-btn"
              onClick={handleAddUsers}
            >
              Create AD Group
            </Button>
          )}
        </div>
        {activeTab === 'Cust1' && (
          <div style={{ width: 900 }} className="mt-2 mb-2">
            <AppSearchBar
              // handleChange={handleSearchChange}
              handleChange={(event) => handleNameSearch(event.target.value)}
              placeholder="Search by name"
            />
          </div>
        )}

        <div style={{ padding: '20px' }}>
          <Tabs
            defaultActiveKey="Cust1"
            activeKey={activeTab}
            onChange={handleTabChange}
          >
            <TabPane tab="Custom AD Groups" key="Cust1">
              <Table
                dataSource={
                  isDashboard
                    ? dashCustomAppADGroups?.data?.data
                    : appCustomAppADGroups?.data?.data
                }
                columns={CustomADGroups_columns}
                pagination={false}
              />
            </TabPane>
            <TabPane tab="Default AD Groups" key="Cust2">
              <Table
                dataSource={
                  isDashboard
                    ? currentAppData?.dash_groups
                    : currentAppData?.Groups
                }
                columns={defaultADGroups_columns}
                pagination={false}
              />
            </TabPane>
          </Tabs>
        </div>
      </Card>
      {showAddUserModal.visible && (
        <Modal
          visible={showAddUserModal.visible}
          footer={[]}
          onCancel={() => setShowAddUserModal({})}
        >
          <AddUsers
            //refreshData={refreshData}
            appId={appId}
            setShowAddUserModal={setShowAddUserModal}
            handelCustomAppADGroups={handelCustomAppADGroups}
          />
        </Modal>
      )}

      {showConfirmationModal.visible && (
        <Modal
          title="Deactivate AD Group"
          visible={showConfirmationModal.visible}
          onCancel={() => setShowConfirmationModal({})}
          onOk={() => handlerDeactivateAppADGroup()}
          okText="Deactivate"
        >
          <div>
            This will deactivate
            <span style={{ textTransform: 'capitalize' }}>
              <strong>{` ${showConfirmationModal?.record?.group_name} `}</strong>
            </span>
            from this {entityLabel}. Continue?
          </div>
        </Modal>
      )}
    </div>
  );
};

export default AD_Details;
