import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { debounce } from 'lodash';

import './homepage.css';
import appActions from '../../redux/actions/actions';
import AppSideNav from '../../components/AppSideNav';
import AppCard, { toTitleCase } from '../../components/AppCard';
import { useNavigate } from 'react-router-dom';
import {
  CHANGE_ENTITY,
  UPDATE_SHOW_BACKGROUND,
} from '../../redux/constants/types';
import {
  Button,
  Carousel,
  Col,
  Input,
  message,
  Modal,
  Row,
  Skeleton,
  Tabs,
  Card,
  Space,
  Pagination,
  Typography,
} from 'antd';
import {
  BarChartOutlined,
  MenuOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import RequestsTable from '../../components/RequestsTable';
import {
  getDashboardMyRequestsColumns,
  getDashboardRequestColumns,
  getMyRequestsColumns,
  getRequestColumns,
} from './constants';

import moment from 'moment';
import RequestAccess from '../RequestAccess';
import TextArea from 'antd/lib/input/TextArea';
import AppsBackground from './appsBackground';
import dashboardActions from '../../redux/actions/dashboardActions';
import DashboardBackground from './dashboardBackground';
import RequestsList from '../RequestsList';
import CompletedRequestsList from '../RequestsList/completedRequests';
import AppSearchBar from '../../components/AppSearchbar';
import { Document, Page, pdfjs } from 'react-pdf';
import FeedbackList from '../RequestsList/feedbacklist';
import sarovarActions from '../../redux/actions/sarovarActions';
import PdfComponent from '../../components/Base64/PdfComponent';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const HomePage = ({ dashboard, sarovar }) => {
  //const [activeTab, setActiveTab] = useState('Cust1');

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const entity = useSelector((state) => {
    return state.root.entity;
  });

  const entityList = useSelector((state) => {
    return state[entity].entityList;
  });

  const updatesList = useSelector((state) => {
    return state[entity].updatesList;
  });

  const [selectedStatus, setSelectedStatus] = useState({});
  const [page, setPage] = useState(1);

  const userRequestsList = useSelector((state) => {
    return state[entity].userRequestsList?.data;
  });

  const myRequestsList = useSelector((state) => {
    return state[entity].myRequestsList?.data;
  });

  const [showReviewModal, setShowReviewModal] = useState({
    visible: false,
    data: null,
  });

  const [showRejectModal, setShowRejectModal] = useState({
    visible: false,
    data: null,
  });

  const [showRequestAccess, setShowRequestAccess] = useState({
    visible: false,
    selectedApp: null,
  });

  const [showQuicklook, setShowQuicklook] = useState({
    visible: false,
    filePath: null,
  });

  const [numPages, setNumPages] = useState(null);

  const [displayRequests, setDisplayRequests] = useState({
    visible: false,
    type: null,
  });

  const [rejectionReason, setRejectionReason] = useState('');

  const [showRejectionViewModal, setShowRejectionViewModal] = useState({
    visible: false,
    data: null,
  });

  const [selectedMenu, setSelectedMenu] = useState(null); //--------------------

  const currentUser = JSON.parse(localStorage.getItem('user_details') || '{}');
  const isAdmin = currentUser?.role === 'admin';

  const isDashboards = entity === 'dashboard';

  const isSarovar = entity === 'sarovar';

  // console.log('isDashboards', isDashboards);
  // console.log('isSarovar', isSarovar);

  const [searchText, setSearchText] = useState();

  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    refreshData();
  }, [searchText]);

  const refreshData = debounce(() => {
    const searchPayload = {
      type: isDashboards ? 'search' : 'app_search',
      query: searchText,
    };
    getEntityMyRequests(searchPayload);
  }, 500);

  useEffect(() => {
    if (entityList.data.length === 0) {
    }
    dispatch({ type: UPDATE_SHOW_BACKGROUND, show: false });
    setSelectedStatus({});
    setDisplayRequests({});

    if (
      (dashboard && entity !== 'dashboard') ||
      (isSarovar && entity !== 'sarovar')
    )
      return;

    if (entity === 'app') {
      dispatch(appActions.getCategoryCounts());
      dispatch(appActions.getMyRequestStatusCounts());
      dispatch(appActions.getUserRequestStatusCounts());
    } else if (entity === 'sarovar') {
      dispatch(sarovarActions.getCategoryCounts());
      dispatch(sarovarActions.getMyRequestStatusCounts());
      dispatch(sarovarActions.getUserRequestStatusCounts());
    } else if (entity === 'dashboard') {
      dispatch(dashboardActions.getCategoryCounts());
      dispatch(dashboardActions.getMyRequestStatusCounts());
      dispatch(dashboardActions.getUserRequestStatusCounts());
    }
    getEntities();
  }, [entity]);
  // console.log('userRequestsList?.data?.pagination:', updatesList?.data);
  useEffect(() => {
    handlerGetUpdatesList();
  }, [page, searchText, entityList]);

  const handlerGetUpdatesList = () => {
    const payload = {
      page,
      per_page: 10,
      app_id: '',
      search: searchText,
    };
    const payloadApp = {
      page,
      per_page: 10,
      search: searchText,
      app_id: '',
    };
    if (!isDashboards && !isSarovar) {
      dispatch(appActions.getUpdatesList(payloadApp));
    } else if (isDashboards) {
      dispatch(
        dashboardActions.getUpdatesListDashboard(payload, { type: 'dashboard' })
      );
    } else if (isSarovar) {
      dispatch(
        dashboardActions.getUpdatesListDashboard(payload, { type: 'sarovar' })
      );
    }
  };

  const getEntityMyRequests = (searchPayload = {}) => {
    if (entity === 'app') {
      dispatch(
        appActions.getMyRequestsByStatus(
          currentUser.role === 'admin',
          selectedStatus.status,
          searchPayload
        )
      );
    } else if (isSarovar) {
      dispatch(
        sarovarActions.getMyRequestsByStatus(
          currentUser.role === 'admin',
          selectedStatus.status,
          searchPayload
        )
      );
    } else if (isDashboards) {
      dispatch(
        dashboardActions.getMyRequestsByStatus(
          currentUser.role === 'admin',
          selectedStatus.status,
          searchPayload
        )
      );
    }
  };

  useEffect(() => {
    setSearchText('');
    if (selectedStatus?.type === 'own') {
      getEntityMyRequests();
    } else if (selectedStatus?.type === 'user') {
      // getEntityUserRequests();
    }
  }, [selectedStatus]);

  const getEntities = (payload) => {
    if (!isDashboards && !isSarovar) {
      dispatch(appActions.getPermittedAppsList(payload));
    } else if (isSarovar) {
      dispatch(sarovarActions.getDashboardsList(payload)); //------Sarovar
    } else if (isDashboards) {
      dispatch(dashboardActions.getDashboardsList(payload));
    }
  };

  useEffect(() => {
    if (selectedMenu && selectedMenu !== '' && selectedMenu !== 'updates') {
      // console.log('selectedMenu_getEntities!!!:', selectedMenu);
      getEntities({ category: selectedMenu });
    } else if (selectedMenu === '') {
      // console.log('selectedMenu_getEntities***:', selectedMenu);
      getEntities();
    } else if (selectedMenu === 'updates') {
      // console.log('selectedMenu_getEntities@@@@:', selectedMenu);
      //getEntities();
    }
  }, [selectedMenu]);

  useEffect(() => {
    if (dashboard && entity !== 'dashboard') {
      dispatch({ type: CHANGE_ENTITY, entity: 'dashboard' });
    }
  }, []);

  useEffect(() => {
    if (sarovar && entity !== 'sarovar') {
      dispatch({ type: CHANGE_ENTITY, entity: 'sarovar' });
    }
  }, []);

  const goToAppDetails = (id) => {
    if (isDashboards) {
      navigate('/dashboard/' + id);
    } else if (isSarovar) {
      navigate('/sarovar/' + id);
    } else {
      navigate('/app/' + id);
    }
  };

  const cancelRequestAccess = () => {
    setShowRequestAccess({ visible: false, selectedApp: null });
  };

  const requestAccess = (app) => {
    // navigate('/request-access/' + app.id + '/' + app.app_name);
    setShowRequestAccess({ visible: true, selectedApp: app });
  };

  const openQuicklook = (url) => {
    const url_pdf = url?.split('/').pop();
    setShowQuicklook({ visible: true, filePath: url_pdf });
  };

  const cancelRequestModal = () => {
    setShowRequestAccess({ visible: false, selectedApp: null });
    dispatch({ type: UPDATE_SHOW_BACKGROUND, show: false });
  };

  const requestSuccess = () => {
    setShowRequestAccess({ visible: false, selectedApp: null });
    dispatch({ type: UPDATE_SHOW_BACKGROUND, show: false });
    if (isDashboards) {
      dispatch(dashboardActions.getMyRequestStatusCounts());
    } else if (isSarovar) {
      dispatch(sarovarActions.getMyRequestStatusCounts());
    } else {
      dispatch(appActions.getMyRequestStatusCounts());
    }
  };

  const addApp = () => {
    navigate('/add-app', { state: null });
  };

  const searchAppByName = (e) => {
    getEntities({ appSearch: e.target.value });
  };

  const handleAppSearch = useCallback(
    debounce((e) => {
      searchAppByName(e);
    }, 300),
    [entity]
  );

  const handleReviewRequest = (row, action, type) => {
    console.log('row::::::::::', row);
    setShowReviewModal({ visible: true, data: row, action, type });
  };

  const cancelReviewModal = () => {
    setShowReviewModal({ visible: false, data: null });
  };

  const approveSuccessCallback = (success, status) => {
    let successString = '';
    if (status === 'rejected') {
      successString = `Alright! The ${entity} request has been rejected and reassigned`;
    } else if (status === 'accepted') {
      successString = `Perfect! The ${entity} request has been successfully approved`;
    }
    if (success) {
      setShowRejectModal({ visible: false, data: null });
      setShowReviewModal({ visible: false, data: null });
      setRejectionReason('');
      message.success(successString);
      if (isDashboards) {
        dispatch(dashboardActions.getUserRequestStatusCounts());
        dispatch(
          dashboardActions.getUserRequestsByStatus(
            currentUser.role === 'admin',
            'pending'
          )
        );
      } else if (entity === 'sarovar') {
        dispatch(sarovarActions.getUserRequestStatusCounts());
        dispatch(
          sarovarActions.getUserRequestsByStatus(
            currentUser.role === 'admin',
            'pending'
          )
        );
      } else {
        dispatch(appActions.getUserRequestStatusCounts());
        dispatch(
          appActions.getUserRequestsByStatus(
            currentUser.role === 'admin',
            'pending'
          )
        );
      }
    } else {
      message.error('Failure: Something went wrong. We are looking into it');
    }
  };

  const approveRequest = (status) => {
    if (isDashboards) {
      dispatch(
        dashboardActions.approveRequests(
          {
            dashboard_id: showReviewModal.data.dashboard_id,
            user_id: showReviewModal.data.user_id,
            status,
            dash_approval_id: showReviewModal.data.id,
          },
          approveSuccessCallback
        )
      );
    }
    if (isSarovar) {
      dispatch(
        sarovarActions.approveRequests(
          {
            dashboard_id: showReviewModal.data.dashboard_id,
            user_id: showReviewModal.data.user_id,
            status,
            dash_approval_id: showReviewModal.data.id,
          },
          approveSuccessCallback
        )
      );
    } else {
      dispatch(
        appActions.approveRequests(
          {
            app_id: showReviewModal.data.app_id,
            user_id: showReviewModal.data.user_id,
            status,
            approval_id: showReviewModal.data.id,
          },
          approveSuccessCallback
        )
      );
    }
  };

  const openRejectModal = (data) => {
    setShowReviewModal({ data: null, visible: false });
    setShowRejectModal({
      visible: true,
      data,
    });
  };

  const rejectRequest = () => {
    const approvalData = showRejectModal.data;

    if (isDashboards) {
      dispatch(
        dashboardActions.approveRequests(
          {
            status: 'rejected',
            approver_comment: rejectionReason,
            user_id: approvalData.user_id,
            dashboard_id: approvalData.app_id,
            dash_approval_id: approvalData.id,
          },
          approveSuccessCallback
        )
      );
    }
    if (isSarovar) {
      dispatch(
        sarovarActions.approveRequests(
          {
            status: 'rejected',
            approver_comment: rejectionReason,
            user_id: approvalData.user_id,
            dashboard_id: approvalData.app_id,
            dash_approval_id: approvalData.id,
          },
          approveSuccessCallback
        )
      );
    } else {
      dispatch(
        appActions.approveRequests(
          {
            status: 'rejected',
            approver_comment: rejectionReason,
            user_id: approvalData.user_id,
            app_id: approvalData.app_id,
            approval_id: approvalData.id,
          },
          approveSuccessCallback
        )
      );
    }
  };

  const accessibleEntities =
    (entityList.data || []).filter((itm) => itm?.access_type === 'open') || [];

  const restrictedEntities = (entityList.data || []).filter(
    (itm) => itm?.access_type === 'restricted'
  );

  const upcomingEntities = (entityList.data || []).filter(
    (itm) => itm?.access_type === 'upcoming'
  );

  const setSelectedMenuStorage = (statusString) => {
    //console.log('statusString2222', statusString);
    localStorage.setItem('selected_menu', statusString);
  };

  const handleRejectionView = (action, record) => {
    switch (action.key) {
      case 're_request':
        setSelectedMenu('');
        setSelectedStatus({});
        setSelectedMenuStorage({ type: 'category', status: '' });
        setDisplayRequests({ visible: false });
        break;
      case 'rejection_reason':
        setShowRejectionViewModal({ visible: true, data: record });
        break;
      default:
        break;
    }
  };

  const cancelViewRejectionModal = () => {
    setShowRejectionViewModal({ visible: false, data: null });
  };

  const renderEntityName = () => {
    if (isDashboards) {
      return showReviewModal.type === 'user'
        ? showReviewModal.data?.dash_name
        : showReviewModal.data?.Dashboard?.dash_name;
    }
    if (isSarovar) {
      return showReviewModal.type === 'user'
        ? showReviewModal.data?.dash_name
        : showReviewModal.data?.Dashboard?.dash_name;
    }
    return showReviewModal.type === 'user'
      ? showReviewModal.data?.app_name
      : showReviewModal.data?.App?.app_name;
  };

  const goToDashboards = () => {
    navigate('/dashboards', {});
    dispatch({ type: CHANGE_ENTITY, entity: 'dashboard' });
  };

  const goToApps = () => {
    navigate('/home', {});
    dispatch({ type: CHANGE_ENTITY, entity: 'app' });
  };
  const goToSarovar = () => {
    navigate('/sarovar', {});
    dispatch({ type: CHANGE_ENTITY, entity: 'sarovar' });
  };

  const editEntity = (itm) => {
    if (isDashboards) {
      navigate(`/add-dashboard`, { state: { appToEdit: itm } });
    } else if (isSarovar) {
      navigate(`/add-sarovar`, { state: { appToEdit: itm } });
    } else if (!isDashboards && !isSarovar) {
      navigate(`/add-app`, { state: { appToEdit: itm } });
    }
  };

  const handleSearchChange = debounce((e) => {
    setSearchText(e.target.value);
  }, 300);

  const renderUsersMenu = () => {
    switch (selectedStatus?.status?.toLowerCase()) {
      case 'pending':
        return <RequestsList dashboard={isDashboards} />;
      case 'accepted':
      case 'rejected':
        return (
          <CompletedRequestsList
            status={selectedStatus.status}
            isDashboards={isDashboards}
          />
        );
      case 'feedback':
        return <FeedbackList isDashboards={isDashboards} />;
      default:
        break;
    }
  };
  /* setSelectedStatus({});
    setShowMenu(false);
    setSelectedMenuStorage({ type: 'category', status: category });
    setSelectedMenu(category);
    setDisplayRequests({ visible: false });
    getEntities({ category }); */
  // console.log('entityList_111:', entityList); //----------Inportant
  // console.log('selectedStatus_111:', selectedStatus);
  // console.log('selectedMenu_111:', selectedMenu); //--------!important
  // console.log('ShowMenut_111:', showMenu);
  // console.log('displayRequests?.type####:', displayRequests?.type);

  // const handalDownloadDoc = async (url) => {
  //   console.log('URL:', url);
  //   const url_pdf = url?.split('/').pop();
  //   console.log('url_pdf:', url_pdf);
  //   try {
  //     // Fetch the PDF data from the API
  //     const response = await fetch(
  //       `https://dx-api.lexyslabs.com/fetch-files/${url_pdf}`
  //     );

  //     // Check if the response is OK and has JSON content type
  //     if (!response.ok) {
  //       throw new Error(`Network response was not ok: ${response.statusText}`);
  //     }

  //     // Check the content type of the response
  //     const contentType = response.headers.get('Content-Type');
  //     console.log('Content-Type:', contentType);

  //     if (!contentType || !contentType.includes('application/json')) {
  //       throw new Error('Expected JSON response, but got ' + contentType);
  //     }

  //     // Parse the response as JSON
  //     const data = await response.json();
  //     console.log('Response JSON:', data);

  //     const base64PDF = data.base64; // Adjust based on the actual response structure

  //     if (base64PDF) {
  //       // Convert base64 string to Blob
  //       const mimeType = 'application/pdf';
  //       const byteCharacters = atob(base64PDF);
  //       const byteArrays = [];

  //       for (let offset = 0; offset < byteCharacters.length; offset += 512) {
  //         const slice = byteCharacters.slice(offset, offset + 512);
  //         const byteNumbers = new Array(slice.length);
  //         for (let i = 0; i < slice.length; i++) {
  //           byteNumbers[i] = slice.charCodeAt(i);
  //         }
  //         const byteArray = new Uint8Array(byteNumbers);
  //         byteArrays.push(byteArray);
  //       }

  //       const blob = new Blob(byteArrays, { type: mimeType });
  //       const objectURL = URL.createObjectURL(blob);

  //       // Create an anchor element and click it to open/download the PDF
  //       const link = document.createElement('a');
  //       link.href = objectURL;
  //       link.target = '_blank';
  //       link.download = 'document.pdf'; // Optional: Set a default file name
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);

  //       // Clean up the object URL after use
  //       URL.revokeObjectURL(objectURL);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching PDF:', error);
  //   }
  // };
  return (
    <>
      <div className="homepage-container">
        <div className="app-carousel">
          <div className="carousel-adjust">
            {isDashboards ? (
              <Carousel autoplay className="dash-carousal">
                <div>
                  <div className="welcome-to carousel-title">
                    Analytics & Insights
                  </div>
                  <div className="carousel-desc">
                    Browse through the catalog of enterprise dashboards, make
                    access requests and view dashboards and analytics
                  </div>
                </div>
                {/* <div>
                  <div className="welcome-to carousel-title">
                    Analytics & Insights
                  </div>
                  <div className="carousel-desc">
                    Browse through the catalog of enterprise dashboards, make
                    access requests and view dashboards and analytics
                  </div>
                </div> */}
              </Carousel>
            ) : isSarovar ? (
              <Carousel autoplay className="sarovar-carousal">
                <div>
                  <div className="welcome-to carousel-title">
                    Datalake and Reporting Solutions
                  </div>
                  <div className="carousel-desc">
                    Integrated Platform for ERP Reports and Dashboards
                  </div>
                </div>
                {/* <div>
                  <div className="welcome-to carousel-title">
                    Analytics & Insights
                  </div>
                  <div className="carousel-desc">
                    Browse through the catalog of enterprise dashboards, make
                    access requests and view dashboards and analytics
                  </div>
                </div> */}
              </Carousel>
            ) : (
              <Carousel
                className="applications-carousel"
                autoplay
                autoplaySpeed={5000}
              >
                <div>
                  <div className="welcome-to carousel-title">
                    Welcome to Tata Projects DX Store
                  </div>
                  <div className="carousel-desc">
                    Browse through the catalog of enterprise apps, make access
                    requests and view analytics
                  </div>
                </div>
                <div>
                  <div className="carousel-title">Project NEEV Microsite</div>
                  <div className="carousel-desc">
                    Learn more about the Project NEEV and important details on
                    the SAP implementation
                  </div>
                  <Button
                    type="primary"
                    className="submit-btn checkout-btn"
                    href={'https://neev.tataprojects.com'}
                    target="_blank"
                  >
                    Visit Now
                  </Button>
                </div>
              </Carousel>
            )}
          </div>
        </div>
        <div className="home-apps">
          <div className="side-nav-trigger">
            <MenuOutlined
              onClick={() => {
                setShowMenu(!showMenu);
              }}
            />
          </div>
          {/*  {isSarovar ? (
            ''
          ) : ( */}
          <div className={`home-sidenav ${showMenu ? 'show' : ''}`}>
            <AppSideNav
              appsList={entityList}
              setDisplayRequests={setDisplayRequests}
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
              selectedMenu={selectedMenu}
              setSelectedMenu={setSelectedMenu}
              setShowMenu={setShowMenu}
              sarovarCount={entityList?.data?.length}
              isSarovar={isSarovar}
            />
          </div>
          {/*   )} */}
          {displayRequests.visible ? (
            <>
              {displayRequests?.type === 'user' ? (
                <>{renderUsersMenu()}</>
              ) : (
                <div className="requests-section">
                  <div className="status-heading">
                    {toTitleCase(selectedStatus.status || '')}
                  </div>
                  <div className="request-table">
                    <div className="d-flex">
                      <AppSearchBar
                        handleChange={handleSearchChange}
                        placeholder={`Search by ${entity} name`}
                      />
                    </div>
                    {isDashboards ? (
                      <RequestsTable
                        data={
                          displayRequests.type === 'own'
                            ? myRequestsList
                            : userRequestsList || []
                        }
                        columns={
                          displayRequests.type === 'own'
                            ? getDashboardMyRequestsColumns({
                                handleReviewRequest,
                                status: selectedStatus.status,
                                handleRejectionView,
                              })
                            : getDashboardRequestColumns({
                                isAdmin,
                                handleReviewRequest,
                              })
                        }
                      />
                    ) : (
                      <RequestsTable
                        data={
                          displayRequests.type === 'own'
                            ? myRequestsList
                            : userRequestsList || []
                        }
                        columns={
                          displayRequests.type === 'own'
                            ? getMyRequestsColumns({
                                handleReviewRequest,
                                status: selectedStatus.status,
                                handleRejectionView,
                              })
                            : getRequestColumns({
                                isAdmin,
                                handleReviewRequest,
                              })
                        }
                      />
                    )}
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="home-tabs">
              {selectedMenu === 'updates' ? (
                <div>
                  <div className="request-list-container">
                    <div style={{ width: '100%', margin: 'auto' }}>
                      <div className="status-text">
                        Updates{' '}
                        {`(${updatesList?.data?.pagination?.total_records})`}
                      </div>

                      <div className="  mb-2">
                        <div>
                          <AppSearchBar
                            handleChange={handleSearchChange}
                            placeholder={`Search by ${entity}`}
                          />
                        </div>
                      </div>
                      <div>
                        {updatesList?.data?.data?.map((itm, index) => (
                          <Space
                            direction="vertical"
                            size={18}
                            style={{ width: '100%', marginBottom: '10px' }}
                            key={index}
                          >
                            <Card style={{ overflow: 'hidden' }}>
                              <Space
                                direction="vertical"
                                size={18}
                                style={{ width: '100%' }}
                              >
                                <Space
                                  direction="horizontal"
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                  }}
                                >
                                  <Space
                                    direction="horizontal"
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      width: '100%',
                                    }}
                                  >
                                    <span className="app-profile-img">
                                      <img
                                        src={
                                          dashboard
                                            ? itm?.Dashboard?.Dash_Documents[0]
                                                ?.doc_url
                                            : itm?.App?.Documents[0]?.doc_url
                                        }
                                        alt=""
                                      />
                                    </span>

                                    <span className="update-card-title">
                                      {!dashboard
                                        ? `${itm?.App?.app_name}`
                                        : `${itm?.Dashboard?.dash_name}`}
                                    </span>
                                  </Space>
                                  {!dashboard ? (
                                    <span className="update-card-posted_date">
                                      {itm?.posted_date === null
                                        ? ''
                                        : moment(
                                            itm?.posted_date,
                                            'DD-MMM-YYYY'
                                          ).format('DD MMM YYYY')}
                                    </span>
                                  ) : (
                                    <span className="update-card-posted_date">
                                      {itm?.posted_date === null
                                        ? ''
                                        : moment(
                                            itm?.posted_date,
                                            'DD-MMM-YYYY'
                                          ).format('DD MMM YYYY')}
                                    </span>
                                  )}
                                </Space>
                                <Space
                                  direction="vertical"
                                  size={1}
                                  // style={{ width: '100%' }}
                                >
                                  <div className="card-release_notes-title">
                                    Release Notes:
                                  </div>
                                  <Typography className="card-release_notes">
                                    {itm?.release_notes}
                                  </Typography>
                                </Space>
                              </Space>
                            </Card>
                          </Space>
                        ))}
                      </div>
                      <div className="pagination">
                        <Pagination
                          total={updatesList?.data?.pagination?.total_records}
                          defaultCurrent={1}
                          pageSize={10}
                          current={page}
                          onChange={(currentPage) => {
                            setPage(currentPage);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <Tabs>
                  <Tabs.TabPane key="item-1">
                    <div className="apps-layout">
                      <div>
                        <div key="actions-bar" className="home-actions-bar">
                          <Input
                            className="home-search"
                            size="large"
                            placeholder={
                              isDashboards
                                ? 'Search Analytics & Insights'
                                : isSarovar
                                ? 'Search Module'
                                : 'Search Apps'
                            }
                            prefix={<SearchOutlined />}
                            // onPressEnter={searchAppByName}
                            onChange={handleAppSearch}
                          />
                          <Button
                            className="home-buttons"
                            shape="round"
                            href="https://servicedesk.tataprojects.com/app/itdesk/HomePage.do"
                            target="_blank"
                          >
                            Service Desk
                          </Button>
                          {!isDashboards && (
                            <Button
                              className="home-buttons"
                              shape="round"
                              icon={<BarChartOutlined />}
                              onClick={goToDashboards}
                            >
                              Analytics & Insights
                            </Button>
                          )}
                          {(isDashboards || isSarovar) && (
                            <Button
                              className="home-buttons"
                              shape="round"
                              icon={<BarChartOutlined />}
                              onClick={goToApps}
                            >
                              Apps
                            </Button>
                          )}
                          {currentUser.role === 'admin' && (
                            <>
                              {isDashboards ? (
                                <>
                                  <Button
                                    className="home-buttons"
                                    shape="round"
                                    icon={<PlusOutlined />}
                                    onClick={() => navigate('/add-dashboard')}
                                  >
                                    Add Analytics & Insights
                                  </Button>
                                </>
                              ) : isSarovar ? (
                                <>
                                  <Button
                                    className="home-buttons"
                                    shape="round"
                                    icon={<PlusOutlined />}
                                    onClick={() => navigate('/add-sarovar')}
                                  >
                                    Add Module
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <Button
                                    className="home-buttons"
                                    shape="round"
                                    icon={<PlusOutlined />}
                                    onClick={addApp}
                                  >
                                    Add App
                                  </Button>
                                </>
                              )}
                            </>
                          )}
                        </div>
                        <div
                          key="apps-with-access"
                          className="apps-with-access"
                        >
                          {/* {isDashboards ? 'Analytics & Insights' : 'Apps'} you
                          have access to ({accessibleEntities.length}) */}
                          {isDashboards
                            ? 'Analytics & Insights'
                            : isSarovar
                            ? 'Modules'
                            : 'Apps'}{' '}
                          you have access to ({accessibleEntities?.length})
                        </div>
                        {entityList.loading ? (
                          <>
                            <Skeleton />
                          </>
                        ) : (
                          <Row
                            gutter={[16, 16]}
                            className="apps-list"
                            key="accesible-list"
                          >
                            {accessibleEntities.length > 0 ? (
                              accessibleEntities?.map((app, idx) => (
                                <Col
                                  span={8}
                                  className={`${
                                    isDashboards ? 'dashboard' : ''
                                  } card-item`}
                                  key={app.app_name}
                                >
                                  <AppCard
                                    // index={idx}
                                    app={app}
                                    onOpen={goToAppDetails}
                                    //dashboard={isDashboards}
                                    dashboard={isDashboards || isSarovar}
                                    isSarovar={isSarovar}
                                    isAdmin={isAdmin}
                                    editEntity={editEntity}
                                    openQuicklook={openQuicklook}
                                  />
                                </Col>
                              ))
                            ) : (
                              <div className="empty-text">
                                {`There are no ${
                                  isDashboards
                                    ? 'dashboards'
                                    : isSarovar
                                    ? 'Module'
                                    : 'apps'
                                } to display`}
                              </div>
                            )}
                          </Row>
                        )}
                        <div className="apps-with-access" key="restricted">
                          Request access to{' '}
                          {/*  {isDashboards ? 'Analytics & Insights' : 'Apps'} (
                          {restrictedEntities.length}) */}
                          {isDashboards
                            ? 'Analytics & Insights'
                            : isSarovar
                            ? 'Modules'
                            : 'Apps'}{' '}
                          ({restrictedEntities.length})
                        </div>
                        {entityList.loading ? (
                          <>
                            <Skeleton />
                          </>
                        ) : (
                          <Row
                            gutter={[16, 16]}
                            className="apps-list"
                            key="restricted-apps"
                          >
                            {restrictedEntities.length > 0 ? (
                              restrictedEntities?.map((app) => (
                                <Col
                                  span={8}
                                  className={`${
                                    isDashboards ? 'dashboard' : ''
                                  } card-item`}
                                  key={app.app_name}
                                >
                                  <AppCard
                                    app={app}
                                    requestAccess={requestAccess}
                                    //dashboard={isDashboards}
                                    dashboard={isDashboards || isSarovar}
                                    isSarovar={isSarovar}
                                    isAdmin={isAdmin}
                                    editEntity={editEntity}
                                    onOpen={goToAppDetails}
                                    openQuicklook={openQuicklook}
                                  />
                                </Col>
                              ))
                            ) : (
                              <div className="empty-text">
                                {`There are no ${
                                  isDashboards
                                    ? 'dashboards'
                                    : isSarovar
                                    ? 'Module'
                                    : 'apps'
                                } to display`}
                              </div>
                            )}
                          </Row>
                        )}
                        <div className="apps-with-access" key="upcoming">
                          Upcoming{' '}
                          {/*  {isDashboards ? 'Analytics & Insights' : 'Apps'} (
                          {upcomingEntities.length}) */}
                          {isDashboards
                            ? 'Analytics & Insights'
                            : isSarovar
                            ? 'Modules'
                            : 'Apps'}{' '}
                          ({upcomingEntities.length})
                        </div>
                        {entityList.loading ? (
                          <>
                            <Skeleton />
                          </>
                        ) : (
                          <Row
                            gutter={[16, 16]}
                            className="apps-list"
                            key="upcoming-apps"
                          >
                            {upcomingEntities.length > 0 ? (
                              upcomingEntities?.map((app) => (
                                <Col
                                  span={8}
                                  className={`${
                                    isDashboards ? 'dashboard' : ''
                                  } card-item`}
                                  key={app.app_name}
                                >
                                  <AppCard
                                    app={app}
                                    upcoming
                                    requestAccess={requestAccess}
                                    //dashboard={isDashboards}
                                    dashboard={isDashboards || isSarovar}
                                    isSarovar={isSarovar}
                                    isAdmin={isAdmin}
                                    editEntity={editEntity}
                                    onOpen={() => {}}
                                    openQuicklook={openQuicklook}
                                  />
                                </Col>
                              ))
                            ) : (
                              <div className="empty-text">
                                {`There are no upcoming ${
                                  isDashboards
                                    ? 'dashboards'
                                    : isSarovar
                                    ? 'Module'
                                    : 'apps'
                                } to display`}
                              </div>
                            )}
                          </Row>
                        )}
                      </div>
                    </div>
                  </Tabs.TabPane>
                </Tabs>
              )}
            </div>
          )}
        </div>
      </div>

      {/*   //----My request -> Pending -> View -> Modal */}
      {showReviewModal.visible && (
        <Modal
          visible={showReviewModal?.visible}
          onCancel={cancelReviewModal}
          width={'60%'}
          footer={
            showReviewModal.type === 'user' &&
            selectedStatus.status === 'pending'
              ? [
                  <Button
                    key="cancel"
                    type="cancel"
                    className="cancel-btn"
                    onClick={cancelReviewModal}
                  >
                    Cancel
                  </Button>,
                  <Button
                    key="reject"
                    type="primary"
                    onClick={() => openRejectModal(showReviewModal.data)}
                  >
                    Reject
                  </Button>,
                  <Button
                    key="approve"
                    type="primary"
                    onClick={() => approveRequest('accepted')}
                  >
                    Approve
                  </Button>,
                ]
              : []
          }
        >
          <div className="modal-header">
            {toTitleCase(showReviewModal.action)}
          </div>
          <div>
            <Row className="mt-3">
              <Col span={12}>
                <div className="review-label">
                  {isDashboards ? 'Dashboard' : 'App'} Name:
                </div>
                <div className="review-value">{renderEntityName()}</div>
              </Col>
              <Col span={12}>
                <div className="review-label ">Reason:</div>
                <div className="review-value">
                  {isAdmin
                    ? showReviewModal.data.reason === null
                      ? 'NA'
                      : showReviewModal.data.reason
                    : showReviewModal.data.reason === null
                    ? 'NA'
                    : showReviewModal.data.reason}
                </div>
              </Col>
            </Row>
            {dashboard && entity === 'dashboard' ? (
              <Row className="mt-3">
                <Col span={12}>
                  <div className="review-label">Access Group:</div>
                  <div className="review-value">
                    {showReviewModal?.data?.dash_role_ADgroup === null
                      ? 'NA'
                      : showReviewModal?.data?.dash_role_ADgroup?.group_name}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="review-label">
                    Level of Access by:{' '}
                    {showReviewModal?.data?.dash_access_levels_details?.length >
                    0
                      ? showReviewModal?.data?.dash_access_levels_details[0]
                          ?.access_levels_type
                      : ''}
                  </div>
                  <div className="review-value">
                    {showReviewModal?.data?.dash_access_levels_details?.length >
                    0
                      ? showReviewModal?.data?.dash_access_levels_details?.map(
                          (itm) => (
                            <div key={itm?.id}>
                              {' '}
                              {itm?.BU_master !== null
                                ? itm?.BU_master?.BU_name
                                : itm?.SBG_master !== null
                                ? itm?.SBG_master?.SBG_name
                                : itm?.SBU_master !== null
                                ? itm?.SBU_master?.SBU_name
                                : itm?.project_master !== null
                                ? itm?.project_master?.project_name
                                : ''}
                            </div>
                          )
                        )
                      : 'NA'}
                  </div>
                </Col>
              </Row>
            ) : (
              ''
            )}
            {entity === 'app' ? (
              <Row className="mt-3">
                <Col span={12}>
                  <div className="review-label">Access Group:</div>
                  <div className="review-value">
                    {showReviewModal?.data?.app_role_ADgroup === null
                      ? 'NA'
                      : showReviewModal?.data?.app_role_ADgroup?.group_name}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="review-label">
                    Level of Access by:{' '}
                    {showReviewModal?.data?.app_access_levels_details?.length >
                    0
                      ? showReviewModal?.data?.app_access_levels_details[0]
                          ?.access_levels_type
                      : ''}
                  </div>
                  <div className="review-value">
                    {showReviewModal?.data?.app_access_levels_details?.length >
                    1
                      ? showReviewModal?.data?.app_access_levels_details?.map(
                          (itm) => (
                            <div key={itm?.id}>
                              {' '}
                              {itm?.SBG_name ||
                                itm?.BU_name ||
                                itm?.SBU_name ||
                                itm?.project_name}
                            </div>
                          )
                        )
                      : 'NA'}
                  </div>
                </Col>
              </Row>
            ) : (
              ''
            )}
            {dashboard && entity === 'dashboard' ? (
              <Row className="mt-3">
                <Col span={12}>
                  <div className="review-label">Additional Information:</div>
                  <div className="review-value">
                    {showReviewModal?.data?.addnl_comments === null
                      ? 'NA'
                      : showReviewModal?.data?.addnl_comments}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="review-label">Documents:</div>
                  <div className="review-value">
                    {showReviewModal?.data?.Dash_Documents?.length > 0
                      ? showReviewModal?.data?.Dash_Documents?.map(
                          (doc, idx) => (
                            <div key={idx}>
                              {/* <a href={doc?.doc_url} target="_blank"> */}
                              <a
                                href={`${
                                  process.env.REACT_APP_API_URL2
                                }/fetch-files/${doc?.doc_url
                                  ?.split('/')
                                  .pop()}`}
                                target="_blank"
                              >
                                {doc?.file_name}
                              </a>
                            </div>
                          )
                        )
                      : 'NA'}
                  </div>
                </Col>
              </Row>
            ) : (
              ''
            )}
            {entity === 'app' ? (
              <Row className="mt-3">
                <Col span={12}>
                  <div className="review-label">Additional Information:</div>
                  <div className="review-value">
                    {showReviewModal?.data?.addnl_comments === null
                      ? 'NA'
                      : showReviewModal?.data?.addnl_comments}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="review-label">Documents:</div>
                  <div className="review-value">
                    {showReviewModal?.data?.Documents?.length > 0
                      ? showReviewModal?.data?.Documents?.map((doc, idx) => (
                          <div key={idx}>
                            <a
                              //href={doc?.doc_url}
                              href={`${
                                process.env.REACT_APP_API_URL2
                              }/fetch-files/${doc?.doc_url?.split('/').pop()}`}
                              type="link"
                              target="_blank"
                              //onClick={() => handalDownloadDoc(doc?.doc_url)}
                            >
                              {doc?.file_name}
                            </a>
                          </div>
                        ))
                      : 'NA'}
                  </div>
                </Col>
              </Row>
            ) : (
              ''
            )}
          </div>
        </Modal>
      )}
      {showRejectModal.visible && (
        <Modal
          visible={showRejectModal.visible}
          onCancel={() => {
            setRejectionReason('');
            setShowRejectModal({ visible: false, data: null });
          }}
          onOk={rejectRequest}
        >
          <div className="modal-header mb-2">Reason</div>
          <div>
            <TextArea
              onChange={(e) => setRejectionReason(e.target.value)}
              value={rejectionReason}
            />
          </div>
        </Modal>
      )}
      {showRequestAccess.visible && (
        <Modal
          width="60%"
          visible={showRequestAccess?.visible}
          onCancel={cancelRequestModal}
          footer={[]}
        >
          <div>
            <RequestAccess
              isDashboards={isDashboards}
              handleSuccess={requestSuccess}
              fromModal
              app={showRequestAccess.selectedApp}
              handleCancel={cancelRequestAccess}
              Access={'Request'}
            />
          </div>
        </Modal>
      )}
      {showRejectionViewModal.visible && (
        <Modal
          // width="0%"
          visible={showRejectionViewModal.visible}
          onCancel={cancelViewRejectionModal}
          footer={[]}
        >
          <div className="modal-header mb-2">Rejection Reason</div>
          <div>
            {
              showRejectionViewModal.data[
                isDashboards ? 'approver_comment' : 'approver_comments'
              ]
            }
          </div>
        </Modal>
      )}
      {showQuicklook.visible && showQuicklook.filePath && (
        <Modal
          //width="fit-content"
          visible={showQuicklook.visible}
          onCancel={() => setShowQuicklook({})}
          footer={[]}
          className="quicklook-modal"
          width={'80%'}
        >
          {/* <div className="modal-header mb-2">Rejection Reason</div> */}
          <div>
            {/* <Document
              file={{ url: showQuicklook.filePath }}
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            >
              {Array.apply(null, Array(numPages))
                .map((x, i) => i + 1)
                .map((page) => (
                  <Page pageNumber={page} />
                ))}
            </Document> */}
            <PdfComponent pdf_file={showQuicklook.filePath} />
          </div>
        </Modal>
      )}
    </>
  );
};

export default HomePage;
