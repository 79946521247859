import React from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const AppSearchBar = ({ placeholder, handleChange }) => {
  return (
    <>
      <Input
        className="search-bar pv-0"
        size="large"
        placeholder={placeholder}
        prefix={<SearchOutlined />}
        // onPressEnter={searchAppByName}
        onChange={handleChange}
      />
    </>
  );
};

export default AppSearchBar;
