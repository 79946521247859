import React from 'react';
import './stream-player.scss';

const StreamPlayer = ({ url }) => {
  return (
    <div className="stream-player">
      <iframe
        title="app-stream"
        // width="640"
        // height="360"
        src={url}
        allowFullScreen
        className="responsive-iframe"
      ></iframe>
    </div>
  );
};

export default StreamPlayer;
