import {
  ArrowLeftOutlined,
  FileTextOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import {
  Button,
  Collapse,
  Image,
  message,
  Modal,
  Tooltip,
  Row,
  Col,
  Space,
  Empty,
} from 'antd';
import { useEffect, useState } from 'react';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { UPDATE_SHOW_BACKGROUND } from '../../redux/constants/types';
import './app-detail.css';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';
import { useNavigate } from 'react-router-dom';
import { parseYoutubeUrl } from '../../helpers/util';
import Rating from 'react-rating';
import TextArea from 'antd/lib/input/TextArea';
import dashboardActions from '../../redux/actions/dashboardActions';
import sarovarActions from '../../redux/actions/sarovarActions';
import RequestAccess from '../RequestAccess';
import StreamPlayer from '../../components/StreamPlayer';
import appActions from '../../redux/actions/actions';
import ReactPlayer from 'react-player';
import ReportCard from './ReportCard';

const SarovarDetail = () => {
  const currentApp =
    useSelector((state) => {
      return state.dashboard.currentApp.data;
    }) || [];

  const { appId } = useParams();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const entity = useSelector((state) => {
    return state.root.entity;
  });

  console.log('entity', entity);

  const [showFeedback, setShowFeedback] = useState(false);
  const [feedbackComment, setFeedbackComment] = useState('');
  const [userRating, setUserRating] = useState(0);
  const [showTrialModal, setShowTrialModal] = useState({});

  const [showRequestAccess, setShowRequestAccess] = useState({
    visible: false,
  });

  const currentUser = JSON.parse(localStorage.getItem('user_details') || '{}');

  useEffect(() => {
    dispatch(
      sarovarActions.getDashboardById({
        appId,
        userId: currentUser.id,
        dashboard_type: 'sarovar',
      })
    );
    dispatch({ type: UPDATE_SHOW_BACKGROUND, show: false });
  }, []);

  const currentAppDetails = currentApp?.data || {};

  useEffect(() => {
    if (currentAppDetails?.dash_name) {
      document.title = `DX Store - ${currentAppDetails?.dash_name}`;
    }
  }, [currentAppDetails]);

  const appImages = currentAppDetails?.Dash_Documents?.filter(
    (itm) => itm.image_use === 'gallery_images'
  );

  // const appProfileImg =
  //   currentAppDetails?.Dash_Documents?.filter(
  //     (itm) => itm.image_use === 'profile'
  //   ) || [];
  const appProfileImg =
    currentAppDetails?.profile_images?.map((itm) => ({
      image_url: itm?.image_url,
    })) || [];

  const userManual = currentAppDetails?.Dash_Documents?.filter(
    (itm) => itm.image_use === 'user_manual'
  );

  const handleFeedbackChange = (e) => {
    setFeedbackComment(e.target.value);
  };

  const feedbackCallback = (success) => {
    if (success) {
      setShowFeedback(false);
      setUserRating(null);
      setFeedbackComment('');
      message.success(
        'Perfect! Your feedback has been submitted successfully.'
      );
      dispatch(
        sarovarActions.getDashboardById({
          appId,
          userId: currentUser.id,
          dashboard_type: 'sarovar',
        })
      );
    } else {
      message.error('Something went wrong! We are looking into it.');
    }
  };

  const submitFeedback = () => {
    const payload = {
      dashboard_id: currentAppDetails.id,
      rating: userRating,
      comment: feedbackComment,
    };
    dispatch(sarovarActions.submitFeedback(payload, feedbackCallback));
  };

  const handleRatingChange = (val) => {
    setUserRating(val);
  };

  const goback = () => {
    navigate('/sarovar');
  };

  const requestAccess = (app) => {
    // navigate('/request-access/' + app.id + '/' + app.app_name);
    setShowRequestAccess({ visible: true });
  };

  const cancelRequestModal = () => {
    setShowRequestAccess({ visible: false, selectedApp: null });
  };

  const requestSuccess = () => {
    setShowRequestAccess({ visible: false, selectedApp: null });
    // dispatch({ type: UPDATE_SHOW_BACKGROUND, show: false });
  };

  const cancelRequestAccess = () => {
    setShowRequestAccess({ visible: false, selectedApp: null });
  };

  const goToStaging = () => {
    console.log(currentAppDetails);
    // currentAppDetails?.staging_url &&
    //   window.open(currentAppDetails.staging_url);
    setShowTrialModal({ visible: true, data: { isExhausted: true } });

    // const payload = {
    //   user_id: currentUser.id,
    //   dashboard_id: currentAppDetails.id.toString(),
    // };
    // appActions
    //   .makeApiCall('post', `/dashboard/validate_trial`, payload)
    //   .then((res) => {
    //     console.log('trial resp', res.data);
    //     if (res.data.trialEnabled) {
    //       setShowTrialModal({ visible: true, data: { isExhausted: false } });
    //     } else {
    //       setShowTrialModal({ visible: true, data: { isExhausted: true } });
    //     }
    //   })
    //   .catch((err) => {
    //     console.log('error', err);
    //     message.error('Something went wrong! We are looking into it.');
    //   });
  };

  const createSecretAndNavigate = () => {
    const payload = {
      user_id: currentUser.id,
      dashboard_id: currentAppDetails.id,
    };
    appActions
      .stagingSecret(payload)
      .then((res) => {
        console.log('secret resp', res);
        if (res.data.secret) {
          console.log(
            currentAppDetails.staging_url + '/login?secret=' + res.data.secret
          );
          window.open(
            currentAppDetails.staging_url + '/login?secret=' + res.data.secret
          );
        }
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const proceedToStaging = () => {
    console.log('proceed to staging');
    window.open(currentAppDetails.staging_url);
    // if (currentAppDetails.is_launch_restricted) {
    //   createSecretAndNavigate();
    // } else {
    //   window.open(currentAppDetails.staging_url);
    // }
  };

  const launchDashboard = () => {
    console.log(currentAppDetails);
    if (currentAppDetails?.is_launch_restricted) {
      const payload = {
        user_id: currentUser.id,
        dashboard_id: currentAppDetails.id,
      };
      appActions
        .makeApiCall('post', `/secret/create_secret`, payload)
        .then((res) => {
          console.log('secret resp', res);
          if (res.data.secret) {
            console.log(
              currentAppDetails.dash_url + '/login?secret=' + res.data.secret
            );
            window.open(
              currentAppDetails.dash_url + '/login?secret=' + res.data.secret
            );
          }
        })
        .catch((err) => {
          console.log('error', err);
        });
    } else {
      window.open(currentAppDetails.dash_url);
    }
  };

  return (
    <div className="sarovar-detail-container">
      <div className="info-container">
        <div className="app-back-button" onClick={() => goback()}>
          <ArrowLeftOutlined />{' '}
          <span className="back-text">Go to previous page</span>
        </div>
        <div className="d-flex flex-wrap">
          <div className="app-info">
            <div className="app-detail-title d-flex">
              <span>
                <img
                  className="profile-img"
                  src={appProfileImg[0]?.image_url}
                  style={{ width: '30px', height: '30px' }}
                  alt=""
                />
              </span>
              {currentAppDetails.dash_name}
              {/* Receivables */}
            </div>
            <div className="app-desc">{currentAppDetails.dash_description}</div>
            <div className="reviews-section">
              <div>
                <Rating
                  readonly
                  placeholderRating={currentAppDetails?.ratingAvg || 0}
                  emptySymbol={
                    <img src="../../star_grey.png" className="icon" alt="" />
                  }
                  placeholderSymbol={
                    <img src="../../star_gold.png" className="icon" alt="" />
                  }
                  fullSymbol={
                    <img src="../../star_gold.png" className="icon" alt="" />
                  }
                />
              </div>
              <div className="review-counts">
                {`${currentAppDetails?.totalReview || 0} Reviews`}
              </div>
            </div>
            <div className="d-flex">
              <div className="refresh">
                <span className="label">Refresh Cycle:</span>
                <span className="value">
                  {currentAppDetails?.Refresh_Cycle?.cycle_name}
                </span>
              </div>
              <div className="refresh last-updated">
                <span className="label">Last Updated:</span>
                <span className="value">
                  {new Date(currentAppDetails?.last_updated)
                    .toLocaleDateString('en-GB', {
                      day: 'numeric',
                      month: 'short',
                      year: 'numeric',
                    })
                    .replace(/ /g, ' ')}
                </span>
              </div>
            </div>
            <div className="sarovar-detail-buttons">
              {currentAppDetails?.is_accessible ? (
                <Button
                  //   type="primary"
                  className="sarovar-submit-btn"
                  // href={currentAppDetails.dash_url}
                  onClick={launchDashboard}
                  target="_blank"
                >
                  Launch Now
                </Button>
              ) : (
                //:  currentAppDetails?.dashboard_group === 'TMU' ? (
                //   <Button
                //     type="primary"
                //     className="submit-btn"
                //     onClick={requestAccess}
                //   >
                //     Request Access
                //   </Button>
                // )
                <Button
                  //disabled
                  type="primary"
                  className="submit-btn"
                  onClick={requestAccess}
                >
                  Request Access
                </Button>
              )}
              <Tooltip
                title={
                  currentAppDetails?.isFeedbackProvided
                    ? 'You have already submitted the feedback'
                    : ''
                }
              >
                <Button
                  onClick={() => setShowFeedback(true)}
                  className="sarovar-cancel-btn ml-2"
                  disabled={currentAppDetails?.isFeedbackProvided}
                >
                  Give Feedback
                </Button>
              </Tooltip>

              {/* <Button
                  className="cancel-btn ml-2"
                  target="_blank"
                  href={
                    userManual && userManual.length ? userManual[0]?.doc_url : ''
                  }
                  rel="noopener"
                >
                  Quick look
                </Button> */}
              <Button
                className="sarovar-cancel-btn ml-2"
                target="_blank"
                // href={currentAppDetails.staging_url}
                onClick={goToStaging}
              >
                Experience it
              </Button>
            </div>
          </div>
          <div className="app-video">
            <div className="app-youtube">
              {/* <LiteYouTubeEmbed
                  aspectHeight={48}
                  aspectWidth={100}
                  id={parseYoutubeUrl(currentAppDetails?.youtube_url)}
                /> */}
              {/* <ReactPlayer
                  width="100%"
                  height="100%"
                  url={
                    currentAppDetails?.youtube_url
                    //`https://player.vimeo.com/video/854285921?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`
                  }
                  controls={true}
                /> */}
              {/* <ReactPlayer
                  width="100%"
                  height="100%"
                  url={`/videos/${currentAppDetails.dash_name
                    ?.toLowerCase()
                    .replaceAll(' ', '-')}.mp4`}
                  controls={true}
                />*/}
              <StreamPlayer
                url={
                  currentAppDetails?.youtube_url
                  // 'https://web.microsoftstream.com/embed/video/1387e908-7351-44b0-a86b-cbede39551db?autoplay=false&showinfo=true'
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="sarovar-report">
        <div className="report-heading">
          Reports({currentAppDetails?.sarovar_reports?.length})
        </div>
        <div>
          {/* className="report-flex" */}
          {/* <Row gutter={[18, 28]}>
            {currentAppDetails?.sarovar_reports?.length > 0 ? (
              currentAppDetails?.sarovar_reports?.map((item) => (
                <div key={item?.id}>
                  <Col span={8}>
                    <ReportCard
                      item={item}
                      is_accessible={currentAppDetails?.is_accessible}
                    />
                  </Col>
                </div>
              ))
            ) : (
              <div className="empty-text">{`There are no report to display`}</div>
            )}
          </Row> */}
          <Row gutter={[20, 20]}>
            {currentAppDetails?.sarovar_reports?.length > 0 ? (
              currentAppDetails?.sarovar_reports?.map((item) => {
                return (
                  <Col span={8} key={item?.id} xs={24} sm={8}>
                    <ReportCard
                      item={item}
                      is_accessible={currentAppDetails?.is_accessible}
                    />
                  </Col>
                );
              })
            ) : (
              <Empty className="w-100" />
            )}
          </Row>
        </div>
      </div>
      <div className="app-faqs">
        <Collapse
          bordered={false}
          defaultActiveKey={['0']}
          expandIcon={({ isActive }) =>
            isActive ? <MinusCircleOutlined /> : <PlusCircleOutlined />
          }
        >
          <Collapse.Panel header="How to use" key={'how_to_use'}>
            {currentAppDetails.how_to_use}

            {userManual && userManual.length > 0 && (
              <div className="user-manual">
                <FileTextOutlined />
                <Button
                  type="link"
                  target="_blank"
                  //href={`/view-pdf-dashboard/${appId}?${userManual[0]?.doc_url}`}
                  href={`${
                    process.env.REACT_APP_API_URL2
                  }/fetch-files/${userManual[0]?.doc_url?.split('/').pop()}`}
                >
                  User Manual
                </Button>
              </div>
            )}
          </Collapse.Panel>
          <Collapse.Panel header="Benefits" key={'benefits'}>
            {currentAppDetails.benifits}
          </Collapse.Panel>
          <Collapse.Panel
            header="SME Contact to reach out for More Information"
            key={'sme_contact'}
          >
            {currentAppDetails.sme_contact}
          </Collapse.Panel>
        </Collapse>
      </div>
      {showFeedback && (
        <Modal
          visible={showFeedback}
          onOk={submitFeedback}
          onCancel={() => {
            setShowFeedback(false);
            setUserRating(0);
          }}
        >
          <div className="rating-section">
            <div className="modal-header mb-3">{`Rate the ${
              entity === 'sarovar' ? 'Module' : 'Dashboard'
            }`}</div>
            <Rating
              onChange={handleRatingChange}
              initialRating={userRating}
              emptySymbol={
                <img src="../../star_grey.png" className="icon" alt="" />
              }
              placeholderSymbol={
                <img src="../../star_gold.png" className="icon" alt="" />
              }
              fullSymbol={
                <img src="../../star_gold.png" className="icon" alt="" />
              }
            />
            <div className="mt-2">
              <div>
                <label>Recommendation for improvement</label>
              </div>
              <TextArea className="mt-2" onChange={handleFeedbackChange} />
            </div>
          </div>
        </Modal>
      )}
      {showRequestAccess.visible && (
        <Modal
          width="60%"
          visible={showRequestAccess?.visible}
          onCancel={cancelRequestModal}
          footer={[]}
        >
          <div>
            <RequestAccess
              isDashboards={true}
              handleSuccess={requestSuccess}
              fromModal
              app={currentAppDetails}
              handleCancel={cancelRequestAccess}
            />
          </div>
        </Modal>
      )}
      {showTrialModal.visible && (
        <Modal
          visible={showTrialModal.visible}
          okText={'Continue'}
          cancelText="Close"
          onOk={proceedToStaging}
          onCancel={() => setShowTrialModal({})}
          // cancelButtonProps={{ style: { display: 'none' } }}
        >
          <div>
            {/* <div className="modal-header mb-3">
                {showTrialModal.data?.isExhausted
                  ? 'Trials exhausted'
                  : 'Please confirm'}
              </div> */}
            <div>
              Please note the Module you are trying to open is in Development
              instance/ Environment . This Module is published to give users a
              facility to accustom themselves with Actual Live Module. Please do
              not refer this development instance to take any operational
              decisions as it is only for development and testing purposes
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default SarovarDetail;
