import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Space } from 'antd';

// getDashboardMyRequestsColumns
export const getDashboardMyRequestsColumns = ({
  isAdmin,
  handleReviewRequest,
  status,
  handleRejectionView,
}) => {
  const menu = (record) => (
    <Menu
      onClick={(action) => handleRejectionView(action, record)}
      items={[
        {
          label: 'View Rejection Reason',
          key: 'rejection_reason',
        },
        {
          label: 'Request Again',
          key: 're_request',
        },
      ]}
    />
  );
  return [
    {
      title: 'Dashboard Name',
      dataIndex: 'dashboardName',
      key: 'dashboardName',
      render: (val, record) => <span>{record.Dashboard?.dash_name}</span>,
    },
    {
      title: 'Web/Mobile',
      dataIndex: 'platform',
      key: 'platform',
      render: (val, record) => (
        <span>{record.Dashboard?.isWeb ? 'Web' : 'Mobile'}</span>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'role',
      key: 'role',
      width: 180,
      render: (val, record) => (
        <div className="d-flex">
          <Button
            className="status-review-btn"
            onClick={() => handleReviewRequest(record, 'view', 'own')}
          >
            View
          </Button>
          {status === 'rejected' && (
            <Dropdown overlay={menu(record)}>
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <span className="dropdown-table">
                    <DownOutlined />
                  </span>
                </Space>
              </a>
            </Dropdown>
          )}
        </div>
      ),
    },
  ];
};

export const getDashboardRequestColumns = ({ handleReviewRequest }) => [
  {
    title: 'Dashboard Name',
    dataIndex: 'dashboardName',
    key: 'dashboardName',
    render: (val, record) => <span>{record.Dashboard?.dash_name}</span>,
  },
  {
    title: 'Web/Mobile',
    dataIndex: 'platform',
    key: 'platform',
    render: (val, record) => (
      <span>
        {record.Dashboard.isBoth
          ? 'Both'
          : record.Dashboard.isWeb
          ? 'Web'
          : 'Mobile'}
      </span>
    ),
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    width: 180,
    render: (val, record) => (
      <>
        <Button
          className="status-review-btn"
          onClick={() => handleReviewRequest(record, 'review', 'user')}
        >
          Review
        </Button>
      </>
    ),
  },
];

export const getMyRequestsColumns = ({
  isAdmin,
  handleReviewRequest,
  status,
  handleRejectionView,
}) => {
  const menu = (record) => (
    <Menu
      onClick={(action) => handleRejectionView(action, record)}
      items={[
        {
          label: 'View Rejection Reason',
          key: 'rejection_reason',
        },
        {
          label: 'Request Again',
          key: 're_request',
        },
      ]}
    />
  );
  return [
    {
      title: 'App Name',
      dataIndex: 'appName',
      key: 'appName',
      render: (val, record) => <span>{record.App?.app_name}</span>,
    },
    {
      title: 'Web/Mobile',
      dataIndex: 'platform',
      key: 'platform',
      render: (val, record) => (
        <span>{record.App?.isWeb ? 'Web' : 'Mobile'}</span>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'role',
      key: 'role',
      width: 180,
      render: (val, record) => (
        <div className="d-flex">
          <Button
            className="status-review-btn"
            onClick={() => handleReviewRequest(record, 'view', 'own')}
          >
            View
          </Button>
          {status === 'rejected' && (
            <Dropdown overlay={menu(record)}>
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <span className="dropdown-table">
                    <DownOutlined />
                  </span>
                </Space>
              </a>
            </Dropdown>
          )}
        </div>
      ),
    },
  ];
};

export const getRequestColumns = ({ handleReviewRequest }) => [
  {
    title: 'App Name',
    dataIndex: 'appName',
    key: 'appName',
    render: (val, record) => <span>{record.App.app_name}</span>,
  },
  {
    title: 'Web/Mobile',
    dataIndex: 'platform',
    key: 'platform',
    render: (val, record) => (
      <span>
        {record.App.isBoth ? 'Both' : record.App.isWeb ? 'Web' : 'Mobile'}
      </span>
    ),
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    width: 180,
    render: (val, record) => (
      <>
        <Button
          className="status-review-btn"
          onClick={() => handleReviewRequest(record, 'review', 'user')}
        >
          Review
        </Button>
      </>
    ),
  },
];
