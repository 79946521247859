import { ArrowLeftOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import appActions from '../../redux/actions/actions';
import { UPDATE_SHOW_BACKGROUND } from '../../redux/constants/types';
import RequestCard from './request-card';
import { useNavigate } from 'react-router-dom';

import './requests.css';
import dashboardActions from '../../redux/actions/dashboardActions';
import { Button, Checkbox, message, Modal, Pagination, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { debounce } from 'lodash';
import AppSearchBar from '../../components/AppSearchbar';
import Search from 'antd/lib/input/Search';
import { toTitleCase } from '../../components/AppCard';
import sarovarActions from '../../redux/actions/sarovarActions';

const CompletedRequestsList = ({ isDashboards, data, status }) => {
  const entity = useSelector((state) => {
    return state.root.entity;
  });
  const userRequestsList = useSelector((state) => {
    return state[entity].userRequestsList?.[status] || {};
  });

  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  // const isDashboards = entity === 'dashboard';
  const currentUser = JSON.parse(localStorage.getItem('user_details') || '{}');
  const isAdmin = currentUser?.role === 'admin';

  const [searchBy, setSearchBy] = useState('user_search');
  const [searchText, setSearchText] = useState();

  const refreshData = debounce(() => {
    const searchPayload = { type: searchBy, query: searchText };
    if (entity === 'app') {
      dispatch(
        appActions.getUserRequestsByStatus(isAdmin, status, page, searchPayload)
      );
    } else if (entity === 'sarovar') {
      dispatch(
        sarovarActions.getUserRequestsByStatus(
          isAdmin,
          status,
          page,
          searchPayload
        )
      );
    } else {
      dispatch(
        dashboardActions.getUserRequestsByStatus(
          isAdmin,
          status,
          page,
          searchPayload
        )
      );
    }
  }, 500);

  useEffect(() => {
    refreshData();
  }, [page, searchText, status]);

  const handleSearchChange = debounce((e) => {
    setSearchText(e.target.value);
    setPage(1);
  }, 300);

  console.log('final user requests', userRequestsList);

  return (
    <div className="request-list-container">
      <div style={{ width: '100%', margin: 'auto' }}>
        <div className="status-text">
          {toTitleCase(status || '')} Requests
          {`(${userRequestsList?.pagination?.total_records || 0})`}
        </div>

        <div className="d-flex align-items-center selection  mb-2">
          <div style={{ width: 500, marginLeft: 20 }}>
            <Select value={searchBy} onChange={(val) => setSearchBy(val)}>
              {isDashboards || entity === 'sarovar' ? (
                <Select.Option value="dashboard_search">
                  Dashboard
                </Select.Option>
              ) : (
                <Select.Option value="app_search">Apps</Select.Option>
              )}
              <Select.Option value="user_search">Users</Select.Option>
            </Select>
            <AppSearchBar
              handleChange={handleSearchChange}
              placeholder={`Search by user or ${entity}`}
            />
          </div>
        </div>
        <div>
          {userRequestsList?.data?.map((itm, index) => (
            <RequestCard
              viewOnly
              request={itm}
              requestIndex={index}
              isDashboards={isDashboards}
              status={status}
              entity={entity}
            />
          ))}
        </div>
        <div className="pagination">
          <Pagination
            total={userRequestsList?.pagination?.total_records}
            showSizeChanger={false}
            defaultCurrent={1}
            pageSize={10}
            current={page}
            onChange={(current, size) => {
              setPage(current);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CompletedRequestsList;
