import { ArrowLeftOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  message,
  Tabs,
  Typography,
  Form,
  DatePicker,
  Input,
  Row,
  Col,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import appActions from '../../redux/actions/actions';
import dashboardActions from '../../redux/actions/dashboardActions';

import './addapp.css';
import 'antd/dist/antd.css';
import moment from 'moment';

const { TabPane } = Tabs;

const PublishReleaseNotes = ({ dashboards }) => {
  const dispatch = useDispatch();
  const { appId } = useParams();
  //console.log('appId_adDetails:', parseInt(appId));

  const location = useLocation();

  const entity = useSelector((state) => {
    return state.root.entity;
  });
  //console.log('entity:', entity);
  const isDashboard = entity === 'dashboard' || dashboards;
  // console.log('isDashboard:', isDashboard);

  const entityActions = isDashboard ? dashboardActions : appActions;

  const entityLabel = isDashboard ? 'dashboard' : 'app';

  const navigate = useNavigate();

  const [currentTab, setCurrentTab] = useState('users');

  const [expiryDate, setExpiryDate] = useState(moment());
  const [publishDate, setPublishDate] = useState(moment());

  //console.log('activeTab:', activeTab);

  const goBack = () => {
    navigate(-1);
  };
  const [appForm] = Form.useForm();

  console.log(' moment(publishDate):', publishDate);
  console.log(' moment(expiryDate):', expiryDate);

  const releaseNotesComplete = (success) => {
    if (success) {
      const entityLabel = entity === 'dashboard' ? 'Dashboard' : 'App';
      const successMessage =
        currentTab === 'users'
          ? `Alright! Release Notes has been submited successfully.`
          : `Alright! Release Notes has been submited successfully.`;

      message.success(successMessage);
      goBack();
    } else {
      message.error('Something went wrong. We are looking into it');
    }
  };
  const onFinish = (values) => {
    const payloadApp = {
      app_id: parseInt(appId),
      posted_date:
        publishDate === undefined ? null : moment().format('YYYY-MM-DD'),
      release_notes: values?.releaseNotes,
      expiry_date:
        expiryDate === undefined
          ? null
          : moment(expiryDate).format('YYYY-MM-DD'),
    };
    const payloadDash = {
      dashboard_id: parseInt(appId),
      posted_date:
        publishDate === undefined ? null : moment().format('YYYY-MM-DD'),
      release_notes: values?.releaseNotes,
      expiry_date:
        expiryDate === undefined
          ? null
          : moment(expiryDate).format('YYYY-MM-DD'),
    };
    console.log('VALUE:', values);
    console.log('PAYLOAD:', payloadApp);

    if (entity === 'app') {
      dispatch(
        appActions.createEditReleaseNote(payloadApp, releaseNotesComplete)
      );
    } else {
      dispatch(
        appActions.createEditReleaseNote(payloadDash, releaseNotesComplete)
      );
    }
  };
  const currentDate = moment().format('YYYY-MM-DD');
  return (
    <div className="manage-users-container">
      <div className="app-back-button" onClick={goBack}>
        <ArrowLeftOutlined /> <span className="back-text">Go to home page</span>
      </div>
      <Card className="manage-users-card">
        <div className="d-flex w-100 mb-4  align-items-center AD_title_container">
          <Typography.Title level={4} className="mb-0">
            Publish Release Notes
          </Typography.Title>
        </div>
        <Form
          name="basic"
          onFinish={onFinish}
          autoComplete="off"
          className="ml-10"
          layout="vertical"
          form={appForm}
        >
          <Row gutter={18}>
            <Col span={12}>
              <Form.Item
                label="Publish Date"
                // name="expiry_date"
                rules={[
                  { required: false, message: 'Please select Publish Date' },
                ]}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  format={'DD-MMM-YYYY'}
                  onChange={(_, dateValue) => setPublishDate(dateValue)}
                  value={publishDate}
                  disabledDate={(current) => {
                    return (
                      moment().add(-1, 'days') >= current ||
                      moment().add(0, 'month') <= current
                    );
                  }}
                ></DatePicker>
              </Form.Item>{' '}
            </Col>
            {entity === 'app' || entity === 'dashboard' ? (
              <Col span={12}>
                <Form.Item
                  label="Expiry Date"
                  name="expiry_date"
                  rules={[
                    { required: true, message: 'Please select Expiry Date' },
                  ]}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    format={'DD-MMM-YYYY'}
                    onChange={(_, dateValue) => setExpiryDate(dateValue)}
                    value={expiryDate}
                    disabledDate={(current) => {
                      return moment().add(0, 'days') >= current;
                    }}
                  ></DatePicker>
                </Form.Item>
              </Col>
            ) : null}
          </Row>
          <Form.Item
            label="Release Notes"
            name="releaseNotes"
            rules={[{ required: true, message: 'Please enter release notes!' }]}
          >
            <TextArea maxLength={1000} placeholder="Enter release notes" />
          </Form.Item>{' '}
          <Form.Item className="text-right">
            <Button type="outlined" className="cancel-btn" onClick={goBack}>
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              className="submit-btn ml-2"
              // disabled={addAppLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default PublishReleaseNotes;
