import React, { useEffect } from 'react';
import { Button, message, Row } from 'antd';
import { useMsal } from '@azure/msal-react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import appActions from '../../redux/actions/actions';
import appLogo from '../../assets/tata-logo.png';
import './login.scss';
import { CheckCircleOutlined } from '@ant-design/icons';
import { UPDATE_SHOW_HEADER } from '../../redux/constants/types';

const LoginPage = () => {
  const { instance } = useMsal();
  const dispatch = useDispatch();

  const accessToken = localStorage.getItem('access_token');

  const navigate = useNavigate();

  useEffect(() => {
    if (accessToken) {
      navigate('/welcome');
    } else {
      dispatch({ type: UPDATE_SHOW_HEADER, show: false });
    }
    document.title = 'Welcome to DX Store';
  }, []);

  useEffect(() => {
    return () => {
      dispatch({ type: UPDATE_SHOW_HEADER, show: true });
    };
  }, []);

  const loginCallback = (success, userDetails) => {
    if (success) {
      localStorage.setItem('access_token', userDetails?.token);
      localStorage.setItem(
        'user_details',
        JSON.stringify(userDetails?.user_details || '{}')
      );
      const prevPath = localStorage.getItem('path_before_login');
      if (prevPath && prevPath !== 'undefined') {
        navigate(prevPath);
        localStorage.removeItem('path_before_login');
      } else {
        navigate('/welcome');
      }

      //message.success('Welcome! You have logged in successfully');
      message.success({
        content: 'Welcome! You have logged in successfully',
        duration: 2, // The message will disappear after 3 seconds
      });
    } else {
      message.error('Failure: Something went wrong. We are looking into it');
    }
  };

  return (
    <div className="pt-3">
      <div className="login-logo">
        <img alt="" className="app-logo cursor-pointer" src={appLogo} />
      </div>
      <div className="login-container">
        <div className="left_screen">
          <div className="illustration">
            <img src="illustration.png" alt="" />
          </div>
        </div>
        <div className={'right_screen'}>
          <div>
            <div className="welcome-text">Welcome Back!</div>
            <div className="desc-text">
              Management of Apps and approvals in one place.
            </div>
            <div className="features">
              <CheckCircleOutlined /> Add, Manage Applications & User Access.
            </div>
            <div className="features">
              <CheckCircleOutlined />
              Request or Approve App Access.
            </div>
            <div className="features">
              <CheckCircleOutlined />
              View analytics & Insights.
            </div>
            <Row>
              <Button
                className={`ml-5 mt-3 ${'styles.secondary_button'}`}
                // src={microsoft}
                id="microsoft-login"
                centered="true"
                alt="使用 Microsoft 工作或學校帳戶登入"
                onClick={() => {
                  instance
                    .loginPopup({
                      scopes: ['User.Read'],
                    })
                    .then((result) => {
                      return dispatch(
                        appActions.signin(
                          {
                            token: result.accessToken,
                          },
                          loginCallback
                        )
                      );
                    })
                    .then((result) => {
                      if (result.payload.success) {
                        localStorage.setItem(
                          'NFA_user',
                          JSON.stringify(result.payload)
                        );
                        const user = result.payload;
                        if (user.user_details.role === 'user') {
                        } else if (user.user_details.role === 'admin') {
                        }
                        //   history.push('/users');
                      } else {
                        console.log('$$$$$$$$$$$$$$$$$$$');
                      }
                    })
                    .catch((err) => {});
                }}
              >
                <b className="sfprotext-bold"> Go to your Account</b>
              </Button>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
