import React, { useCallback, useEffect, useState } from 'react';
import './app-header.css';
import { CaretDownFilled, SearchOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Input, Menu, Radio } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as SearchIcon } from '../../assets/search-icon.svg';
import applogo from '../../assets/tata-logo.png';
import { useDispatch, useSelector } from 'react-redux';
import appActions from '../../redux/actions/actions';
import { debounce } from 'lodash';
import { CHANGE_ENTITY } from '../../redux/constants/types';
import dashboardActions from '../../redux/actions/dashboardActions';
import sarovarActions from '../../redux/actions/sarovarActions';
import { useMsal } from '@azure/msal-react';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
ReactGA.initialize(TRACKING_ID);

const AppHeader = ({ hideLinks }) => {
  const { instance } = useMsal();
  const navigate = useNavigate();

  const [localEntity, setLocalEntity] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setInitialized(true);
    // const tagManagerArgs = {
    //   gtmId: 'G-QGVCR9SH2N',
    // };
    // TagManager.initialize(tagManagerArgs);
  }, []);

  useEffect(() => {
    if (initialized) {
      console.log(
        'being called',
        process.env.REACT_APP_GA_TRACKING_ID,
        location.pathname
      );
      ReactGA.pageview(window.location.pathname + window.location.search);
      // window.dataLayer.push({
      //   event: 'pageview',
      //   path: location.pathname,
      // });
    }
  }, [initialized, location]);

  const logout = () => {
    // console.log(instance.logoutRedirect);
    instance.logoutRedirect({
      account: localStorage.homeAccountId,
      postLogoutRedirectUri: '/',
      mainWindowRedirectUri: '/',
      onRedirectNavigate: () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('user_details');
        return true;
      },
    });
  };

  const entity = useSelector((state) => {
    return state.root.entity;
  });

  const isDashboards = entity === 'dashboard';
  const isSarovar = entity === 'sarovar';

  useEffect(() => {
    if (
      location.pathname &&
      location.pathname.includes('dashboard') &&
      entity !== 'dashboard'
    ) {
      dispatch({ type: CHANGE_ENTITY, entity: 'dashboard' });
    }
  }, [location]);

  const dispatch = useDispatch();

  const handleEntityChange = (e) => {
    const newEntity = e.target.value;
    console.log('entity change', e);
    if (newEntity === 'welcome') {
      dispatch({ type: CHANGE_ENTITY, entity: null });
      setLocalEntity('welcome');
      navigate('/welcome');
    } else {
      if (newEntity !== entity) {
        dispatch({ type: CHANGE_ENTITY, entity: newEntity });
      }

      if (newEntity === 'app') {
        navigate('/home');
      } else if (newEntity === 'dashboard') {
        navigate('/dashboards');
      } else if (newEntity === 'sarovar') {
        navigate('/sarovar');
      }
    }
  };

  const currentUser = JSON.parse(localStorage.getItem('user_details') || '{}');

  const menu = (
    <Menu
      items={[
        {
          key: '1',
          label: <span onClick={logout}>Logout</span>,
        },
      ]}
    />
  );

  const goToHome = () => {
    navigate('/welcome');
  };

  const searchAppByName = (e, localEntity) => {
    if (localEntity === 'app') {
      dispatch(appActions.getPermittedAppsList({ appSearch: e.target.value }));
    }
    if (entity === 'sarovar') {
      dispatch(sarovarActions.getDashboardsList({ appSearch: e.target.value }));
    } else {
      dispatch(
        dashboardActions.getDashboardsList({ appSearch: e.target.value })
      );
    }
  };

  const handleAppSearch = useCallback(
    debounce((e) => {
      searchAppByName(e, entity);
    }, 300),
    [entity]
  );

  return (
    <div className="app-header">
      <img
        onClick={() => goToHome()}
        alt=""
        className="app-logo cursor-pointer"
        src={applogo}
      />
      {!hideLinks && (
        <>
          <Radio.Group
            onChange={handleEntityChange}
            value={entity || localEntity}
            style={{
              fontWeight: 'bold',
              borderRadius: '4px',
            }}
            buttonStyle="solid"
          >
            <Radio.Button value="welcome">Home</Radio.Button>
            <Radio.Button
              onClick={(e) => {
                console.log('click radio', e);
                if (entity === 'app') {
                  navigate('/home');
                }
              }}
              value="app"
            >
              Apps
            </Radio.Button>
            <Radio.Button
              onClick={(e) => {
                console.log('click radio', e);
                if (entity === 'dashboard') {
                  navigate('/dashboard');
                }
              }}
              value="dashboard"
            >
              Analytics & Insights
            </Radio.Button>

            <Radio.Button
              onClick={(e) => {
                console.log('click radio', e);
                if (entity === 'sarovar') {
                  navigate('/sarovar');
                }
              }}
              value="sarovar"
            >
              Sarovar
            </Radio.Button>
          </Radio.Group>
          <div className="header-right">
            <Input
              className="app-search"
              size="large"
              placeholder={
                // isDashboards ? 'Search Analytics & Insights' : 'Search Apps'
                isDashboards
                  ? 'Search Analytics & Insights'
                  : isSarovar
                  ? 'Search Sarovar'
                  : 'Search Apps'
              }
              prefix={<SearchIcon />}
              onChange={(e) => handleAppSearch(e)}
            />
            <Dropdown
              overlay={menu}
              placement="topRight"
              className="user-dropdown"
            >
              <div>
                <Avatar
                  style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
                >
                  {currentUser.fullname ? currentUser.fullname[0] : ''}
                </Avatar>
                <CaretDownFilled />
              </div>
            </Dropdown>
          </div>
        </>
      )}
    </div>
  );
};

export default AppHeader;
