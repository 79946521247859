import { ArrowLeftOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  message,
  Modal,
  Select,
  Table,
  Tabs,
  Typography,
  Tooltip,
  Form,
  Input,
} from 'antd';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { toTitleCase } from '../../components/AppCard';
import AppSearchBar from '../../components/AppSearchbar';
import appActions from '../../redux/actions/actions';
import dashboardActions from '../../redux/actions/dashboardActions';
import {
  CHANGE_ENTITY,
  UPDATE_SHOW_BACKGROUND,
} from '../../redux/constants/types';
import './addapp.css';
import 'antd/dist/antd.css';
import { isDisabled } from '@testing-library/user-event/dist/utils';
//import App from './DemoAD';

const { TabPane } = Tabs;

const AddUsers = ({
  appId,
  currentAppData,
  setShowAddUserModal,
  //refreshData,
  handelCustomAppADGroups,
}) => {
  const [appForm] = Form.useForm();
  const [role, setRole] = useState('user');
  const [selectedCandidates, setSelcetdCandidates] = useState([]);
  const allUsers = useSelector((state) => {
    return state.app.appUsers;
  });

  const dispatch = useDispatch();

  const entity = useSelector((state) => {
    return state.root.entity;
  });
  const appLoading = useSelector((state) => {
    return state[entity].adAppGroupAddMembers;
  });
  const dashLoading = useSelector((state) => {
    return state[entity].adDashGroupAddMembers;
  });

  //const isDashboard = entity === 'dashboard';

  //const entityActions = isDashboard ? dashboardActions : appActions;

  //console.log('entityActions', entityActions);
  useEffect(() => {
    // refreshData();
    dispatch({ type: UPDATE_SHOW_BACKGROUND, show: true });
    dispatch(appActions.getUsers());
  }, []);

  const onUserSelect = (val) => {
    setSelcetdCandidates(val);
  };

  const handleMembersSearch = useCallback(
    debounce((value) => {
      if (value.length >= 3) {
        dispatch(appActions.getUsers({ search: value }));
      }
    }, 300),
    []
  );

  const addingComplete = (success) => {
    if (success) {
      //const entityLabel = entity === 'dashboard' ? 'Dashboard' : 'App';
      const successMessage =
        role === 'user'
          ? `Perfect! Group Members has been added successfully.`
          : `Perfect! Group Members has been added successfully`;
      message.success(successMessage);
      setShowAddUserModal({});
      // refreshData();
      handelCustomAppADGroups();
    } else {
      message.error('Something went wrong! We are looking into it.');
    }
  };
  const onFinish = () => {
    if (entity === 'dashboard' || entity === 'sarovar') {
      const payload = {
        dashboard_id: parseInt(appId),
        group_uuid: currentAppData?.id,
        new_members_uuid: selectedCandidates,
      };
      dispatch(dashboardActions.ADDashGroupAddMembers(payload, addingComplete));
    } else {
      const payload = {
        app_id: parseInt(appId),
        group_uuid: currentAppData?.id,
        new_members_uuid: selectedCandidates,
      };

      dispatch(appActions.ADAppGroupAddMembers(payload, addingComplete));
    }
  };

  return (
    <div>
      <div>
        <Typography.Title level={4}>Add Members</Typography.Title>
      </div>
      <div>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          className="add-app-form"
          layout="vertical"
          form={appForm}
        >
          <div>
            <Form.Item
              label="Select Members"
              name="ADMembers"
              rules={[{ required: true, message: 'Please select members!' }]}
            >
              <Select
                mode="multiple"
                style={{ minWidth: 200 }}
                showSearch
                placeholder="Select a person"
                optionFilterProp="children"
                onChange={onUserSelect}
                onSearch={handleMembersSearch}
                value={selectedCandidates}
              >
                {allUsers?.data?.data?.map((usr) => (
                  <Select.Option key={usr?.id} val={usr}>{`${usr.first_name} ${
                    usr.last_name
                  } (${usr.email || ''})`}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <Form.Item className="text-right">
            <Button
              onClick={setShowAddUserModal}
              className="cancel-btn ml-auto"
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              className="submit-btn ml-2"
              loading={
                entity === 'dashboard' || entity === 'sarovar'
                  ? dashLoading?.loading
                  : appLoading?.loading
              }
            >
              ADD
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

const AD_group_view = ({ dashboards }) => {
  const dispatch = useDispatch();
  const { appId } = useParams();
  // console.log('appId:', appId);

  const location = useLocation();

  const entity = useSelector((state) => {
    return state.root.entity;
  });

  //const isDashboard = entity === 'dashboard' || dashboards;

  const entityActions =
    entity === 'dashboard' || entity === 'sarovar'
      ? dashboardActions
      : appActions;

  //const entityLabel = isDashboard ? 'dashboard' : 'app';

  const navigate = useNavigate();

  const currentAppData =
    entity === 'dashboard' || entity === 'sarovar'
      ? location.state?.currentDashboardData
      : location.state?.currentAppData;

  //console.log('currentAppData:', currentAppData);
  //console.log('dashCustomAppADGroups:', dashCustomAppADGroups);

  // const [currentPage, setcurrentPage] = useState(1);
  const [currentTab, setCurrentTab] = useState('users');
  //const [activeTab, setActiveTab] = useState('Cust1');
  const [showAddUserModal, setShowAddUserModal] = useState({});
  const [showConfirmationModal, setShowConfirmationModal] = useState({});
  const [membersListSearch, setMembersListSearch] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [unselected, setUnselected] = useState([]);

  console.log('selectedRowKeys:', selectedRowKeys);
  console.log('unselected:', unselected);

  const goBack = () => {
    navigate(-1);
  };

  const customAppADMembersList = useSelector((state) => {
    return state[entity].customAppADMembersList;
  });

  const removeLoading = useSelector((state) => {
    return state[entity].deactivateAppADMembers;
  });
  const customAppADMembersListNew = customAppADMembersList?.data?.data?.map(
    (item) => ({
      key: item?.id,
      display_name: item?.display_name,
      email: item?.email,
      first_name: item?.first_name,
      is_active: item?.is_active,
    })
  );
  const CustomADGroups_columns = [
    {
      title: 'Members Name',
      dataIndex: 'display_name',
      key: 'display_name',
      sorter: (a, b) => a.display_name.localeCompare(b.display_name),
      ellipsis: {
        showTitle: false,
      },
      render: (display_name) => {
        return (
          <>
            <Tooltip
              title={display_name}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <span style={{ textTransform: 'capitalize' }}>
                {display_name}
              </span>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      //sorter: (a, b) => a.display_name.localeCompare(b.display_name),
      ellipsis: {
        showTitle: false,
      },
      render: (email) => {
        return (
          <>
            <Tooltip
              title={email}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {email}
            </Tooltip>
          </>
        );
      },
    },

    {
      title: 'Action',
      dataIndex: 'deactivate',
      key: 'deactivate',
      width: '100px',
      render: (deactivate, record) => {
        // console.log('RECOR_Deactivate', record);
        const isButtonDisabled = !selectedRowKeys.includes(record.key);
        return (
          <>
            <div>
              <Button
                size="small"
                danger
                onClick={() => deactivateClick(record)}
                disabled={isButtonDisabled}
              >
                Remove
              </Button>
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    handelCustomAppADGroups();
  }, [appId, membersListSearch, page, pageSize]);

  const handelCustomAppADGroups = () => {
    if (entity === 'dashboard' || entity === 'sarovar') {
      const payload = {
        dashboard_id: parseInt(appId),
        group_id: currentAppData?.id,
        page: page,
        per_page: pageSize,
        search: membersListSearch ? membersListSearch : '',
      };

      dispatch(dashboardActions.getCustomDashADMembersList(payload));
    } else {
      const payload = {
        app_id: parseInt(appId),
        group_id: currentAppData?.id,
        page: page,
        per_page: pageSize,
        search: membersListSearch ? membersListSearch : '',
      };

      dispatch(appActions.getCustomAppADMembersList(payload));
    }
  };

  const handleNameSearch = useMemo(() => {
    const loadOptions = (membersListSearch) => {
      setMembersListSearch(membersListSearch);
    };
    return debounce(loadOptions, 400);
  }, []);

  /* const handleTabChange = (key) => {
    setActiveTab(key);
  }; */
  const handleAddUsers = () => {
    setShowAddUserModal({ visible: true });
  };

  const deactivateClick = (record) => {
    //console.log('record_deactivate', record);
    setShowConfirmationModal({
      // type: 'deactivate',
      // user: user,
      visible: true,
      record: record,
      // role: type,
    });
  };

  const handlerDeactivateAppADGroup = () => {
    if (entity === 'dashboard' || entity === 'sarovar') {
      dispatch(
        entityActions.deactivateDashADMembers(
          {
            dashboard_id: parseInt(appId),
            group_uuid: currentAppData?.id,
            remove_members_uuid: selectedRowKeys,
          },
          deactivateComplete
        )
      );
    } else {
      dispatch(
        entityActions.deactivateAppADMembers(
          {
            app_id: parseInt(appId),
            group_uuid: currentAppData?.id,
            remove_members_uuid: selectedRowKeys,
          },
          deactivateComplete
        )
      );
    }
  };

  const deactivateComplete = (success) => {
    if (success) {
      //const entityLabel = entity === 'dashboard' ? 'Dashboard' : 'App';
      const successMessage =
        currentTab === 'users'
          ? `Alright! Group Members has been removed successfully.`
          : `Alright! Group Members has been removed successfully.`;
      message.success(successMessage);
      setShowConfirmationModal({});
      //refreshData();
      handelCustomAppADGroups();
      setSelectedRowKeys([]);
      setSelectedRow([]);
    } else {
      message.error('Something went wrong. We are looking into it');
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (newSelectedRowKeys, selectedRows) => {
      const unselectedRows = customAppADMembersListNew?.filter((object1) => {
        return !selectedRows.some((object2) => {
          return object1.key === object2.key;
        });
      });
      setUnselected(unselectedRows);
      setSelectedRowKeys(newSelectedRowKeys);
      setSelectedRow(selectedRows);
      //console.log('SelectedRowKeys: ', selectedRowKeys);
    },
  };

  return (
    <div className="manage-users-container">
      <div className="app-back-button" onClick={goBack}>
        <ArrowLeftOutlined /> <span className="back-text">Go to home page</span>
      </div>
      <Card className="manage-users-card">
        <div className="d-flex w-100 align-items-center AD_title_container">
          <Typography.Title level={4} className="mb-0">
            View -{' '}
            <span
              style={{ textTransform: 'capitalize' }}
            >{`${currentAppData?.group_name}`}</span>
          </Typography.Title>

          {/*  {activeTab === 'Cust1' && ( */}
          <Button
            type="primary"
            className="ml-2 submit-btn"
            onClick={handleAddUsers}
          >
            Add Member
          </Button>
          {/*  )} */}
        </div>
        {/*  {activeTab === 'Cust1' && ( */}
        <div style={{ width: 900 }} className="mt-2 mb-2">
          <AppSearchBar
            handleChange={(event) => handleNameSearch(event.target.value)}
            placeholder="Search by name"
          />
        </div>
        {/*  )} */}

        <div style={{ padding: '20px' }}>
          <Table
            dataSource={
              customAppADMembersListNew
              // isDashboard
              //   ? customAppADMembersList?.data?.data //dashCustomAppADGroups?.data?.data
              //   : customAppADMembersList?.data?.data
            }
            columns={CustomADGroups_columns}
            pagination={{
              current: page,
              pageSize: pageSize,
              showSizeChanger: true,
              size: 'small',
              total:
                customAppADMembersList?.data?.pagination?.total_records || 0,
              onChange: (page, pageSize) => {
                setPage(page);
                setPageSize(pageSize);
              },
            }}
            rowSelection={rowSelection}
          />
        </div>
      </Card>
      {showAddUserModal.visible && (
        <Modal
          visible={showAddUserModal.visible}
          footer={[]}
          onCancel={() => setShowAddUserModal({})}
        >
          <AddUsers
            appId={appId}
            currentAppData={currentAppData}
            setShowAddUserModal={setShowAddUserModal}
            handelCustomAppADGroups={handelCustomAppADGroups}
          />
        </Modal>
      )}

      {showConfirmationModal.visible && (
        <Modal
          title="Remove Members"
          visible={showConfirmationModal.visible}
          onCancel={() => setShowConfirmationModal({})}
          onOk={() => handlerDeactivateAppADGroup()}
          okText="Remove"
          okButtonProps={{
            disabled: removeLoading?.loading || selectedRowKeys?.length <= 0,
          }}
        >
          <div>
            This will remove{' '}
            {selectedRow?.map((item, index) => (
              <strong>{`  ${index + 1}.${item?.display_name} ${' '}`}</strong>
            ))}
            from group. Continue?
          </div>
        </Modal>
      )}
    </div>
  );
};

export default AD_group_view;
