import { ArrowLeftOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import appActions from '../../redux/actions/actions';
import { UPDATE_SHOW_BACKGROUND } from '../../redux/constants/types';
import RequestCard from './request-card';
import { useNavigate } from 'react-router-dom';

import './requests.css';
import dashboardActions from '../../redux/actions/dashboardActions';
import { Button, Checkbox, message, Modal, Pagination, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { debounce } from 'lodash';
import AppSearchBar from '../../components/AppSearchbar';
import Search from 'antd/lib/input/Search';
import sarovarActions from '../../redux/actions/sarovarActions';

const RequestsList = ({ dashboard }) => {
  const entity = useSelector((state) => {
    return state.root.entity;
  });
  const userRequestsList = useSelector((state) => {
    return state[entity].userRequestsList?.pending;
  });

  const navigate = useNavigate();

  const [page, setPage] = useState(1);

  const [selectedRequests, setSelectedRequests] = useState([]);

  const [selectAll, setSelectAll] = useState(false);

  const [rejectionReason, setRejectionReason] = useState('');

  const [showRejectModal, setShowRejectModal] = useState({
    visible: false,
    data: null,
  });

  useEffect(() => {
    // dispatch({ type: UPDATE_SHOW_BACKGROUND, show: true });
  }, []);

  const dispatch = useDispatch();
  const isDashboards = entity === 'dashboard';
  const currentUser = JSON.parse(localStorage.getItem('user_details') || '{}');
  const isAdmin = currentUser?.role === 'admin';

  const [searchBy, setSearchBy] = useState('user_search');
  const [searchText, setSearchText] = useState();

  const refreshData = debounce(() => {
    const searchPayload = { type: searchBy, query: searchText };
    if (entity === 'app') {
      dispatch(
        appActions.getUserRequestsByStatus(
          isAdmin,
          'pending',
          page,
          searchPayload
        )
      );
    } else if (entity === 'sarovar') {
      dispatch(
        sarovarActions.getUserRequestsByStatus(
          isAdmin,
          'pending',
          page,
          searchPayload
        )
      );
    } else {
      dispatch(
        dashboardActions.getUserRequestsByStatus(
          isAdmin,
          'pending',
          page,
          searchPayload
        )
      );
    }
  }, 500);

  useEffect(() => {
    refreshData();
  }, [page, searchText]);

  const approveSuccessCallback = (success, status, bulk) => {
    let successString = '';
    if (status === 'rejected') {
      successString = `Alright! The ${entity} request(s) have been rejected and reassigned`;
    } else if (status === 'accepted') {
      if (bulk) {
        successString = `Perfect! The Users have been added successfully`;
      } else {
        successString = `Perfect! The User have been added successfully.`;
      }
    }
    if (success) {
      setRejectionReason('');
      setShowRejectModal({ visible: false });
      setSelectedRequests([]);
      setSelectAll(false);
      if (isDashboards) {
        dispatch(dashboardActions.getUserRequestStatusCounts());
        dispatch(
          dashboardActions.getUserRequestsByStatus(
            currentUser.role === 'admin',
            'pending'
          )
        );
      } else if (entity === 'sarovar') {
        dispatch(sarovarActions.getUserRequestStatusCounts());
        dispatch(
          sarovarActions.getUserRequestsByStatus(
            currentUser.role === 'admin',
            'pending'
          )
        );
      } else {
        dispatch(appActions.getUserRequestStatusCounts());
        dispatch(
          appActions.getUserRequestsByStatus(
            currentUser.role === 'admin',
            'pending'
          )
        );
      }
      message.success(successString);
    } else {
      message.error('Failure: Something went wrong. We are looking into it');
    }
  };

  const handleSelection = (index, checked) => {
    const indexExists = selectedRequests.indexOf(index) >= 0;
    if (!indexExists) {
      setSelectedRequests([...selectedRequests, index]);
    } else {
      const tempSelected = [...selectedRequests];
      tempSelected.splice(selectedRequests.indexOf(index), 1);
      setSelectedRequests(tempSelected);
    }
  };

  const onSelectAllChange = (e) => {
    setSelectAll(e.target.checked);
    if (!e.target.checked) {
      setSelectedRequests([]);
    } else {
      setSelectedRequests(userRequestsList?.data?.map((itm, idx) => idx));
    }
  };

  const openRejectModal = (data) => {
    setShowRejectModal({
      visible: true,
      data,
    });
  };

  const bulkAccept = (status) => {
    if (isDashboards) {
      dispatch(
        dashboardActions.approveRequests(
          {
            status: 'accepted',
            action: selectedRequests.map((idx) => {
              const request = userRequestsList?.data[idx];
              return {
                dashboard_id: request.Dashboard?.id,
                user_id: request?.User?.id,
                status,
                dash_approval_id: request?.id,
                approver_comments: 'approved',
              };
            }),
          },
          (succss, status) => approveSuccessCallback(succss, status, true)
        )
      );
    } else {
      dispatch(
        appActions.approveRequests(
          {
            status: 'accepted',
            action: selectedRequests.map((idx) => {
              const request = userRequestsList?.data[idx];
              return {
                app_id: request.App?.id,
                user_id: request?.User?.id,
                status,
                approval_id: request?.id,
                approver_comments: 'approved',
              };
            }),
          },
          (succss, status) => approveSuccessCallback(succss, status, true)
        )
      );
    }
  };

  const rejectRequest = () => {
    if (isDashboards) {
      dispatch(
        dashboardActions.approveRequests(
          {
            status: 'rejected',
            action: selectedRequests?.map((idx) => {
              const request = userRequestsList?.data[idx];
              return {
                status: 'rejected',
                approver_comment: rejectionReason,
                user_id: request?.User?.id,
                dashboard_id: request.Dashboard?.id,
                dash_approval_id: request?.id,
              };
            }),
          },
          (succss, status) => approveSuccessCallback(succss, status, true)
        )
      );
    } else {
      dispatch(
        appActions.approveRequests(
          {
            status: 'rejected',
            action: selectedRequests.map((idx) => {
              const request = userRequestsList?.data[idx];
              return {
                status: 'rejected',
                approver_comment: rejectionReason,
                user_id: request?.User?.id,
                app_id: request.App?.id,
                approval_id: request.id,
              };
            }),
          },
          (succss, status) => approveSuccessCallback(succss, status, true)
        )
      );
    }
  };

  const handleSearchChange = debounce((e) => {
    setSearchText(e.target.value);
    setPage(1);
  }, 300);

  return (
    <div className="request-list-container">
      {/* <div className="app-back-button" onClick={() => navigate(-1)}>
        <ArrowLeftOutlined /> <span className="back-text">Go to home page</span>
      </div> */}
      <div style={{ width: '100%', margin: 'auto' }}>
        <div className="status-text">
          Pending Requests
          {`(${userRequestsList?.pagination?.total_records || 0})`}
          {/*  <div className="!header-buttons">
            <Button
              onClick={() => bulkAccept('accepted')}
              className="app-buttons accept-btn"
              disabled={
                !(
                  userRequestsList?.data?.length > 0 &&
                  selectedRequests?.length > 0
                )
              }
            >
              Accept All
            </Button>
            <Button
              onClick={() => openRejectModal()}
              className="app-buttons reject-btn ml-2"
              disabled={
                !(
                  userRequestsList?.data?.length > 0 &&
                  selectedRequests?.length > 0
                )
              }
            >
              Reject All
            </Button>
          </div> */}
        </div>

        <div className="d-flex align-items-center selection  mb-2">
          {/*  <Checkbox onChange={onSelectAllChange} checked={selectAll}>
            Select all
          </Checkbox> */}
          <div style={{ width: 500, marginLeft: 20 }}>
            <Select value={searchBy} onChange={(val) => setSearchBy(val)}>
              {isDashboards || entity === 'sarovar' ? (
                <Select.Option value="dashboard_search">
                  Dashboard
                </Select.Option>
              ) : (
                <Select.Option value="app_search">Apps</Select.Option>
              )}
              <Select.Option value="user_search">Users</Select.Option>
            </Select>
            <AppSearchBar
              handleChange={handleSearchChange}
              placeholder={`Search by user or ${entity}`}
            />
          </div>
        </div>
        <div>
          {userRequestsList?.data?.map((itm, index) => (
            <RequestCard
              approveSuccessCallback={approveSuccessCallback}
              request={itm}
              requestIndex={index}
              isDashboards={isDashboards}
              handleSelection={(checked) => handleSelection(index, checked)}
              selectedRequests={selectedRequests}
              selectAll={selectAll}
              entity={entity}
            />
          ))}
        </div>
        <div className="pagination">
          <Pagination
            total={userRequestsList?.pagination?.total_records}
            defaultCurrent={1}
            pageSize={10}
            current={page}
            onChange={(current, size) => {
              setPage(current);
            }}
          />
        </div>
      </div>
      {showRejectModal.visible && (
        <Modal
          visible={showRejectModal.visible}
          onCancel={() => {
            setRejectionReason('');
            setShowRejectModal({ visible: false, data: null });
          }}
          onOk={rejectRequest}
        >
          <div className="modal-header mb-2">Reason</div>
          <div>
            <TextArea
              onChange={(e) => setRejectionReason(e.target.value)}
              value={rejectionReason}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default RequestsList;
