import {
  AuditOutlined,
  CarOutlined,
  CreditCardOutlined,
  GlobalOutlined,
  HomeOutlined,
  ManOutlined,
  MedicineBoxOutlined,
  TeamOutlined,
  ThunderboltOutlined,
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import appActions from '../../redux/actions/actions';
import dashboardActions from '../../redux/actions/dashboardActions';
import { toTitleCase } from '../AppCard';
import './sidenav.css';
import sarovarActions from '../../redux/actions/sarovarActions';

const AppSideNav = (payload) => {
  const {
    setDisplayRequests,
    selectedStatus,
    setSelectedStatus,
    selectedMenu,
    setSelectedMenu,
    setShowMenu,
    sarovarCount,
    isSarovar,
  } = payload;

  // console.log('selectedStatus_NAV::', selectedStatus);

  // console.log('selectedMenu_NAV::', selectedMenu);

  const [updatesCount, setUpdatesCount] = useState(0);

  const currentUser = JSON.parse(localStorage.getItem('user_details'));

  //console.log('currentUser@@@@@@:', currentUser);

  const entity = useSelector((state) => {
    return state.root.entity;
  });
  const updatesCountApp = useSelector((state) => {
    return state.app.updatesCount;
  });
  // console.log('updatesCountApp', updatesCountApp);
  // console.log('entity', entity);

  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const setSelectedMenuStorage = (statusString) => {
    localStorage.setItem('selected_menu', JSON.stringify(statusString));
    setSearchParams({
      category: statusString.type,
      status: statusString.status,
    });
  };

  const categories = useSelector((state) => {
    return state[entity].categories.data;
  });

  //console.log('categories_111:', categories); //----------Inportant

  const myRequestStatuses = useSelector((state) => {
    return state[entity].myRequestStatuses?.data;
  });

  const userRequestStatuses = useSelector((state) => {
    return state[entity].userRequestStatuses?.data;
  });

  useEffect(() => {
    const payload = {};
    dispatch(appActions.getUpdatesCounts(payload));
  }, []);

  const setIfMenuChanged = (status) => {
    if (selectedMenu !== status) {
      setSelectedMenu(status?.toLowerCase());
    }
  };

  const setIfStatusChanged = (statusObj) => {
    if (
      statusObj.type === selectedStatus.type &&
      statusObj.status === selectedStatus.status.toLowerCase()
    )
      return;
    setSelectedStatus(statusObj);
  };

  useEffect(() => {
    // const menuFromStorage = JSON.parse(getSelectedMenuStorage() || '{}');
    const catUrl = searchParams.get('category') || 'category';
    const statusUrl = searchParams.get('status') || '';
    if (catUrl === 'category') {
      setIfMenuChanged(statusUrl.toLowerCase());
    } else if (catUrl && catUrl !== '') {
      setIfMenuChanged(null);
      setIfStatusChanged({ type: catUrl, status: statusUrl?.toLowerCase() });
      setDisplayRequests({ type: catUrl, visible: true });
    } else {
      setIfMenuChanged('');
    }
  }, [categories, myRequestStatuses, userRequestStatuses]);

  const IconStringToSvgMap = {
    projects: <TeamOutlined />,
    updates: <ThunderboltOutlined />,
    finance: <CreditCardOutlined />,
    all_apps: <GlobalOutlined />,
    hr: <ManOutlined />,
    banking: <AuditOutlined />,
    automobiles: <CarOutlined />,
    medical: <MedicineBoxOutlined />,
    construction: <HomeOutlined />,
  };

  const getAppIcon = (type) => {
    return IconStringToSvgMap[type];
  };

  useEffect(() => {
    // dispatch(appActions.getCategoryCounts());
    // dispatch(appActions.getMyRequestStatusCounts());
    // dispatch(appActions.getUserRequestStatusCounts());
  }, []);

  const getEntities = (payload) => {
    if (entity === 'app') {
      dispatch(appActions.getPermittedAppsList(payload));
    } else if (entity === 'sarovar') {
      dispatch(sarovarActions.getDashboardsList(payload));
    } else if (entity === 'dashboard') {
      dispatch(dashboardActions.getDashboardsList(payload));
    }
  };

  const handleSelectedCategory = (category) => {
    //console.log("categoryTTTTTTTT:",category)
    setSelectedStatus({});
    setShowMenu(false);
    setSelectedMenuStorage({ type: 'category', status: category });
    setSelectedMenu(category);
    setDisplayRequests({ visible: false });
    getEntities({ category });
  };

  const handleMyRequestStatus = (status) => {
    setShowMenu(false);
    setDisplayRequests({ type: 'own', visible: true });
    setSelectedMenu(null);
    const tempStatus = { type: 'own', status };
    setSelectedStatus(tempStatus);
    setSelectedMenuStorage(tempStatus);
    // dispatch(
    //   appActions.getMyRequestsByStatus(currentUser.role === 'admin', status)
    // );
  };

  const handleUserRequestStatus = (status) => {
    setShowMenu(false);
    setDisplayRequests({ type: 'user', visible: true });
    setSelectedMenu(null);
    const tempStatus = { type: 'user', status };
    setSelectedStatus(tempStatus);
    setSelectedMenuStorage(tempStatus);
    // dispatch(
    //   appActions.getUserRequestsByStatus(currentUser.role === 'admin', status)
    // );
  };

  let totalApps = 0;
  Object.values(categories).forEach((count) => {
    totalApps += count;
  });

  return (
    <>
      <div className="sidenav-container">
        <div>
          <div
            className={`app-menu-title ${selectedMenu === '' ? 'active' : ''}`}
            onClick={() => handleSelectedCategory('')}
            style={{
              paddingLeft: '4px',
              paddingRight: '0px',
              // fontSize: '14px',
            }}
          >
            {entity === 'dashboard' ? (
              <>
                <span
                  className="cat-icon"
                  style={{ marginRight: '6px', marginLeft: '6px' }}
                >
                  <GlobalOutlined />
                </span>{' '}
                <span style={{ marginRight: '2px' }}>Analytics & Insights</span>
                {`(${totalApps})`}
              </>
            ) : entity === 'sarovar' ? (
              <>
                <span
                  className="cat-icon"
                  style={{ marginRight: '6px', marginLeft: '6px' }}
                >
                  <GlobalOutlined />
                </span>{' '}
                <span style={{ marginRight: '2px' }}>Sarovar</span>
                {`(${sarovarCount})`}
              </>
            ) : (
              <>
                <span
                  className="cat-icon"
                  style={{ marginRight: '6px', marginLeft: '6px' }}
                >
                  <GlobalOutlined />
                </span>{' '}
                <span style={{ marginRight: '2px' }}>All Apps</span>
                {`(${totalApps})`}
              </>
            )}
            {/*  <span className="cat-icon" style={{marginRight:"2px"}}>
              <GlobalOutlined />
            </span>{' '}
            All {entity === 'dashboard' ? 'Analytics & Insights' : 'Apps'}{' '}
            {`(${totalApps})`} */}
          </div>
          <div
            className={`app-menu-title ${
              selectedMenu === 'updates' ? 'active' : ''
            }`}
            style={{
              paddingLeft: '4px',
              paddingRight: '0px',
              // fontSize: '14px',
            }}
            onClick={() => {
              setShowMenu(false);
              setSelectedMenu('updates');
              setSelectedMenuStorage({ type: 'category', status: 'updates' });
              setDisplayRequests({ visible: false });
              setSelectedStatus({});
            }}
          >
            <span
              style={{ marginRight: '6px', marginLeft: '6px' }}
              className="cat-icon"
            >
              <ThunderboltOutlined />
            </span>
            Updates{' '}
            {entity === 'app'
              ? `(${updatesCountApp?.data?.app_updates})`
              : entity === 'sarovar'
              ? `(${updatesCountApp?.data?.sarovar_updates})`
              : `(${updatesCountApp?.data?.dashboard_updates})`}
          </div>
        </div>
        <div>
          {/* <div className="category-header">Categories</div> */}
          <div className="category-header">
            {entity === 'dashboard' || entity === 'app'
              ? 'Categories'
              : 'Modules'}
          </div>
          {Object.keys(categories).map((itm) => (
            <div
              key={itm?.toLocaleLowerCase()}
              className={`app-menu-title ${
                selectedMenu === itm.toLocaleLowerCase() ? 'active' : ''
              }`}
              onClick={() => handleSelectedCategory(itm.toLocaleLowerCase())}
            >
              {/* <span className="cat-icon">
                {getAppIcon(categories[itm].type)}
              </span> */}
              <span className="mr-1">{itm}</span>{' '}
              <span>({categories[itm]})</span>
            </div>
          ))}
        </div>
        <div>
          <div className="category-header">My Requests</div>
          {Object.keys(myRequestStatuses).map((itm) => (
            <div
              key={itm}
              className={`app-menu-title ${
                selectedStatus.type === 'own' && selectedStatus.status === itm
                  ? 'active'
                  : ''
              }`}
              onClick={() => handleMyRequestStatus(itm)}
            >
              <span className="mr-1">{toTitleCase(itm)}</span>{' '}
              <span>({myRequestStatuses[itm]})</span>
            </div>
          ))}
          {((entity === 'dashboard' && currentUser.isDashboardOwner) ||
            (entity === 'sarovar' && currentUser.isSarovarOwner) ||
            (entity === 'app' && currentUser.isAppOwner)) && (
            <div
              key={'feedback'}
              className={`app-menu-title ${
                selectedStatus.type === 'user' &&
                selectedStatus.status === 'feedback'
                  ? 'active'
                  : ''
              }`}
              onClick={() => handleUserRequestStatus('feedback')}
            >
              <span className="mr-1">Feedback</span>{' '}
            </div>
          )}
        </div>
        {/* {currentUser.role !== 'user' ? ( */}
        {((entity === 'dashboard' && currentUser.isDashboardOwner) ||
          (entity === 'sarovar' && currentUser.isSarovarOwner) ||
          (entity === 'app' && currentUser.isAppOwner)) && (
          <div>
            <div className="category-header">User Requests</div>
            {Object.keys(userRequestStatuses).map((itm) => (
              <div
                key={itm}
                className={`app-menu-title ${
                  selectedStatus.type === 'user' &&
                  selectedStatus.status === itm
                    ? 'active'
                    : ''
                }`}
                onClick={() => handleUserRequestStatus(itm)}
              >
                <span className="mr-1">{toTitleCase(itm)}</span>{' '}
                <span>({userRequestStatuses[itm]})</span>
              </div>
            ))}
          </div>
        )}

        {/* ) : (
          ''
        )} */}
      </div>
    </>
  );
};

export default AppSideNav;
