import { Table } from 'antd';
import React from 'react';
import './requests-table.css';

const RequestsTable = ({ columns, data }) => {
  return (
    <div>
      <Table
        className="requests-table"
        columns={columns}
        dataSource={data}
      ></Table>
    </div>
  );
};

export default RequestsTable;
