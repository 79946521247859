import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_URL;
const headers = {};
if (localStorage.getItem('access_token')) {
  headers.Authorization = 'Bearer ' + localStorage.getItem('access_token');
}
const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers,
});

axiosInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('access_token');
  config.headers.Authorization = token;
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return new Promise((resolve, reject) => {
      resolve(response);
    });
  },
  (error) => {
    if (!error.response) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    if (error.response.status === 401 || error.response.status === 403) {
      localStorage.setItem('path_before_login', window.location.pathname);
      localStorage.removeItem('access_token');
      localStorage.removeItem('user_details');
      window.location = '/login';
    } else {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  }
);

export default axiosInstance;
