import { combineReducers } from 'redux';
import { CHANGE_ENTITY } from '../constants/types';
import appReducer from './appReducer';
import dashboardReducer from './dashboardReducer';
import sarovarReducer from './sarovarReducer';

const INITIAL_STATE = {
  entity: 'app',
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_ENTITY:
      return {
        ...state,
        entity: action.entity,
      };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  app: appReducer,
  dashboard: dashboardReducer,
  sarovar: sarovarReducer,
  root: reducer,
});

export default rootReducer;
